import React, { useState } from 'react'

import "./dashboard.scss";
import profile from '../../assets/img/dashboard/lokl-profile.svg';
// import topbar from '../../assets/img/dashboard/dashboard-bar.png';
import investIcon from '../../assets/img/dashboard/iconInvest.svg';
import blogIcon from '../../assets/img/dashboard/blog.svg';
import whatsappIcon from '../../assets/img/dashboard/whatsappIcon.svg';
import profileIcon from '../../assets/img/dashboard/profile.svg';
import shareCode from '../../assets/img/dashboard/shareCode.svg';
import close from "../../assets/img/home/close-white.svg";

import { 
  FacebookShareButton, 
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  // EmailShareButton,
  // EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';

import jwt_decode from "jwt-decode";

import { Link, Outlet, useNavigate } from 'react-router-dom';
import { on, trigger } from '../../helpers/events';
import { getUserData } from '../../services/ownerServices';
import { checkSessionStorageToken, isAuthenticated } from '../../helpers/functions';
import { useEffectOnce } from '../../helpers/hooks';
import CircularBar from '../../components/CircularBar';

function Dashboard() {

  // const [itemActive] = useState('Inversiones');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [modalCode, setModalCode] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);

  const navigate = useNavigate();

  const menuItems = [
    {
      id: 'inversiones',
      icon: investIcon,
      name: 'Inversiones'
    },
    {
      id: 'blog',
      icon: blogIcon,
      name: 'Blog'
    },
    {
      id: 'hablar',
      icon: whatsappIcon,
      name: 'Soporte'
    },
    {
      id: 'logout',
      icon: profileIcon,
      name: 'Cerrar sesión'
    }
  ]
  
  const closeSession = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('user_id');
    trigger('unauthed');
    navigate('/');
  }

  const dashboardOptions = (item: any) => {

    switch (item.id) {

      case 'inversiones':
        return(
          <>
            <div className="flex justify-start items-center">
              <img width={25} className="mr-[20px]" src={item.icon} alt="dash" />
              <Link to="/dashboard/" className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>{item.name}</Link>
            </div>
          </>
        );
        
      case 'hablar': 
        return(
          <>
            <div className="flex justify-start items-center">
              <img width={25} className="mr-[20px]" src={item.icon} alt="contacto" />
              <a  
                rel="noreferrer" 
                target="_blank" 
                href="https://api.whatsapp.com/send?phone=573126715883&text=Hola!,%20estoy%20en%20el%20dashboard,%20necesito%20tu%20ayuda" 
                className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>
                  {item.name}
              </a>
            </div>
          </>
        );

      case 'logout': 
      return(
        <>
          <div className="flex justify-start items-center  cursor-pointer" onClick={e => closeSession()}>
            <img width={25} className="mr-[20px]" src={item.icon} alt="dash" />
            <h1 className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>{item.name}</h1>
          </div>
        </>
        );

      default:
        return(
          <>
            <div className="flex justify-start items-center">
              <img width={25} className="mr-[20px]" src={item.icon} alt="dash" />
              {/* <div className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-gray-400`}>{item.name}</div> */}
              <Link to={`/${item.id}`} className={`font-roboto font-medium text-[16px] no-underline text-black hover:text-black`}>{item.name}</Link>
            </div>
            {/* <div className="font-roboto w-full text-center text-gray-400 text-[11px]">Próximamente</div> */}
          </>
        )
        
    }

  }

  const getData = async (id: string) => {
    const { data } = await getUserData();
    const userName = data.data.firstName + ' ' + data.data.lastName;
    const userCode = (data.data.uniqueCode).toUpperCase();
    sessionStorage.setItem('name', userName.split(" ")[0].toLowerCase());
    setName(userName);
    setCode(userCode);

    // TODO: Define user profile completion
    setProfileCompleted(false);

  }

  const copyTextToClipboard = () => {

    navigator.clipboard.writeText(`Hola me gustaría que conocieras este increíble proyecto. Usa mi link de referido para registrarte https://www.lokl.life?code=${code}`);
    setCodeCopied(true);
    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);

  }

  useEffectOnce(() => {

    isAuthenticated();
    
    localStorage.removeItem('fromDash');
    const token: string = localStorage.getItem("token") || '';
    const decoded: any = jwt_decode(token);

    on('reload:dashboard', () => {
      getData(decoded.id)
    });

    if(checkSessionStorageToken()){
      getData(decoded.id);
    }

  });

  return (
    <div className="lg:px-8 pt-28 lg:pt-44 pb-44 min-h-screen flex justify-center lg:justify-start bg-[#EFEFEF]">

      <div className="hidden lg:flex w-[270px] min-h-[600px] py-[40px] flex-col justify-between items-center rounded-lg mr-2.5 bg-white">

        <div className="flex flex-col justify-start items-center">

          <div className="mb-[20px] flex flex-col justify-center items-center">

            <div className="relative mb-3">
              
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                <img className=" rounded-full bg-white" width={100} height={100} src={profile} alt="profile" />
              </div>

              <CircularBar percentage={ profileCompleted === true ? 100 : 70 } />
            
            </div>

            <div className="text-[13px] font-medium text-gray-500">Bienvenido de nuevo</div>
            <div className="text-[22px] font-roboto font-black px-9 text-center capitalize">{name}</div>

            {
              profileCompleted === false &&
              <div className="mt-3">
                <button 
                  className='bg-[#D8D1FF] px-4 py-2 font-roboto font-extrabold rounded-full text-sm'
                  onClick={() => window.open('https://docs.google.com/forms/d/1xV5MwodhzA1MGZcWnRlyxxwFDuLjWQcQu2quvDeBQDc/viewform?edit_requested=true')}
                >
                  10% Completar perfil
                </button>
              </div>
            }

            { 
              code &&
              <>
                <div className="w-56 border-b-[1px] border-gray-300 mt-4 mb-1"></div>
                
                <div className="text-center">
                
                  <div className="font-roboto text-[22px] font-black text-black">{code}</div>
                  <div className="font-roboto">Tu código de referidos</div>

                  <div className="flex justify-center items-center text-sm mt-2 py-2 px-1 border-[1px] border-black rounded-full cursor-pointer"
                  onClick={() => setModalCode(!modalCode)}
                  >
                    <img className='mr-2 font-roboto' src={shareCode} alt="share code icon" />
                    <div className="">Compartir</div>
                  </div>

                </div>

              </>
            }

            <div className="w-56 border-b-[1px] border-gray-300 my-3"></div>
            
            {/* <div className="w-full flex justify-between items-center">
              <progress className='w-[80%] h-[10px] my-3' value="30" max="100"></progress>
              <div className="w-[20%] ml-3 text-[12px] text-gray-500">30%</div>
            </div>
            <Link className="text-[12px] text-gray-500" to="/">Editar perfil</Link> */}
          
          </div>

          {
            menuItems.map((item: any) => 
            <div className='w-[150px] flex flex-col my-[10px]'>
              
              {dashboardOptions(item)}

            </div>
            )
          }

        </div>

      </div>

      <div className="w-[90%] lg:w-[80%] h-5/6 2xl:w-[90%] relative">

        {
          modalCode &&
          <div className="flex justify-center items-center w-full min-h-[735px] absolute bg-black/40 rounded">

            <div className="flex flex-col justify-center items-center relative w-[380px] h-[240px] bg-white px-9 py-2 rounded-lg">

              <div className="absolute -top-5 -right-5 cursor-pointer"
                onClick={() => setModalCode(false)}
              >
                <img src={close} alt="close" />
              </div>

                <div className="w-full font-bold">Compártelo</div>
                <div className="w-full mb-3">y recibe beneficios por referido</div>

                <div className="flex justify-between items-center w-full border p-2 rounded-full mb-3">

                  <div className="text-gray-400 text-sm">https://www.lokl.life?code={code}</div>

                  <div
                    className="bg-black text-white text-sm rounded-full py-1.5 px-4 cursor-pointer"
                    onClick={() => copyTextToClipboard()}
                  >
                    {codeCopied ? 'Copiado!' : 'Copiar'}
                  </div>

                </div>

                <div className="w-full mb-3 flex justify-center gap-3">

                  {/* <EmailShareButton
                  url={`https://www.lokl.life?code=${code}`}
                >
                  <EmailIcon size={35} round />
                </EmailShareButton> */}
                
                <FacebookShareButton
                  url={`https://www.lokl.life?code=${code}`}
                  quote={'Me gusta invertir en lokl'}
                  hashtag="#lokl"
                >
                  <FacebookIcon size={35} round />
                </FacebookShareButton>

                <TwitterShareButton
                  url={`https://www.lokl.life?code=${code}`}
                >
                  <TwitterIcon size={35} round />
                </TwitterShareButton>

                <WhatsappShareButton
                  url={`https://www.lokl.life?code=${code}`}
                >
                  <WhatsappIcon size={35} round />
                </WhatsappShareButton>

                <TelegramShareButton
                  url={`https://www.lokl.life?code=${code}`}
                >
                  <TelegramIcon size={35} round />
                </TelegramShareButton>

                <LinkedinShareButton
                  url={`https://www.lokl.life?code=${code}`}
                >
                  <LinkedinIcon size={35} round />
                </LinkedinShareButton>

              </div>

            </div>

          </div>
        }

        {/* <div style={{ backgroundImage: `url(${topbar})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className="hidden lg:flex justify-between px-14 h-[130px] rounded-lg mb-4 text-white font-lato">

          <div className="flex flex-col justify-center">

            <div className="mb-1 font-bold text-2xl">
              Los amigos son la familia que eliges
            </div>

            <div className="font-lato">
              Crece junto a los tuyos invirtiendo en proyectos con LOKL <br />
              <span className='text-[#DBD3FF] font-bold font-lato'>Invita a tus amigos, refiere y gana 1 unit.</span>
            </div>

          </div>

          <div className="flex items-center mt-9">

            <div className="btn-outline-white">
              Compartir con amigos
            </div>

          </div>

        </div> */}

        <div className="w-full flex flex-col lg:ml-4 ">

          <Outlet />

        </div>

      </div>
    
    </div>
  )
}

export default Dashboard;
