import React from 'react'

interface ArticleHeaderProps {
    title: string,
    children: React.ReactNode;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({ children, title }) => {
    return (
        <div className="w-full flex justify-center">

            <div className="w-full">

                <div className="blog-title-md mb-4 text-center">
                    {title}
                </div>

                <p className="text-base font-roboto w-full md:w-4/5 mx-auto text-center">
                    {children}
                </p>

            </div>
        </div>
    )
}
