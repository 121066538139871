var axios = require('axios');
const countriesUrl = "https://api.countrystatecity.in/v1/countries"

var config = {
    method: 'get',
    headers: {
        'X-CSCAPI-KEY': 'aXhBaXdiUlEzUWxPTWwydVJ2dk14SGpSMm1TUEFOM0VwUm0wTUJ5VA==',
    },
    url: ""
};

export const fetchCountries = (setCountries) => {
    let params = { ...config, url: `${countriesUrl}` }
    axios(params)
        .then(result => setCountries(result.data))
        .catch(error => console.log('error', error));
};

export const fetchRegions = (country, setRegions) => {
    const regionsUrl = `${countriesUrl}/${country}/states`
    let params = { ...config, url: regionsUrl }
    axios(params)
        .then(result => {
            let regions = result.data
            let regionsClean = regions.map(region => {
                region.name = region.name.replace(/Department/, "").trim()
                if (region.name === "Archipelago of Saint Andréws, Providence and Saint Catalina") { region.name = "San Andrés, Providencia y Santa Catalina" }
                region.id = region.iso2
                delete region.iso2
                return region
            })
            let regionsSort = regionsClean.sort((a, b) => a.name > b.name ? 1 : -1)
            setRegions(regionsSort);
        })
        .catch(error => console.log('error', error));
};

// Refactoring to set logic only to make the API call
export const fetchStates = async(country) => {
    try {
        const regionsUrl = `${countriesUrl}/${country}/states`
        let params = { ...config, url: regionsUrl }
    
        const result = await axios(params)
        let regionsClean = result.data.map(region => {
            region.name = region.name.replace(/Department/, "").trim()
            if (region.name === "Archipelago of Saint Andréws, Providence and Saint Catalina") { region.name = "San Andrés, Providencia y Santa Catalina" }
            region.id = region.iso2
            delete region.iso2
            return region
        })
        let regionsSort = regionsClean.sort((a, b) => a.name > b.name ? 1 : -1)
        return regionsSort;
    } catch (error) {
        throw new Error('Error fetching states')
    }
};

// Refactoring to set logic only to make the API call
export const fetchCitiesNew = async(country, region) => {
    try {
        const citiesUrl = `${countriesUrl}/${country}/states/${region}/cities`
        if(region === "DC"){
            //Ajuste temporal, el api que estamos usando tiene un bug con Bogota
            return([{
                "id": 149237,
                "name": "Bogotá D.C.",
                "state_id": 4921,
                "state_code": "DC",
                "state_name": "Bogotá D.C.",
                "country_id": 48,
                "country_code": "CO",
                "country_name": "Colombia",
                "latitude": "4.61263889",
                "longitude": "-74.07050000",
                "wikiDataId": "Q2841"
              }]);
        }
        let params = { ...config, url: citiesUrl }
        const result = await axios(params)

        let citiesSort = result.data.sort((a, b) => a.name > b.name ? 1 : -1);
        let cleanArray = [];
        let normalize = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        for (let i of citiesSort) {
            if (!cleanArray.find(e => normalize(e.name) === normalize(i.name))) {
                cleanArray.push(i)
            }
        }
        return cleanArray
    } catch (error) {
        throw new Error('Error fetching cities')
    }
};

export const fetchCities = (country, region, next) => {
    const citiesUrl = `${countriesUrl}/${country}/states/${region}/cities`
    if(region === "DC"){
        //Ajuste temporal, el api que estamos usando tiene un bug con Bogota
        next([{
            "id": 149237,
            "name": "Bogotá D.C.",
            "state_id": 4921,
            "state_code": "DC",
            "state_name": "Bogotá D.C.",
            "country_id": 48,
            "country_code": "CO",
            "country_name": "Colombia",
            "latitude": "4.61263889",
            "longitude": "-74.07050000",
            "wikiDataId": "Q2841"
          }]);
          return;
    }
    let params = { ...config, url: citiesUrl }
    axios(params)
        .then(result => {
            let citiesSort = result.data.sort((a, b) => a.name > b.name ? 1 : -1);
            let cleanArray = [];
            let normalize = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            for (let i of citiesSort) {
                if (!cleanArray.find(e => normalize(e.name) === normalize(i.name))) {
                    cleanArray.push(i)
                }
            }
            if (cleanArray.length > 1) {
                next(cleanArray)
            } else next(false)
        })
        .catch(error => console.log('error', error));
}
