import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form';
import { Banner } from './components/Banner';
import { SummaryCard } from './components/SummaryCard';
import { PaymentMethodSelect } from '../../Dashboard/components/PaymentMethodSelect';
import { CreditCardForm } from './components/CreditCardForm';
import { PseForm } from './components/PseForm';
import { useInvestSummary } from '../../../hooks/useInvestSummary';
import { urls } from '../../../helpers/urls';
import { sendTransaction } from '../../../services/paymentDataServices';
import PaymentResponseModal from './components/PaymentResponseModal';
import { useNavigate } from 'react-router-dom';
import { getCart } from '../../../services/cartService';
import { validateCoupon } from '../../../services/couponService';
import toast from 'react-hot-toast';

export const Payment = () => {

  // TODO: Verify if is possible to improve this with react-router-dom config
  // Code to scroll to top when change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate()

  const [installmentsQty, setInstallmentQty] = useState('0');
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [investUnitAmount, setInvestUnitAmount] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState<{title: string, description: string, style: 'success' | 'error'}>({
    title: '',
    description: '',
    style: 'success'
  });

  const [cartId] = useState(localStorage.getItem('cartId') ? localStorage.getItem('cartId')! : null);
  const [cartInfo, setCartInfo] = useState<any>(null);

  const [coupon, setCoupon] = useState({
    code: '',
    couponUnitPrice: 0,
    couponSubscriptionFeeFree: false
  });

  useEffect(() => {
    console.log(cartInfo) // FIXME: Use cart info to show payment summary
    if (!cartId) {
      setOpenModal(true)
      setModalInfo({
        title: 'No tienes un carrito de compras activo!',
        description: 'Por favor selecciona la cantidad de units',
        style: 'error'
      })
      setTimeout(() => {
        setOpenModal(true)
        navigate('/checkout/invest')
      }, 3000)
      return
    };
    getCart(cartId).then((data) => {
      if (data?.data) {
        const cartResponse = data.data;
        setCartInfo(cartResponse.data)
        setPaymentMethod(cartResponse.data.paymentMethod === 'CARD' ? 1 : cartResponse.data.paymentMethod === 'PSE' ? 2 : 0)
        setInstallmentQty((cartResponse.data.installmentsQuantity).toString()) // Value to string to keep compatibility with useInvestSummary
        setInvestUnitAmount((cartResponse.data.unitsQuantity).toString()) // Value to string to keep compatibility with useInvestSummary
        // Get coupon information
        validateCoupon(cartResponse.data.coupon).then((couponResponse) => {
          if(!couponResponse?.data.success) {
            toast.error('Ha ocurrido un error validando el cupón')
            return
          }
          setCoupon({
            code: cartResponse.data.coupon,
            couponUnitPrice: couponResponse?.data.data?.unitPrice,
            couponSubscriptionFeeFree: couponResponse?.data.data?.subscriptionFeeFree
          })
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId])

  const [openModal, setOpenModal] = useState(false);

  const {
    totalInvestment,
    installments,
    financingCost,
    subtotal,
    paymentMethodFee,
    total,
  } = useInvestSummary(investUnitAmount, installmentsQty, paymentMethod, coupon.couponUnitPrice, coupon.couponSubscriptionFeeFree)
  const {
    register: registerCard,
    handleSubmit: handleSubmitCard,
    formState: {
      errors: errorsCard,
    },
    setValue: setValueCard,
    setError: setErrorCard,
    clearErrors: clearErrorsCard,
    getValues: getValuesCard
  } = useForm<FieldValues>({
    defaultValues: {
      cardNumber: '',
      cardHolder: '',
      expDate: '',
      cvc: '',
      address: '',
      documentType: '',
      documentNumber: '',
      phone: '',
      email: ''
    }
  })

  const {
    register: registerPse,
    handleSubmit: handleSubmitPse,
    formState: {
      errors: errorsPse,
    },
    setValue: setValuePse,
    setError: setErrorPse,
    clearErrors: clearErrorsPse,
    getValues: getValuesPse
  } = useForm<FieldValues>({
    defaultValues: {
      financialInstitutionCode: '',
      fullName: '',
      address: '',
      userType: '',
      documentType: '',
      documentNumber: '',
      phone: '',
      email: ''
    }
  })

  const handlePaymentMethod = (selectedMethod: number) => {
    setPaymentMethod(selectedMethod);
  }

  const submitCardForm = async (data: any) => {
    if(!getValuesCard("documentType")) {
      setErrorCard('documentType', {type: 'required'});
      return
    }
    if(!getValuesCard("phone")) {
      setErrorCard('phone', {type: 'required'});
      return
    }
    const token = localStorage.getItem('token');
    if (!token) {
      setOpenModal(true)
      setModalInfo({
        title: '¡No tienes una sesión activa!',
        description: 'Por favor inicia sesión de nuevo',
        style: 'error'
      })
      setTimeout(() => {
        setOpenModal(true)
        navigate('/login?redirect=/nido')
      }, 3000)
      return
    };


    // Extract month and year from expDate
    const expDate = data.expDate.split('/');

    const expMonth = expDate[0]
    const expYear = expDate[1]


    const requestBody = {
      cartId: cartId,
      customerEmail: data.email,
      address: data.address,
      customerFullName: data.cardHolder,
      customerDocumentType: data.documentType,
      customerDocumentNumber: data.documentNumber,
      customerPhoneNumber: data.phone,
      cardNumber: data.cardNumber,
      expMonth: expMonth,
      expYear: expYear,
      cvc: data.cvc,
    }

    try {
      setIsLoading(true);
      const result = await sendTransaction(requestBody)
      if(result) {
        const transaction = result; 
        if(transaction.data?.status === 'APPROVED') {
          window.location.href = `${urls.URL_FRONT}payment/successful?id=${transaction.data?.id}`;
        } else if(transaction.data?.status === 'PENDING') {
          setOpenModal(true)
          setModalInfo({
            title: '¡Tu pago esta pendiente de aprobación!',
            description: 'Tu pago esta pendiente por confirmar. Por favor revisa tu dashboard en unos minutos. Si tienes alguna duda, por favor contáctanos.',
            style: 'success'
          })
        } else if(transaction.data?.status === 'ERROR') {
          setOpenModal(true)
          setModalInfo({
            title: '¡Ha ocurrido un error!',
            description: `Ha ocurrido un error con tu pago. Intenta de nuevo \n ${result.data?.status_message}`,
            style: 'error'
          })
        } else if(transaction.data?.status === 'DECLINED') {
          setOpenModal(true)
          setModalInfo({
            title: '¡Pago declinado!',
            description: `Tu pago a sido declinado. \n ${transaction.data?.status_message}`,
            style: 'error'
          })
        } else {
          setOpenModal(true)
          setModalInfo({
            title: '¡Algo salio mal!',
            description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo! \n ${transaction?.data?.message}`,
            style: 'error'
          })
          setIsLoading(false);
          return
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      setOpenModal(true)
      setModalInfo({
        title: '¡Algo salio mal!',
        description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo! \n ${error.response.data.message}`,
        style: 'error'
      })
       setIsLoading(false);
       console.log(error)
    }
  }

  const submitPseForm = async (data: any) => {
    if (!getValuesPse("financialInstitutionCode")) {
      setErrorPse('financialInstitutionCode', {type: 'required'});
      return
    }
    if(!getValuesPse("userType")) {
      setErrorPse('userType', {type: 'required'});
      return
    }
    if(!getValuesPse("documentType")) {
      setErrorPse('documentType', {type: 'required'});
      return
    }
    if(!getValuesPse("phone")) {
      setErrorPse('phone', {type: 'required'});
      return
    }
    const token = localStorage.getItem('token');
    if (!token) {
      setOpenModal(true)
      setModalInfo({
        title: '¡No tienes una sesión activa!',
        description: 'Por favor inicia sesión de nuevo',
        style: 'error'
      })
      setTimeout(() => {
        setOpenModal(true)
        navigate('/login')
      }, 2000)
      return
    };


    const requestBody = {
      cartId: cartId,
      financialInstitutionCode: data.financialInstitutionCode,
      redirectUrl: `${urls.URL_FRONT}payment/successful`,
      customerEmail: data.email,
      address: data.address,
      customerFullName: data.fullName,
      customerUserType: data.userType,
      customerDocumentType: data.documentType,
      customerDocumentNumber: data.documentNumber,
      customerPhoneNumber: data.phone,
    }
    setIsLoading(true);

    try {
      const result = await sendTransaction(requestBody)
      const transaction = result;
      if(!transaction.success) {
        setOpenModal(true)
        setModalInfo({
          title: '¡Algo salio mal!',
          description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo \n ${transaction?.message}`,
          style: 'error'
        })
        setIsLoading(false);
        return
      } 
      let redirectUrl = transaction?.data?.payment_method?.extra?.async_payment_url;

      if(!redirectUrl) {
        setOpenModal(true)
        setModalInfo({
          title: '¡Algo salio mal!',
          description: 'Ha ocurrido un error redirigiendo a PSE. ¡Inténtalo de nuevo!',
          style: 'error'
        })
        setIsLoading(false);
        return
      }
      setIsLoading(false);
      return window.location.href = redirectUrl; 
    } catch (error: any) {
      setOpenModal(true)
      setModalInfo({
        title: '¡Algo salio mal!',
        description: `Ha ocurrido un error creando la transacción. ¡Inténtalo de nuevo! \n ${error?.response?.data?.message}`,
        style: 'error'
      })
       setIsLoading(false);
       console.log(error)
    }

  }

  const onErrorPse = (errors: any, event: any) => {
    if (!getValuesPse("financialInstitutionCode")) {
      setErrorPse('financialInstitutionCode', {type: 'required'});
    }
    if(!getValuesPse("userType")) {
      setErrorPse('userType', {type: 'required'});
    }
    if(!getValuesPse("documentType")) {
      setErrorPse('documentType', {type: 'required'});
    }
    if(!getValuesPse("phone")) {
      setErrorPse('phone', {type: 'required'});
    }
  }

  const onErrorCard = (errors: any, event: any) => {
    if(!getValuesCard("documentType")) {
      setErrorCard('documentType', {type: 'required'});
    }
    if(!getValuesCard("phone")) {
      setErrorCard('phone', {type: 'required'});
    }
  }

  const setCustomValuePse = (id: string, value: any, required: boolean) => {
    setValuePse(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  const setCustomValueCard = (id: string, value: any, required: boolean) => {
    setValueCard(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  return (
    <div className="mt-8 md:mt-32"> 
      <div className="grid grid-cols-2">

        <div className="col-span-2 md:col-span-1">
          <div>
          <h2 className="font-extrabold text-3xl mb-3 font-lato">
              Pago con { paymentMethod === 1 ? 'Tarjeta de crédito' : paymentMethod === 2 ? 'PSE' : ''}
            </h2>
            <p><span className="font-bold">Recuerda:</span> Que el pago con tarjeta de crédito puede tener fees adicionales dentro de tu inversión</p>
          </div>

          {
            paymentMethod === 1 || paymentMethod === 2 ? 
            <div>
              <PaymentMethodSelect selectedMethod={paymentMethod} showOne={true} handleMethodSelected={handlePaymentMethod} />
            </div>
            : <p className='mt-2'>Parece que no has seleccionado ningún método de pago</p>
          }

          {
            paymentMethod === 1 &&
            <CreditCardForm
              errors={errorsCard}
              register={registerCard}
              setValue={setValueCard}
              setCustomValue={setCustomValueCard}
              clearErrors={clearErrorsCard}
            />
          }

          {
            paymentMethod === 2 &&
            <div>
              <PseForm
                errors={errorsPse}
                register={registerPse}
                setValue={setValuePse}
                setCustomValue={setCustomValuePse}
                clearErrors={clearErrorsPse}
              />
            </div>
          }

        </div>

        <div className="col-span-2 md:col-span-1 md:ml-16 flex flex-col gap-6 mt-4 md:mt-0">
          <div className="hidden md:block">
            {/* Banner */}
            <Banner/>
          </div>
          {/* Payment summary */}
          <SummaryCard
            handleSubmitPse={handleSubmitPse(submitPseForm, onErrorPse)}
            handleSubmitCard={handleSubmitCard(submitCardForm, onErrorCard)}
            paymentMethod={paymentMethod}
            installments={installments}
            totalInvestment={totalInvestment}
            financingCost={financingCost}
            subtotal={subtotal}
            paymentMethodFee={paymentMethodFee}
            total={total}
            isLoading={isLoading}
          />
        </div>

      </div>
      <PaymentResponseModal
        isOpen={openModal}
        title={modalInfo.title}
        description={modalInfo.description}
        handleOpenModal={setOpenModal}
        modalStyle={modalInfo.style}
        buttonLabel='Aceptar'
      />
    </div>
  )
}
