import React from 'react'
import { DropTargetMonitor, useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import uploadIcon from '../assets/img/payment/upload-icon.svg'

export interface TargetBoxProps {
  onDrop: (item: { files: any[] }) => void
}

function DragAndDrop(props: any) {

  const { text, onDrop, imgPreview } = props;

  // const [droppedFiles, setDroppedFiles] = useState<File[]>([])

  // const handleFileDrop = useCallback(
  //   (item: { files: any[] }) => {
  //     if (item) {
  //       const files = item.files
  //     }
  //   },
  //   [setDroppedFiles],
  // )
  
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item)
        }
      },
      canDrop(item: any) {
        // console.log('canDrop', item.files, item.items)
        return true
      },
      hover(item: any) {
        // console.log('hover', item.files, item.items)
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any
        if (item) {
          // console.log('collect', item.files, item.items)
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    [],
  )

  const isActive = canDrop && isOver;

  console.log();

  return (
    <label className="flex justify-center items-center w-full lg:w-[47%] h-52 cursor-pointer" style={{ backgroundColor: isActive === true ? '#6C6AFF' : '#F2F2F2' }} >

      <div className="flex flex-col justify-center items-center h-52" ref={drop} >
        {
          imgPreview !== null ?
          <img className='max-w-full max-h-full' src={ typeof imgPreview === 'string' ? imgPreview : URL.createObjectURL(imgPreview.files[0]) } alt="" />
          :
          <>
            <img width="21" height="24" src={uploadIcon} alt="upload-icon" />
            <div className="text-sm">{isActive ? 'Arrastra aquí' : text}</div>
          </>
        }
      </div>

      <input type="file" onChange={(e: any) => onDrop(e.target)} style={{ display: 'none' }} />

    </label>
  )
}

export default DragAndDrop;
