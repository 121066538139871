import img2 from "../../../assets/img/blog/card-2.png";

export const ArticlesCarousel = () => {
    return (
        <div className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

            <div className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl">

                <div className="flex flex-col items-start space-y-7 mr-0 md:mr-5">

                    <div className="text-white/50">EDUCACIÓN</div>

                    <div className='font-lato font-extrabold text-2xl'>
                        5 secretos para usar el <br /> dinero adecuadamente, <br /> planifica e invierte a largo <br /> plazo.
                    </div>

                </div>

                <div
                    className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                    onClick={() => {
                        window.location.href = "/blog/planificar-presupuesto-largo-plazo"
                    }}
                >
                    Ver nota
                </div>

            </div>


            <div className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl">

                <div className="flex flex-col items-start space-y-7 mr-0 md:mr-5">

                    <div className="text-white/50">INVERSIÓN</div>

                    <div className='font-lato font-extrabold text-3xl'>
                        Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones

                    </div>

                    <p className='text-sm w-[350px]'>
                        Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante
                    </p>

                    <div
                        className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                        onClick={() => {
                            window.location.href = "/blog/inversiones-colaborativas-hospitality"
                        }}
                    >
                        Ver nota
                    </div>

                </div>

                <img src={img2} alt="banner-card2-lokl" />

            </div>

        </div>

    )
}
