import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DragAndDrop from '../../Payment/dragAndDrop';
import backIcon from "../../assets/img/checkout/back-icon.svg";

function Identity() {

  const [imgFront, setImgFront] = useState<any>(null);
  const [imgBack, setImgBack] = useState<any>(null);

  // const location = useLocation();
  const navigate = useNavigate();

  const continueMobile = () => {

    const prevData: any = JSON.parse(localStorage.getItem('dataBilling') || '');

    const dataBilling = {
      ...prevData,
      document_front: imgFront !== null && imgFront?.files?.length > 0 ? imgFront.files[0] : "",
      document_back: imgBack !== null && imgBack?.files?.length > 0 ? imgBack.files[0] : "",
    }

    console.log('data prevData', prevData);
    console.log('data billing', dataBilling);

    localStorage.setItem('dataBilling', JSON.stringify(dataBilling));

    navigate('/checkout/contract')

  }

  return (
    <div>

      <div className="flex lg:hidden font-black font-lato text-xl mb-3"
        onClick={() => {
          navigate('/checkout/personal-data');
        }}
      >
        <img src={backIcon} className='mr-3' alt='icon-back' />  
        Volver
      </div>
      
      <div className="font-black font-lato text-3xl">Autentificación de identidad</div>

      <div className="text-base text-gray-500 leading-7 py-3">

        Para continuar la compra primero deberás añadir tu documento oficial, con esto nos ayudas a verificar tu identidad. Este proceso asegura que cada miembro de la plataforma cuenta con una identidad legal válida.

      </div>

      <div className="flex flex-col lg:flex-row justify-between items-center space-y-6">

        <DragAndDrop
          text="Subir archivo frontal"
          onDrop={setImgFront}
          imgPreview={imgFront}
        />
        
        <DragAndDrop
          text="Subir archivo trasero"
          onDrop={setImgBack}
          imgPreview={imgBack}
        />

      </div>

      <div className="text-base text-gray-500 leading-7 py-3">
        Documentos aceptados:  Cédula de ciudadania, pasaporte.
      </div>

      <div className="lg:hidden flex justify-center mt-7">

        <button 
          className="w-full bg-gray-900 text-white hover:bg-gray-700 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => continueMobile()}
        >
          Continuar
        </button>

      </div>


    </div>
  )
}

export default Identity;