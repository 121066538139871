import React from 'react';

interface ArticleTextCardProps {
    title: string;
    title2?: string;
    description: string;
    description2?: string;
    reverse?: boolean;
}

export const ArticleTextCard: React.FC<ArticleTextCardProps> = ({ title, title2, description, description2, reverse = false }) => {
    return (
        <div className={`w-full gap-5 flex-col md:flex justify-between ${reverse ? 'flex-row-reverse' : 'flex-row'}`}>
            <div className="w-full md:w-1/2">
                <h2 className='blog-title-md mb-12'>{title}</h2>
                <div className="flex flex-col justify-between">
                    <p className="text-base font-roboto mb-12">
                        {description}
                    </p>
                </div>
            </div>

            <div className="w-full md:w-1/2">
                <h2 className='blog-title-md mb-12'>{title2}</h2>
                <div className="flex flex-col justify-between">
                    <p className="text-base font-roboto mb-12">
                        {description2}
                    </p>
                </div>
            </div>
        </div>
    );
};
