import axios from "axios";
import { urls } from "../helpers/urls";

// variable que devuelve el token
const getToken = () => localStorage.getItem("token");

export const createdPayoutRequest = async(data: any) => {
  try {
    const response = await axios.post(`${urls.NEW_API_URL}dividendPayoutRequest`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}
