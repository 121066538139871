import React, { useEffect, useState } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import es from 'react-phone-number-input/locale/es.json';
import Select from 'react-tailwindcss-select';
import { fetchCitiesNew, fetchStates } from '../../../../services/regionsServices';

interface PersonalDataFormProps {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  isLoading?: boolean
  setValue: any,
  setCustomValue?: any
  clearErrors?: any
  getValues?: any
  setPhone: any,
  phone: any
}

export const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  register,
  errors,
  isLoading,
  setValue,
  setCustomValue,
  clearErrors,
  getValues,
  setPhone,
  phone
}) => {

  const [states, setStates] = useState([]);

  const [documentTypes] = useState<any>([
    { value: 'CC', label: 'Cédula de ciudadanía' },
    { value: 'PS', label: 'Pasaporte' },
    { value: 'CCE', label: 'Cédula de extranjería' },
  ]);

  const [state, setState] = useState<any>(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [maxDateValidation, setMaxDateValidation] = useState<any>(null);
  const [citySelectIsLoading, setCitySelectIsLoading] = useState<boolean>(false);

  const handlePhoneChange = (value: any) =>  {
    setCustomValue('phone', value);
    setPhone(value);
    clearErrors('phone');
  }
  
  const handleState = (val: any) => {
    setCustomValue('state', val.value);
    setState(val);
    clearErrors('state');
  }

  const handleCity = (val: any) => {
    setCustomValue('city', val.value);
    setCity(val);
    clearErrors('city');
  }

  useEffect(() => {
    getDatePickerAgeValidation()
    fetchStates('co').then((res: any) => {
      setStates(res);
    })
  }, []);

  useEffect(() => {
    if (!state) return;
    setCity(null);
    setCities([]);
    setCitySelectIsLoading(true);
    fetchCitiesNew('co', state?.value).then((res: any) => {
      setCities(res);
    }).finally(() => {
      setCitySelectIsLoading(false);
    });
  }, [state]);

  const getDatePickerAgeValidation = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    const ageToValidate = 18;
    const maxDate = new Date(currentYear - ageToValidate, currentMonth, currentDay);
    const maxDateValidation = maxDate.toISOString().split('T')[0];
    setMaxDateValidation(maxDateValidation)
  }

  return (
    <div className="grid grid-cols-12 gap-y-2 my-3">
      <div className="flex flex-col col-span-6">
        <label htmlFor="firstName" className="text-gray-500 mb-1">Nombre</label>
        <input
          {...register('firstName', { required: true })}
          type="text"
          id='firstName'
          className={`
            border-[1px]
            ${!errors.firstName ? 'border-gray-300' : 'border-red-500'}
            ${errors.firstName ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            rounded-l-sm
            outline-none
          `} />
          {(errors.firstName)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-6">
        <label htmlFor="lastName" className="text-gray-500 mb-1">Apellido</label>
        <input
          {...register('lastName', { required: true })}
          type="text"
          id='lastName' 
          className={`
            border-[1px]
            ${!errors.lastName ? 'border-gray-300' : 'border-red-500'}
            ${errors.lastName ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            rounded-l-sm
            outline-none
          `} />
          {(errors.lastName)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="documentType" className="text-gray-500 mb-1">Tipo de documento*</label>
        <select
          className="py-2.5 bg-white rounded-none border px-3"
          id="documentType"
          {...register("documentType", { required: true })}
        >
          <option value="" disabled>Tipo de documento</option>
          {
            documentTypes.map((document: any) => (
              <option  key={document.value} value={document.value}>{document.label}</option>
              ))
          }
        </select>
        {(errors.documentType)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="documentNumber" className="text-gray-500 mb-1">Número de identificación</label>
        <input
          {...register('documentNumber', { required: true })}
          type="text"
          id='documentNumber'
          className={`
            border-[1px]
            ${!errors.documentNumber ? 'border-gray-300' : 'border-red-500'}
            ${errors.documentNumber ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            rounded-l-sm
            outline-none
          `} />
          {(errors.documentNumber)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="birthDate" className="text-gray-500 mb-1">Fecha de nacimiento</label>
        <input
          {...register('birthDate', { required: true })}
          type="date"
          id='birthDate'
          max={maxDateValidation}
          className={`
            border-[1px]
            ${!errors.birthDate ? 'border-gray-300' : 'border-red-500'}
            ${errors.birthDate ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            rounded-l-sm
            outline-none
          `} />
          {(errors.birthDate)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}          
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="phone" className="text-gray-500 mb-1">Teléfono*</label>
        <PhoneInput
          className={`
            border-[1px]
            py-2.5
            px-4

            ${errors.phone ? 'border-red-500' : 'border-gray-300'}
          `}
          international
          countryCallingCodeEditable={false}
          defaultCountry='CO'
          labels={es}
          placeholder="Teléfono"
          value={phone}
          onChange={(value: any) => {
            handlePhoneChange(value);
          }}
        />
        {(errors.phone)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="address" className="text-gray-500 mb-1">Dirección de domicilio*</label>
        <input
          {...register('address', { required: true })}
          type="text"
          id='address'
          className={`
            border-[1px]
            ${!errors.address ? 'border-gray-300' : 'border-red-500'}
            ${errors.address ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            rounded-l-sm
            outline-none
          `} />
          {(errors.address)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>

      <div className="flex flex-col col-span-6">
        <label htmlFor="documentType" className="text-gray-500 mb-1">Departamento/Estado*</label>
        <Select
          classNames={{
            menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.documentType ? 'border-gray-300' : 'border-red-500'} py-1 px-4 outline-none cursor-pointer focus:border-violet-600`,
            ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
          }}
          primaryColor='violet'
          value={state}
          loading={states.length <= 0}
          options={states.map((res: any) => ({ value: res.id, label: res.name }))}
          placeholder="Departamento/Estado"
          onChange={(val: any) => {
            handleState(val)
          }}
        />
        {(errors.state)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>

      <div className="flex flex-col col-span-6">
        <label htmlFor="documentType" className="text-gray-500 mb-1">Ciudad*</label>
        <Select
          classNames={{
            menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.documentType ? 'border-gray-300' : 'border-red-500'} py-1 px-4 outline-none cursor-pointer focus:border-violet-600`,
            ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
          }}
          primaryColor='violet'
          value={city}
          loading={citySelectIsLoading}
          options={cities.map((res: any) => ({ value: res.name, label: res.name }))}
          placeholder="Ciudad"
          onChange={(val: any) => {
            handleCity(val)
          }}
        />
        {(errors.city)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>

    </div>
  )
}
