import React from 'react'
import img1 from "../../assets/img/blog/card-1.png";
import img2 from "../../assets/img/blog/card-2.png";
import { motion } from "framer-motion";

// import image from "../../assets/img/blog/manage-money.png";
// import llaves from "../../assets/img/blog/llaves.png";
// import mapa from "../../assets/img/blog/mapa.png";
// import planta from "../../assets/img/blog/planta.png";
// import image2 from "../../assets/img/blog/image-2-article.png";
// import vecindario from "../../assets/img/blog/vecindario.svg";
// import lokl from "../../assets/img/blog/lokl-black.svg";
// import forbes from "../../assets/img/blog/forbes.svg";
// import republica from "../../assets/img/blog/republica.svg";
// import semana from "../../assets/img/blog/semana.svg";
// import { Tab, TabList } from 'react-tabs';
// import { scrollToSection } from '../../helpers/functions';

function ArticleSectores() {

  // const optionsList = [
  //   "Dejanos tus comentario",
  //   "Comentarios",
  // ];

  // const [tabOptions /*, setTabOptions*/] = useState(optionsList);
  // const [activeTabIndex /*, setActiveTabIndex */] = useState(0);

  return (
    <section id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <div className="bg-note-img bg-cover bg-center h-[432px] w-full flex items-center justify-center">

        <div className="wrapper-lokl text-white ">

          <div className="w-1/2">

            <h1 className="font-lato font-extrabold text-[38px] mb-3">
              Descubre los mejores sectores para invertir en Colombia
            </h1>

            <p className="text-xl">
              Oportunidades de crecimiento en el 2023
            </p>
          
          </div>

        </div>

      </div>

      <div className="wrapper-lokl space-y-10 py-16">

        {/* <div className="w-full flex justify-end">

          <div className="flex space-x-2 mr-3">

            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>

          </div>

          <div className="underline cursor-pointer" onClick={() => scrollToSection('comments', 150)}>35 Comentarios</div>

        </div> */}

        <div className="w-full flex justify-between">

          <div className="w-[67%] mr-10">

            <h2 className="blog-title-md mb-4">
              Introducción
            </h2>

            <p className="text-base font-roboto text-justify">
              Colombia es una economía en crecimiento con una amplia gama de sectores que ofrecen oportunidades de inversión interesantes. Con un entorno empresarial en constante evolución y una economía diversificada, los inversionistas tienen varias opciones para considerar. En esta nota de blog, exploraremos algunos de los mejores sectores para invertir en Colombia en el 2023, basados en las tendencias actuales y las perspectivas económicas del país.
            </p>

          </div>

          <div className="h-auto flex justify-end items-start">

            <div className="flex space-x-2 mr-3">

              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>

            </div>

            <div className="underline cursor-pointer">5</div>

          </div>

        </div>

        <div className="w-[67%] flex justify-between items-start">

          <div className="flex flex-col justify-center">

            <div className="flex flex-col space-y-6">
              
              <div className="flex flex-col justify-center">

                <h2 className='blog-title-sm mb-3'>1. Energías renovables</h2>

                <div className="flex flex-col justify-between">

                  <p className="text-base font-roboto text-justify">
                    Colombia ha experimentado un crecimiento significativo en la inversión en energías renovables en los últimos años, con un enfoque en proyectos de energía solar, eólica y biomasa. El país tiene un enorme potencial para la generación de energía a partir de fuentes renovables, lo que ha llevado a la implementación de políticas gubernamentales y programas de incentivos para fomentar la inversión en este sector. Además, la creciente conciencia sobre la sostenibilidad y la lucha contra el cambio climático a nivel mundial, hace que la inversión en energías renovables en Colombia sea una oportunidad atractiva en el 2023.
                  </p>

                </div>
              
              </div>
              
              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>2. Sector Agroindustrial</h2>

                <div className="flex flex-col justify-between h-28">

                  <p className="text-base font-roboto text-justify">
                    Colombia es conocido por su riqueza en recursos naturales y su diversidad agrícola. El sector agroindustrial, que incluye la agricultura, la ganadería, la pesca y la industria alimentaria, ofrece oportunidades de inversión en el país. Con una creciente demanda de productos agrícolas y pecuarios tanto a nivel nacional como internacional, así como la implementación de políticas gubernamentales para impulsar la competitividad del sector, la inversión en el sector agroindustrial puede ser una opción atractiva en el 2023.
                  </p>

                </div>

              </div>

            </div>

            <div className="my-10">
            
              <div className="mb-8">
                Lee también: <a className='hover:text-[#17008A]' href="/blog/donde-invertir-colombia-2023"> <b> ¿Dónde invertir en Colombia en el 2023? </b> </a>
              </div>

              <hr className="border-t border-gray-400"/>
            
            </div>

            <div className="flex flex-col space-y-6">

              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>3. Tecnología y telecomunicaciones</h2>

                <div className="flex flex-col justify-between">

                  <p className="text-base font-roboto text-justify">
                    El sector tecnológico y de telecomunicaciones en Colombia ha experimentado un crecimiento constante en los últimos años. La penetración de Internet y el uso de dispositivos móviles continúa aumentando, lo que crea oportunidades en áreas como el desarrollo de software, la tecnología financiera (fintech), el comercio electrónico, las telecomunicaciones y la ciberseguridad. Además, el gobierno colombiano ha implementado políticas de impulso a la economía digital, lo que hace que el sector tecnológico sea un área atractiva para la inversión en el 2023.
                  </p>

                </div>

              </div>

              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>4. Infraestructura</h2>

                <div className="flex flex-col justify-between">

                  <p className="text-base font-roboto text-justify">
                    La inversión en infraestructura es una prioridad en Colombia, con proyectos de construcción de carreteras, puentes, puertos, aeropuertos y otros proyectos de infraestructura en marcha o planeados. El país está buscando mejorar su infraestructura para impulsar la competitividad y el desarrollo económico. La inversión en este sector puede ofrecer oportunidades atractivas para los inversionistas en el 2023, especialmente con el apoyo del gobierno y la creciente demanda de infraestructura moderna y eficiente.
                  </p>

                </div>

              </div>

              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>5. Turismo</h2>

                <div className="flex flex-col justify-between mb-4">

                  <p className="text-base font-roboto text-justify">
                    Colombia ha experimentado un crecimiento constante en el sector turístico en los últimos años, con un aumento en la llegada de turistas internacionales y un aumento en la inversión en infraestructura turística.
                  </p>

                </div>

                <div className="mb-4">

                  <hr className="border-t border-gray-400"/>
                
                </div>

                <div className="text-xl">
                  Recuerda <a className='hover:text-[#17008A]' href="/register"><b>registrarte</b></a> para recibir información de primera mano, sobre nuestros proyectos.
                </div>

              </div>

              <div className="flex flex-col justify-start space-y-2 ml-4">

                <div className="">
                  Empieza a invertir en el futuro que quieres vivir. <br /> <a className='hover:text-[#17008A]' href="/#projects"><b>Invierte con LOKL</b></a>.
                </div>

                <div className="">
                  Escrito por Veronica Porras.
                </div>

              </div>

            </div>

          </div>

        </div>

        {/* <div id='comments'>

          <TabList className="flex space-x-6 font-bold text-lg list-none mb-8">

            {
              tabOptions.map((tab, key) => 
                <Tab key={key} className={ `min-w-[100px] cursor-pointer outline-none text-center font-roboto ${ key === activeTabIndex ? ' text-black ' : 'text-gray-400' }` }>
                  {tab}
                  { key === activeTabIndex && <div className='w-full h-1 bg-black mt-2'></div> }
                </Tab>
              )
            }

          </TabList>

          <div className="bg-white w-2/3 p-12 flex flex-col items-center rounded-xl">

            <div className="flex flex-col w-full">

              <label htmlFor="commentInput">¿Tienes preguntas?</label>
              <textarea className='outline-none' name="" id="commentInput" cols={30} rows={10}></textarea>

            </div>

            <button className="btn-primary">
              Comentar
            </button>

          </div>

        </div> */}


        <div className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <h3 className="text-white/50">INVERSIÓN</h3>

              <h2 className='font-lato font-extrabold text-3xl'>¿Dónde invertir en <br /> Colombia en el 2023?</h2>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante evolución, es esencial investigar y considerar cuidadosamente las opciones de inversión disponibles.
              </p>

              <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/donde-invertir-colombia-2023"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" /> 

          </motion.div>

          <motion.div 
            className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <h3 className="text-white/50">INVERSIÓN</h3>

              <h2 className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones
              </h2>

              <p className='text-sm w-[350px]'>
                Las nuevas generaciones tienen un enfoque diferente en cuanto a su relación con el dinero. Muchos jóvenes buscan opciones de inversión que les permitan obtener ganancias, pero también que tengan un impacto positivo en el mundo.
              </p>

              <div 
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" /> 

          </motion.div>

        </div>

      </div>

    </section>
  )
}

export default ArticleSectores;