import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated, numberWithCommas, unitPrice } from '../../helpers/functions';
import { useEffectOnce } from '../../helpers/hooks';
import backIcon from "../../assets/img/checkout/back-icon.svg";

import pseOn from '../../assets/img/payment/ban-on.svg';
import pseOff from '../../assets/img/payment/ban-off.svg';
import cardOn from '../../assets/img/payment/visa-on.svg';
import cardOff from '../../assets/img/payment/visa-off.svg';
import moment from 'moment';
import { getLastIdProject } from '../../services/ownerServices';

function SummaryInvest() {
  
  const priceFee = 2.5;
  const navigate = useNavigate();
  const location = useLocation();
  const priceUnit = unitPrice();
  const units: any = localStorage.getItem('amount');
  const instalmentValue: any = localStorage.getItem('instalmentValue') || priceUnit * 100;
  const monthSelected: any = localStorage.getItem('installment') || 1;
  const initialAmount = priceUnit * 100;

  const [methodSelected, setMethodSelected] = useState<any>(0);
  const [inversion, setInversion] = useState<any>(0);
  const [feePayment, setFeePayment] = useState<any>(0);
  const [total, setTotal] = useState<any>(parseInt(instalmentValue));
  const [error, setError] = useState(false);

  /**
   * Funcion para la opción pse
   */
  const pseSelect = () => {
    
    setMethodSelected(2);
    setFeePayment(0);
    setTotal(total - feePayment);

    localStorage.setItem('feePayment', '0');
    localStorage.setItem('methodSelected', '2');
  
  }

  /**
   * Funcion para la opción de tarjeta de crédito
   */
  const tdcSelect = () => {
    
    const calculationFee = (inversion / priceFee) / 100;
 
    setMethodSelected(1);
    setFeePayment(calculationFee);
    setTotal((parseInt(instalmentValue) || initialAmount) + calculationFee);

    localStorage.setItem('feePayment', calculationFee.toString());
    localStorage.setItem('methodSelected', '1');
  
  }

  /**
   * Función para seleccionar el proyecto
   */
  const getProject = async () => {

    const id_project: any = await getLastIdProject();
    localStorage.setItem('id_project', id_project?.data?.data[1]?._id)

  }

  /**
   * Función para el boton de invertir
   */
  const invertir = () => {

    if(methodSelected === 1 || methodSelected === 2){

      localStorage.setItem('instalmentValue', total.toString());
      localStorage.setItem('installments', monthSelected);

      navigate('/checkout/personal-data', {
        state: {
          ...location.state
        }
      });
    
    }else{

      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000); 

    }

  }

  useEffectOnce(() => {

    isAuthenticated();
 
    getProject();

    if(units){

      const amount = parseInt(units) * priceUnit;
      setInversion(amount);

    }else{

      setInversion(initialAmount);
      setTotal(initialAmount);

    }

  })

  return (
    <div>      

      <div className="flex font-black font-lato text-xl mb-3"
        onClick={() => {
          navigate('/nido');
        }}
      >
        <img src={backIcon} className='mr-3' alt='icon-back' />  
        Volver
      </div>

      <div className="font-black font-lato text-3xl mb-3">Selecciona tu pago</div>

      <div className="font-noto text-base text-gray-500 mb-7">
        Escoge tu mejor opción de pago e inicia tu camino como inversionista en LOKL.
      </div>

      <div className="text-sm bg-gray-200 rounded py-3 px-4 mb-4">
        <strong>Recuerda:</strong> El pago con tarjeta de crédito puede tener fees adicionales dentro de tu inversión.
      </div>

      <div className="flex mb-10">

        <div 
          className="w-1/2 text-center"
          onClick={() => tdcSelect()}
        >
          <div className="flex">
            <img width={30} src={ methodSelected === 1 ? cardOn : cardOff } alt="" />
            <div className={`text-base py-2 ${ methodSelected === 1 ? 'text-purple-600' : 'text-gray-400' } `}>Tarjeta de credito</div>
          </div>
          <div className={`w-full h-1 ${ methodSelected === 1 ? 'bg-purple-600' : 'bg-gray-300' }`}></div>
        </div>

        <div 
          className="w-1/2 text-center"
          onClick={() => pseSelect()}
        >
          <div className="flex">
            <img width={30} src={ methodSelected === 2 ? pseOn : pseOff } alt="" />
            <div className={`text-base py-2 ${ methodSelected === 2 ? 'text-purple-600' : 'text-gray-400' } `}>PSE</div>
          </div>
          <div className={`w-full h-1 ${ methodSelected === 2 ? 'bg-purple-600' : 'bg-gray-300' }`}></div>
        </div>

      </div>

      <div className="flex flex-col justify-center">

        <div className="flex flex-col justify-center bg-blue-lokl-card px-9 py-6 rounded-t-lg space-y-2">
          
          <div className="font-black font-lato text-[24.5px] pt-2 text-center">Resumen de inversión</div>

          <div className="w-full">

            <div className="">{moment().format('MMM D, YYYY')}</div>

          </div>

          <div className="w-full flex justify-between">

            <div className="">P: Nido de Agua</div>

            <div className="bg-white pt-2 px-3 text-xs font-noto font-semibold leading-none rounded-sm">Etapa 1</div>

          </div>

        </div>

        <div className="px-9 py-6 border-[1px] border-gray-300 rounded-b-lg">

          <div className="font-bold mb-3">Pago por suscripción</div>

          <div className="flex justify-between">

            <div className="">Inversion:</div>
            <div className="font-black">${ numberWithCommas(inversion) }</div>

          </div>
          <div className="flex justify-between">

            <div className="">Cuotas diferidas:</div>
            <div className="font-black">{ monthSelected } { monthSelected > 1 ? 'meses' : 'mes' }</div>

          </div>
          <div className="flex justify-between">

            <div className="">Subtotal:</div>
            <div className="font-black">${numberWithCommas(parseInt(instalmentValue))}</div>

          </div>

          <div className="text-blue-600 mb-4">Próximo pago {moment().add(1, 'months').format('MMM D, YYYY')}</div>

          <div className="flex justify-between">

            <div className="">Impuestos y tarifas:</div>
            <div className="font-black">${numberWithCommas(feePayment)}</div>

          </div>

          <div className="border-b-[1px] border-gray-300 my-3"></div>

          <div className="flex justify-between">

            <div className="">Total:</div>
            <div className="font-black">${numberWithCommas(parseInt(total))}</div>

          </div>

          <div className="text-center my-3">

            <a className='text-gray-400' href="/">
              Al dar click acepto términos y <br /> condiciones de pago
            </a>

          </div>

          <div className="flex justify-center">

            <button 
              className="bg-gray-900 text-white px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => invertir()}
            >
              Invierte
            </button>

          </div>

        </div>

        {
          error &&
          <div className="bg-red-400 p-4 text-white rounded mt-3">
            Para continuar selecciona tu método de pago
          </div>
        }

      </div>

    </div>
  )
}

export default SummaryInvest;