import { createRef, useEffect } from "react";
import { createPopper } from '@popperjs/core';

function PopupDisclaimerRetorno({ triggerRef, isVisible }: any) {

  const popoverRef: any = createRef();

  useEffect(() => {
    // if(isVisible){
    // }
    createPopper(triggerRef.current, popoverRef.current, {
      placement: "top"
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div 
      ref={popoverRef} 
      className={`${ isVisible === true ? 'block' : 'hidden' } w-72 bg-white z-10 text-sm text-[#928C8C] float-left p-3 border mb-2 rounded`}
    >
      * Todos estos números dependen netamente de la operación y pueden variar acorde a los resultados operativos de la misma.
    </div>
  )
}

export default PopupDisclaimerRetorno;