import React, { createRef, useEffect, useState } from 'react'
// import question from "../../assets/img/suscription-model/question.svg";
import arrowRight from '../../assets/img/home/arrow-right.svg';
import arrowLeft from '../../assets/img/home/arrow-left.svg';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { isAuthenticated, numberWithCommas, unitPrice } from '../../helpers/functions';
import { createPopper } from '@popperjs/core';
import heroe from '../../assets/img/nido/benefits/heroe.svg';
import sabio from '../../assets/img/nido/benefits/sabio.svg';
import aventurero from '../../assets/img/nido/benefits/aventurero.svg';
import nidos from '../../assets/img/nido/benefits/nidos.svg';
import { calculateSubs } from '../../services/globalServices';

function Suscription() {

  const instalmentsData = [
    {
      month: 1,
      feePaymentPrice: 0,
      unitPricePay: unitPrice(),
      returnPay: 15,
    },
    {
      month: 3,
      feePaymentPrice: 0,
      unitPricePay: 0,
      returnPay: 0,
    },
    {
      month: 6,
      feePaymentPrice: 0,
      unitPricePay: 0,
      returnPay: 0,
    },
    {
      month: 9,
      feePaymentPrice: 0,
      unitPricePay: 0,
      returnPay: 0,
    },
    // {
    //   month: 12,
    //   feePaymentPrice: 0,
    //   unitPricePay: 0,
    //   returnPay: 0,
    // }
  ];
  
  const [instalments, setInstalments] = useState(instalmentsData);
  const [amountUnit, setAmountUnit] = useState({
    amount: 100,
    amountShow: '',
    amountSend: 100
  });
  const priceUnit = unitPrice();
  const [multipleInvest, setMultipleInvest] = useState<any>(0);
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [monthSelected, setMonthSelected] = useState<any>(1);
  const [inputActive, setInputActive] = useState(false);

  const selectDropdownRef: any = createRef();
  const selectedDropdownRef: any = createRef();
  const splideRef: any = createRef();

  const openDropdownPopover = () => {
    createPopper(selectDropdownRef.current, selectedDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const calculatePrices = () => {

    const newInstalment = instalments.map((ins: any) => {

      const projectReturn = 15;

      const amontInvest = priceUnit * amountUnit.amount;
  
      const inversion = amontInvest + ( amontInvest * ins.month / 100 );
  
      const unitPricePay = inversion / amountUnit.amount;
  
      const feePaymentPrice = Math.round(inversion / ins.month);
  
      let returnPay: any = (((projectReturn * priceUnit) / 100) / unitPricePay) * 100;
      returnPay = returnPay.toFixed(2);

      if(ins.month > 1){
        return {
          ...ins,
          feePaymentPrice,
          unitPricePay,
          returnPay,
        }
      }else{
        return{
          ...ins,
          feePaymentPrice: amontInvest
        }
      }

    });

    // console.log(newInstalment);

    setInstalments(newInstalment);
    
  }

  const profileInvestor = () => {

    if(amountUnit.amount >= 1000) {
      return <div className='flex justify-center items-center ml-1.5'> Sabio <img width={40} className='ml-2' src={sabio} alt="" /></div>;
    }

    if(amountUnit.amount >= 500) {
      return <div className='flex justify-center items-center ml-1.5'> Héroe <img width={40} className='ml-2' src={heroe} alt="" /> </div>;
    }
    
    if(amountUnit.amount >= 250) {
      return <div className='flex justify-center items-center ml-1.5'> Aventurero <img width={40} className='ml-2' src={aventurero} alt="" /></div>;
    }

    return <div className='flex justify-center items-center ml-1.5'> Explorador <img width={40} className='ml-2' src={nidos} alt="" /></div>;

  }

  const nextProfileInvestor = () => {

    if(amountUnit.amount >= 1000) {
      return 'Sabio';
    }

    if(amountUnit.amount >= 500) {
      return 'Sabio';
    }
    
    if(amountUnit.amount >= 250) {
      return 'Héroe';
    }

    return 'Aventurero';

  }

  const nextAmountProfileInvestor = () => {
    const unitValue = 123000;
    let result: any;

    if(amountUnit.amount <= 250) {
      result = 250 * unitValue;
    }
    else if(amountUnit.amount <= 500) {
      result = 500 * unitValue;
    }
    else if(amountUnit.amount <= 1000) {
      result = 1000 * unitValue;
    }

    return numberWithCommas(result);

  }

  const selectingMonthPay = (ins: any) => {
                        
    isAuthenticated();

    console.log("splidejs", splideRef.current.splide);

    if(amountUnit.amount >= 100){

      const splideMove = splideRef.current.splide.Components.Move;
      const splideController = splideRef.current.splide.Components.Controller;
      const currentIndex = instalmentsData.findIndex(insd => insd.month === ins.month);

      calculatePrices();
      localStorage.setItem('amount', amountUnit.amount.toString());
      localStorage.setItem('share_value', priceUnit.toString());
      localStorage.setItem('installments', monthSelected);
      calculateSubs(amountUnit.amountShow, monthSelected, amountUnit.amount);
      
      setMonthSelected(ins.month);
      closeDropdownPopover();

      splideMove.jump(currentIndex);
      splideController.go(3);

    }else{

      selectOnePay();
      closeDropdownPopover();
    
    }
 
  }

  const selectOnePay = () => {
    
    setMultipleInvest(false);
    setMonthSelected(1);

    calculatePrices();
    localStorage.setItem('amount', amountUnit.amount.toString());
    localStorage.setItem('share_value', priceUnit.toString());
    localStorage.setItem('installments', monthSelected);
    calculateSubs(amountUnit.amountShow, monthSelected, amountUnit.amount);
    
    const splideMove = splideRef.current.splide.Components.Move;
    const controller = splideRef.current.splide.Components.Controller;
    controller.scroll(0);
    splideMove.jump(0);
    controller.go(0);

  }

  useEffect(() => {

    calculatePrices();

    // eslint-disable-next-line
  }, []);
  

  return (
    <section id="suscription" className='w-full flex flex-col text-center items-center'>

      <div className='font-black font-lato text-2xl lg:text-3xl'>Suscríbete y elige tu plan</div>
      
      <div className="text-gray-400 text-base lg:text-xl font-medium mt-2 mb-14">Calcular tus cuotas / Inversión mínima $12.3 Millones</div>

      <div className="w-full lg:w-[1024px] flex flex-col lg:flex-row lg:justify-center lg:items-center space-x-0 space-y-5 lg:space-x-6 lg:space-y-0">

        <div className="w-full lg:w-[30%] flex flex-col justify-between items-start">
          
          <div className="w-full flex justify-between items-end mb-4">

            <div className="text-base text-gray-400">¿Cuál es tu presupuesto para invertir?</div>
            {/* <img width={14} src={question} alt="" /> */}
          
          </div>
          
          <div className={`w-full flex items-center border-[1px] ${ inputActive ? 'border-violet-600' : 'border-gray-400' } rounded-sm`}>

            <div className={`font-bold text-black' text-xl pl-5`}>$</div>

            <input 
              type="text" 
              placeholder="Desde 12.300.000 COP"
              className="bg-transparent w-full lg:h-[50px] py-3 pr-3 font-lato font-black text-lg outline-none"
              defaultValue={100 * priceUnit}
              min={50 * priceUnit }
              onChange={(e) => {
                
                const value = e.target.value;
                const valueAmountUnit = parseInt(value.replace(/\./g, "")) / priceUnit;
                const valueWithComma = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                const valueSend = parseInt(value.replace(/\./g, ""));

                setAmountUnit({
                  ...amountUnit,
                  amount: valueAmountUnit,
                  amountShow: valueWithComma,
                  amountSend: valueSend
                });

                if(value.length >= 7){
                  localStorage.setItem('amount', valueAmountUnit.toString());
                }

              }}
              onFocus={() => {
                setInputActive(true);
              }}
              onBlur={() => {
                setInputActive(false);
              }}
              value={amountUnit.amountShow}
            />

            <div className="w-[.5px] h-8 border"></div>

            <div className="px-4">
              <div className="text-base font-semibold">{amountUnit.amount >= 50 && amountUnit.amount.toFixed(0)}</div>
              <div className="text-sm">Units</div>
            </div>

          </div>
        
        </div>

        <div className="w-full lg:w-[40%] lg:mx-5 flex flex-col justify-between items-start ">
          
          <div className="w-full flex justify-between items-end mb-4">

            <div className="text-base text-gray-400">¿En cuántas cuotas quieres pagar tu inversión?</div>
            {/* <img width={14} src={question} alt="" /> */}
          
          </div>
          
          <div className="w-full flex justify-between">

            <div 
              className={`w-[300px]`}
              onClick={() => setMultipleInvest( amountUnit.amount >= 100 )}
            >

              <button
                className={
                  `flex justify-between items-center w-full h-[51px] border-[1px] ${ multipleInvest ? 'border-violet-600 text-violet-600' : 'border-gray-400 text-gray-400' } ${ dropdownPopoverShow && multipleInvest && 'border-b-transparent' } rounded-sm text-sm px-6 py-3 outline-none mr-1`
                }
                type="button"
                ref={selectDropdownRef}
                onClick={() => {
                  dropdownPopoverShow
                    ? closeDropdownPopover()
                    : openDropdownPopover();
                }}
              >
                
                <div className={ `lg:text-base text-lg ${monthSelected > 1 ? 'lg:ml-14' : 'lg:ml-5'}` }>
                  { monthSelected > 1 ? monthSelected + ' Meses' : 'Cuotas mensuales' }
                </div>

                <div  className="">
                
                  <svg className={`ml-2 ${ dropdownPopoverShow && multipleInvest && 'rotate-180' } ${ multipleInvest ? 'stroke-violet-600' : 'stroke-gray-400' } duration-300 ease-in-out`} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.04395 1.90637L6.45771 6.99962L12.0423 2.09432" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                
                </div>

              </button>

              <div 
                ref={selectedDropdownRef} 
                className={
                  (multipleInvest && dropdownPopoverShow ? 'block' : 'hidden' ) + 
                  " border-x-[1px] border-b-[1px] min-w-[138px] lg:w-[207px] bg-white border-violet-600 text-base z-50 text-center -mt-[1px]"
                }
              >

                {
                  instalmentsData.filter((ins: any, i) => i !== 0).map((ins: any) => 
                    <div 
                      className="lg:text-sm text-lg py-3 px-4 no-underline font-normal block w-full whitespace-nowrap cursor-pointer text-violet-600 hover:bg-gray-200"
                      onClick={() => selectingMonthPay(ins)}
                    >
                      {ins.month} Meses
                    </div>
                  )
                }
                
              </div>

            </div>
            
            <div 
              className={`flex justify-center items-center w-[250px] ml-6 border-[1px] ${ multipleInvest === false ? 'border-violet-600 text-violet-600' : 'border-gray-400 text-gray-400' } p-3 rounded-sm font-lato font-normal text-md cursor-pointer`}
              onClick={() => selectOnePay()}
            >

              <div className="mr-2">
                
                <svg className={ multipleInvest === false ? 'fill-violet-600' : 'fill-gray-400' } width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.5493 0.914614C6.84934 -0.00524628 8.15066 -0.00524604 8.4507 0.914615L9.51761 4.18549C9.65183 4.59696 10.0355 4.87539 10.4683 4.87539H13.9126C14.8823 4.87539 15.2844 6.11698 14.4989 6.68549L11.7191 8.69731C11.3673 8.95195 11.22 9.40459 11.3547 9.81751L12.4181 13.0775C12.7184 13.9982 11.6655 14.7655 10.8811 14.1977L8.08629 12.1751C7.73644 11.9219 7.26357 11.9219 6.91371 12.1751L4.11893 14.1977C3.33446 14.7655 2.28165 13.9982 2.58194 13.0775L3.64531 9.81751C3.78 9.40459 3.63275 8.95195 3.2809 8.69731L0.501087 6.68549C-0.284439 6.11698 0.117715 4.87539 1.08738 4.87539H4.53169C4.96449 4.87539 5.34817 4.59696 5.48239 4.18549L6.5493 0.914614Z"/>
                </svg>

              </div>

              Pago único

            </div>
        
          </div>

        </div>

      </div>


      <div className="flex justify-center items-center my-4 lg:my-8 text-xl font-semibold">Inversionista {profileInvestor()}</div>

      <Splide
        options={{
          gap: '2rem',
          arrows: true,
          pagination: false,
          perMove: 1,
          focus: 'center',
          mediaQuery: 'min',
          start: monthSelected === 1 ? monthSelected - 1 : monthSelected === 3 ? monthSelected - 2 : monthSelected === 6 ? monthSelected - 4 : monthSelected === 9 ? monthSelected - 6 : monthSelected === 12 ? monthSelected - 7 : 0,
          breakpoints: {
            1280: {
              perPage: 3,
            },
            640: {
              perPage: 1,
            },
          }
        }}
        hasTrack={ false }
        className="w-full"
        ref={splideRef}
      >
        <div className="custom-wrapper" style={{ width: '100%' }}>

          <SplideTrack className="">

            {

              instalments.map((instalment: any, i: any) => 

                <SplideSlide className="">
                  
                  <div className={`flex my-3 border-[1px] border-gray-300 bg-white text-black rounded-xl flex-col justify-end items-center`}>

                    <div className={`relative ${ monthSelected === instalment.month ? 'bg-violet-300' : 'bg-gray-100' } w-full rounded-t-xl px-4`}>

                    {
                      instalment.month === 1 && monthSelected === 1 ?
                      <div className="absolute top-6 left-4 flex w-fit fill-violet-600 text-violet-600 bg-white text-[11px] py-1 px-2 rounded-md">
                        <svg className='fill-violet-600 mr-1' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5493 0.914614C6.84934 -0.00524628 8.15066 -0.00524604 8.4507 0.914615L9.51761 4.18549C9.65183 4.59696 10.0355 4.87539 10.4683 4.87539H13.9126C14.8823 4.87539 15.2844 6.11698 14.4989 6.68549L11.7191 8.69731C11.3673 8.95195 11.22 9.40459 11.3547 9.81751L12.4181 13.0775C12.7184 13.9982 11.6655 14.7655 10.8811 14.1977L8.08629 12.1751C7.73644 11.9219 7.26357 11.9219 6.91371 12.1751L4.11893 14.1977C3.33446 14.7655 2.28165 13.9982 2.58194 13.0775L3.64531 9.81751C3.78 9.40459 3.63275 8.95195 3.2809 8.69731L0.501087 6.68549C-0.284439 6.11698 0.117715 4.87539 1.08738 4.87539H4.53169C4.96449 4.87539 5.34817 4.59696 5.48239 4.18549L6.5493 0.914614Z"/>
                        </svg>
                        Recomendado
                      </div>
                      :
                      instalment.month === 1 &&
                      <div className="absolute top-6 left-4 flex w-fit py-1 px-2 rounded-md">
                        <svg className={ 'fill-gray-400 mr-1' } width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5493 0.914614C6.84934 -0.00524628 8.15066 -0.00524604 8.4507 0.914615L9.51761 4.18549C9.65183 4.59696 10.0355 4.87539 10.4683 4.87539H13.9126C14.8823 4.87539 15.2844 6.11698 14.4989 6.68549L11.7191 8.69731C11.3673 8.95195 11.22 9.40459 11.3547 9.81751L12.4181 13.0775C12.7184 13.9982 11.6655 14.7655 10.8811 14.1977L8.08629 12.1751C7.73644 11.9219 7.26357 11.9219 6.91371 12.1751L4.11893 14.1977C3.33446 14.7655 2.28165 13.9982 2.58194 13.0775L3.64531 9.81751C3.78 9.40459 3.63275 8.95195 3.2809 8.69731L0.501087 6.68549C-0.284439 6.11698 0.117715 4.87539 1.08738 4.87539H4.53169C4.96449 4.87539 5.34817 4.59696 5.48239 4.18549L6.5493 0.914614Z"/>
                        </svg>
                      </div>
                    }

                      <div className={`font-bold text-2xl mt-4 mb-3`}> {instalment.month} { i === 0 ? 'mes' : 'meses' }</div>

                      <div className="mb-5 text-center">
                        
                        <div className="font-noto lg:text-base text-lg">{ instalment.month > 1 ? 'Cuotas mensuales de:' : 'Un pago de:' }</div>
                        <div className='font-bold font-noto text-2xl'>${numberWithCommas(instalment.feePaymentPrice)} COP</div>

                      </div>

                    </div>

                    <div className="w-[86%] my-4">

                      <div className="w-full">

                        <div className="flex justify-between">

                          <div className="">Units</div>
                          <div className="font-bold">{amountUnit.amount >= 100 && amountUnit.amount.toFixed(2)}</div>

                        </div>
                        
                        <div className="flex justify-between">

                          <div className="">Valor de unidad</div>
                          <div className="font-bold">${numberWithCommas(instalment.unitPricePay)}</div>

                        </div>

                        <div className="flex justify-between">

                          <div className="">% de incremento</div>
                          <div className="font-bold">{ instalment.month === 1 ? 0 : instalment.month }.00%</div>

                        </div>

                      </div>

                      <div className="w-full my-4 border-b border-gray-300"></div>

                      <div className="w-full">

                        <div className="flex justify-between">

                          <div className="font-semibold">Retorno anual estimado:</div>
                          <div className="font-semibold">{instalment.returnPay}%</div>

                        </div>
                        
                        <div className="flex justify-between">

                          <div className="">* Incluye valorización y rentas</div>
                          <div className=""></div>

                        </div>

                      </div>

                    </div>

                    <div className="mb-4">

                      <div className="text-gray-400 underline mb-3">
                        Ver términos y condiciones
                      </div>

                      <h3 
                        id={`btnMes${instalment.month}`}
                        className={`
                        ${ 
                          monthSelected === instalment.month ? 
                        'bg-black text-white' :  
                        'border-[1px] border-gray-400'
                        }
                        hover:bg-black
                        hover:text-white
                        hidden
                        lg:block
                        w-60
                        rounded-full 
                        py-2
                        font-medium 
                        text-lg 
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        `}
                        onClick={() => {

                          if (instalment.feePaymentPrice >= (50 * priceUnit) && amountUnit.amount === 50) {
                            
                            localStorage.setItem('units', amountUnit.amount.toString());
                            isAuthenticated(true);
                          
                          }

                          if (amountUnit.amount >= 100) {

                            localStorage.setItem('units', amountUnit.amount.toString());
                            localStorage.setItem('amount', amountUnit.amount.toString());
                            localStorage.setItem('installments', instalment.month.toString());
                            localStorage.setItem('instalmentValue', instalment.feePaymentPrice.toString());
                            isAuthenticated(true, amountUnit.amountSend, instalment.month);
                          
                          }


                        }}
                      >
                        Invertir ahora
                      </h3>

                      <h3 
                        className={`
                        ${ 
                          monthSelected === instalment.month ? 
                        'bg-black text-white' :  
                        'border-[1px] border-gray-400'
                        }
                        hover:bg-black
                        hover:text-white
                        lg:hidden
                        w-60
                        rounded-full 
                        py-2
                        font-medium 
                        text-base 
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        `}
                        onClick={() => {
                          
                          localStorage.setItem('units', amountUnit.amount.toString());
                          localStorage.setItem('amount', amountUnit.amount.toString());
                          localStorage.setItem('installment', instalment.month.toString());
                          localStorage.setItem('instalmentValue', instalment.feePaymentPrice.toString());

                          isAuthenticated(true, amountUnit.amountSend, instalment.month)

                        }}
                      >
                        Invertir ahora
                      </h3>

                    </div>

                  </div>

                </SplideSlide>

              )

            }

          </SplideTrack>

          <div className="splide__arrows">
          
            <button 
              style={{ 
                backgroundColor: 'transparent', 
                // width: '150px', 
                left: '-20px' 
              }} 
              className="splide__arrow splide__arrow--prev"
              onClick={() => { isAuthenticated() }}
            > <img className='mr-4' src={arrowLeft} alt="" /></button>
            
            <button 
              style={{ 
                backgroundColor: 'transparent', 
                // width: '150px',
                right: '-20px' 
              }}
              className="splide__arrow splide__arrow--next"
              onClick={() => { isAuthenticated() }}
            ><img className='ml-4' src={arrowRight} alt="" /></button>
          
          </div>

        </div>
          
      </Splide>

      <div className="text-lg">
        Conviertete en inversionista {nextProfileInvestor()} a partir de <span className='text-violet-600 font-bold'> ${nextAmountProfileInvestor()} </span>
      </div>

    </section>
  
  )
}

export default Suscription;
