import React, { useEffect, useState } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import Select from 'react-tailwindcss-select'
import { fetchBanks } from '../../../../services/paymentDataServices'
import PhoneInput from 'react-phone-number-input'
import es from 'react-phone-number-input/locale/es.json';

interface PseFormProps {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  isLoading?: boolean
  setValue: any
  setCustomValue?: any
  clearErrors?: any
}

export const PseForm: React.FC<PseFormProps> = ({
  register,
  errors,
  isLoading,
   setCustomValue,
  clearErrors,
}) => {

  const [phone, setPhone] = useState('');

  const [financialInstitutions, setFinancialInstitutions] = useState<any>([]);
  const [financialInstitution, setFinancialInstitution] = useState<any>(null);

  const [userTypes] = useState<any>([
    { value: '0', label: 'Persona natural' },
    { value: '1', label: 'Persona jurídica' },
  ]);
  const [userType, setUserType] = useState<any>(null);

  const [documentTypes] = useState<any>([
    { value: 'CC', label: 'Cédula de ciudadanía' },
    { value: 'NIT', label: 'Nit' },
  ]);
  const [documentType, setDocumentType] = useState<any>(null);

  const handleFinancialInstitution = (val: any) => {
    setCustomValue('financialInstitutionCode', val.value, true);
    setFinancialInstitution(val);
    clearErrors('financialInstitutionCode');
  }

  useEffect(() => {
    fetchBanks().then((res: any) => {
      setFinancialInstitutions(res);
    })
  }, []);

  const handleUserType = (val: any) => {
    setCustomValue('userType', val.value);
    setUserType(val);
    clearErrors('userType');
  }

  const handleDocumentType = (val: any) => {
    setCustomValue('documentType', val.value);
    setDocumentType(val);
    clearErrors('documentType');
  }

  const handlePhoneChange = (value: any) =>  {
    clearErrors('phone');
    setCustomValue('phone', value);
    setPhone(value);
  }

  return (
    <div className="grid grid-cols-12 gap-y-2">
      <div className="flex flex-col col-span-12">
        <label htmlFor="financialInstitution" className="text-gray-500 mb-2">Seleccione un banco*</label>
        <Select
          classNames={{
            menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.financialInstitutionCode ? 'border-gray-300' : 'border-red-500'} py-1 px-4 outline-none cursor-pointer focus:border-violet-600`,
            ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
          }}
          primaryColor='violet'
          value={financialInstitution}
          loading={financialInstitutions.length <= 0}
          options={financialInstitutions.map((value: any) => { return { value: value.financial_institution_code, label: value.financial_institution_name } })}
          placeholder="Seleccione un Banco"
          onChange={(val: any) => {
            handleFinancialInstitution(val)
          }}
        />
        {(errors.financialInstitutionCode)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="fullName" className="text-gray-500 mb-1">Nombre completo</label>
        <input
          {...register('fullName', { required: true })}
          type="text"
          id='fullName' 
          className={`
            border-[1px]
            ${!errors.fullName ? 'border-gray-300' : 'border-red-500'}
            ${errors.fullName ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            outline-none
          `} />
        {(errors.fullName)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="address" className="text-gray-500 mb-1">Dirección de domicilio</label>
        <input
          {...register('address', { required: true })}
          type="text"
          id='address'
          className={`
            border-[1px]
            ${!errors.address ? 'border-gray-300' : 'border-red-500'}
            ${errors.address ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            outline-none
          `} />
          {(errors.address)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-6">
        <label htmlFor="personType" className="text-gray-500 mb-1">Tipo de persona*</label>
        <Select
          classNames={{
            menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.userType ? 'border-gray-300' : 'border-red-500'} py-1 px-4 outline-none cursor-pointer focus:border-violet-600`,
            ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
          }}
          primaryColor='violet'
          value={userType}
          loading={userTypes.length <= 0}
          options={userTypes}
          placeholder="Tipo de persona"
          onChange={(val: any) => {
            handleUserType(val)
          }}
        />
        {(errors.userType)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-6">
        <label htmlFor="documentType" className="text-gray-500 mb-1">Tipo de documento*</label>
        <Select
          classNames={{
            menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.documentType ? 'border-gray-300' : 'border-red-500'} py-1 px-4 outline-none cursor-pointer focus:border-violet-600`,
            ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
          }}
          primaryColor='violet'
          value={documentType}
          loading={documentTypes.length <= 0}
          options={documentTypes}
          placeholder="Tipo de documento"
          onChange={(val: any) => {
            handleDocumentType(val)
          }}
        />
        {(errors.documentType)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="documentNumber" className="text-gray-500 mb-1">Número de documento*</label>
        <input
          {...register('documentNumber', { required: true })}
          type="text"
          id='documentNumber'
          className={`
            border-[1px]
            ${!errors.documentNumber ? 'border-gray-300' : 'border-red-500'}
            ${errors.documentNumber ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            outline-none
          `} />
          {(errors.documentNumber)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="phone" className="text-gray-500 mb-1">Teléfono*</label>
        <PhoneInput
          className={`
            border-[1px]
            py-2.5
            px-4

            ${errors.phone ? 'border-red-500' : 'border-gray-300'}
          `}
          international
          countryCallingCodeEditable={false}
          defaultCountry='CO'
          labels={es}
          placeholder="Teléfono"
          value={phone}
          onChange={(value: any) => {
            handlePhoneChange(value);
          }}
        />
        {(errors.phone)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="email" className="text-gray-500 mb-1">Correo electrónico*</label>
        <input
          {...register('email', {
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Debe ser un correo válido",
            },
          })}
          type="text"
          id='email'
          className={`
            border-[1px]
            ${!errors.email ? 'border-gray-300' : 'border-red-500'}
            ${errors.email ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            outline-none
          `} />
          {(errors.email)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
          {(errors.email?.type === 'pattern') && <p className="text-rose-500 text-sm m-0">Debe ser un correo válido</p>}
      </div>

    </div>
  )
}
