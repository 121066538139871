import axios from "axios";
import { urls } from "./urls";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { fetchPriceUnits } from "../services/globalServices";
// import { on, trigger } from "./events";
// import { useState } from "react";
// import { trigger } from "./events";
import explorer from '../assets/img/nido/simulator/explorador-icon.svg';
import adventurous from '../assets/img/nido/simulator/aventurero-icon.svg';
import hero from '../assets/img/nido/simulator/hero-icon.svg';

export const authUrls = [
  "/login",
  "/register",
  "/confirmation-code",
  "/reset-password",
  "/new-password"
]

const establishedDateUpgrade = "2024-08-31";

/**  
 * Funcion que agrega el formato de miles usando comas
 * recibe un numero
*/
export const numberWithCommas = (num: number) => {

  if(isNaN(num) || num === undefined || num === null ){
    return null;
  }
  return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

}

/**
 * Funcion para consultar markertable y obtener el 
 * porcentaje de inversión actual.
 */
export const getData = () => new Promise<any>((resolve, reject) => {
  axios.get(`${urls.URL}properties/marketables`)
  .then(response => {
    
    let data = response.data.data[0];

    let min_invest = data.min_invested;
    let invested = data.invested;
    let max_shares = data.max_shares;
    let share_value = data.share_value;
    let id_project = data._id;
    
    let porcentInvested: any = (invested * 100) / min_invest;
    porcentInvested = porcentInvested.toFixed(2);

    let investmentQuota: number = max_shares * share_value;

    resolve({
      porcentage: porcentInvested,
      quota: investmentQuota,
      share_value: data.share_value,
      id_project,
    })

  })
  .catch(error => {

    reject(error);

  });
});

// Generate a random number code depending of the length passed
export const generateCode = (length: number) => {
  const randomCode = Math.trunc(Math.random() * 10 ** length).toString();
  const code = `${randomCode}`;
  return code;
};

/**
 * Funcion para vertificar si hay una sesión iniciada, sino, 
 * se pide que vuelva a ingresar nuevamente 
 */
export const isAuthenticated = (buy?: boolean, amount?: number, months?: number) => {

  const token = localStorage.getItem("token");

  const url = new URL(window.location.href);
  
  let urlCheckout = `/checkout/invest?${ amount ? `amount=${amount}&` : '' }${ months ? `months=${months}&` : '' }`;

  let urlToRedirect = '';

  if(buy === true){

    if(checkSessionStorageToken()){
      
      window.location.href = urlCheckout + `token=${token}`

    }else{

      urlToRedirect = `/register?redirect_to=${urlCheckout}`;
      
    }

  }else{

    if(authUrls.includes(url.pathname)){
      
      if(window.location.hash === '#streams'){
        
        urlToRedirect = "/register?redirect_to=/webinars"
        
      }else{
  
        urlToRedirect = "/register"
  
      }
  
    }else{
      urlToRedirect = `/register?redirect_to=${url.pathname + url.hash + url.search}`
  
    }  

  }
   
  if(token === ''){

    window.location.href = urlToRedirect;
    
  }
  
  else if(token === null){
    
    window.location.href = urlToRedirect;  
  
  }

  else if(token === undefined){
    
    window.location.href = urlToRedirect;

  }
  
  else{
    const decoded: any = jwt_decode(token || '');
    const dateNow = new Date();
    
    if(decoded.exp * 1000 < dateNow.getTime()){
      
      window.location.href = urlToRedirect + `token=${token}`;
    
    }
   
  }

}

export const redirectTo = (urlToRedirect: string) => {

  const token = localStorage.getItem("token") ?? '';
  
  if(token !== ''){

    window.location.href = urlToRedirect;
  
  }else{

    const url = new URL(window.location.href);
                      
    url.searchParams.append('redirect_to', urlToRedirect);
    
    window.history.replaceState({}, '', url);

  }


}

export const getNameEmail = () => {

  const token = localStorage.getItem("token") ?? '';
  const decoded: any = jwt_decode(token);

  return decoded;

}

export function scrollToSection(id: string, distance: number = 100) {
  const section = document.getElementById(id);
  window?.scrollTo({
    top: section?.offsetTop && section?.offsetTop - distance,
    behavior: 'smooth'
  });
}

// build a function on typescript and react to knows if exists a sessionStorage called token.
export function checkSessionStorageToken(): boolean {
  const token = localStorage.getItem("token");

  if(token !== null){

    const decoded: any = jwt_decode(token);  
    const dateNow = new Date();
    
    if(decoded.exp * 1000 < dateNow.getTime()){
      localStorage.removeItem('token')
      return false;
    }

  }else{

    return false;

  }

  return token !== null;

}

export async function setUnitPrice() {
  try {

    let unitPriceResponse = 0;

    while (unitPriceResponse < 5) {
      
      const unit = await fetchPriceUnits();
      unitPriceResponse = await unit?.unit_price[0];
      sessionStorage.setItem("unitPrice", unitPriceResponse.toString());
    
    }

    // trigger('unitPrice')
  } catch (error) {
    // console.error(error);
    throw new Error('Failed to fetch unit price');
  }
}

export function unitPrice(): number {
  
  let unit = 123000;

  // console.log('unit 0');
  
  // const getUnitPrice = sessionStorage.getItem("unitPrice");
  // console.log('getUnitPrice defined');

  // if (getUnitPrice === null) {
    
  //   console.log('sessionStorage is null or undefined or "" ');
  //   unitPrice();

  // }else{
    
  //   unit = parseInt(getUnitPrice);
  //   console.log('unit defined with sessionStorage');
  //   return unit;
    
  // }

  // console.log('final process');

  return unit;

}

export function getFinancingPercentage() {
  // Percentage of financing for the project
  // FIXME: Get value from the backend 
  const financingPercentage = 1;
  return financingPercentage;
}

export function getCreditCardFeePercentage() {
  // Percentage of financing for the project
  // FIXME: Get value from the backend 
  const creditCardFeePercentage = 2.5;
  return creditCardFeePercentage;
}


export function unitPriceMulplicated(units: number): string {
  const unit = unitPrice();
  const result = unit * units;
  const resultString = "" + numberWithCommas(result)
  return resultString;
}

export function unitPriceMulplicatedCustomUnit(units: number, price: number): string {
  const result = price * units;
  const resultString = "" + numberWithCommas(result)
  return resultString;
}

export function unitMinimunPrice(): string {
  const unit = unitPrice();
  const result = ((unit * 100) / 12) + (unit*getFinancingPercentage());
  const resultString = "" + numberWithCommas(result)
  return resultString;
}

export function getInstallmentsValue(installments: number, units: number) {
  if (installments === 1) {
    return unitPriceMulplicated(units);
  }
  const unit = unitPrice();
  const totalInvestment = unit * units;
  const installmentValueWithoutFees = totalInvestment / installments;
  const financingValue = (totalInvestment * getFinancingPercentage()) / 100;
  const installmentValueWithFees = installmentValueWithoutFees + financingValue;
  const resultString = numberWithCommas(installmentValueWithFees)
  return resultString;
}

export function getInvestorProfile(investValue: number) {
  let investorProfile = {};
  if (investValue < (unitPrice() * 200)) {
    investorProfile = {title: 'Explorador', icon: explorer};
  } else if (investValue < (unitPrice() * 500)) {
    investorProfile = {title: 'Aventurero', icon: adventurous};
  } else {
    investorProfile = {title: 'Héroe', icon: hero};
  }
  return investorProfile;
}

export function lastDayOfMonth(): string {
  moment.locale('es');
  const lastDayOfMonth = moment().endOf('month').date();
  const month = moment().endOf('month').format('MMMM');
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  const formattedDate = `Válido hasta ${lastDayOfMonth} de ${capitalizedMonth}`;
  return formattedDate;
}

export function firstDayNextMonth(): string {
  moment.locale('es');
  const firstDayOfNextMonth = moment().add(1, 'month').startOf('month').date();
  const nextMonth = moment().add(1, 'month').startOf('month').format('MMMM');
  const capitalizedNextMonth = nextMonth.charAt(0).toUpperCase() + nextMonth.slice(1);
  const formattedFirstDay = `${firstDayOfNextMonth} de ${capitalizedNextMonth}`;
  return formattedFirstDay;
}

export function lastDayOfMonthWithoutValido(): string {
  moment.locale('es');
  const lastDayOfMonth = moment().endOf('month').date();
  const month = moment().endOf('month').format('MMMM');
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  const formattedDate = `hasta el ${lastDayOfMonth} de ${capitalizedMonth}`;
  return formattedDate;
}

export function lastDayOfCurrentMonth(): string {
  moment.locale('es');
  const lastDayOfMonth = moment().endOf('month').date();
  const month = moment().endOf('month').format('MMMM');
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  const formattedDate = `${lastDayOfMonth} de ${capitalizedMonth}`;
  return formattedDate;
}

export const getCurrentAndNextMonth = (): { currentMonth: string, nextMonth: string } => {
  moment.locale('es');
  const currentMonth = moment().format('MMMM');
  const nextMonth = moment().add(1, 'month').format('MMMM');
  return {
    currentMonth: currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1),
    nextMonth: nextMonth.charAt(0).toUpperCase() + nextMonth.slice(1),
  };
};

export function currentDate(): string {
  const date = moment().format('DD/MM/YYYY');
  return date;
}

export function nextDate(): string {
  const firstDayOfNextMonth = moment().add(1, 'month').startOf('month');
  const day = firstDayOfNextMonth.format('DD');
  const month = firstDayOfNextMonth.format('MM');
  const year = firstDayOfNextMonth.format('YYYY');
  return `${day}/${month}/${year}`;
}

export function redirectToZoom() {
  const authed = checkSessionStorageToken();
  if(authed){
    window.open("https://us05web.zoom.us/j/88672474379?pwd=anVMMDYwMTBPTmJtQmZtNXlyRXZPQT09", "blank")
  }else{
    window.location.href = "/register?redirect_to=https://us05web.zoom.us/j/88672474379?pwd=anVMMDYwMTBPTmJtQmZtNXlyRXZPQT09"
  }
}

export const formatDate = (): string => {
  const formattedDate = moment(establishedDateUpgrade);
  const lastDayOfMonth = formattedDate.date();
  const month = formattedDate.format('MMMM');
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  const result = `${lastDayOfMonth} de ${capitalizedMonth}`;
  return result;
};

export const formatPrevDate = (): string => {
  const formattedDate = moment(establishedDateUpgrade).subtract(1, "day");
  const lastDayOfMonth = formattedDate.date();
  const month = formattedDate.format('MMMM');
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  const result = `${lastDayOfMonth} de ${capitalizedMonth}`;
  return result;
};

export const formatNextDate = (): string => {
  const formattedDate = moment(establishedDateUpgrade).add(1, "day");
  const lastDayOfMonth = formattedDate.date();
  const month = formattedDate.format('MMMM');
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  const result = `${lastDayOfMonth} de ${capitalizedMonth}`;
  return result;
};

export const formatDateSlash = (): string => {
  const formattedDate = moment(establishedDateUpgrade);;
  const day = formattedDate.format('DD');
  const month = formattedDate.format('MM');
  const year = formattedDate.format('YYYY');
  const result = `${day}/${month}/${year}`;;
  return result;
};

export const getCalculateValuesOfInvestment = (amount: number, months: number) => {

  const totalInvestment = amount * unitPrice();
  let financingCost = 0;


  if(months === 1) {

  } else if (months > 1) {
    financingCost = (getFinancingPercentage() * 100) / totalInvestment;
  }

  return {
    totalInvestment,
    installments: months,
    financingCost,
    subtotal: totalInvestment / months,
  }
}

export const getPhoneCountryAndPhoneNumber = (phone: string) => {
  let phoneNumberFull = phone.replace('+', '');
  let parts = phoneNumberFull.split(' ');
  const phoneCode = parts[0];
  const phoneNumber = parts.slice(1).join('');
  return {phoneCode, phoneNumber};
}