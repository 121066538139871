import axios from "axios";
import { urls } from "../helpers/urls";

// variable que devuelve el token
const getToken = () => localStorage.getItem("token");

export const getOwnersInvestment = async () => {

  try {
   
    let response = await axios.get(`${urls.NEW_API_URL}investment/userInvestments`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });
  
    return response.data
    
  } catch (error) {
    
    throw error;
    
  }
  
};

export const getOwnersSubcription = async (projectId: string) => {

  try {
   
    let response = await axios.get(`${urls.NEW_API_URL}investment/activeSubscription/${projectId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });
  
    return response;
    
  } catch (error) {
    
    throw error;
    
  }
  
};

export const getInfoPMS = async () => {

  try {
   
    let response = await axios.get(`https://apidash.lokl.life/get_info_pms`);
  
    return response;
    
  } catch (error) {
    
    throw error;
    
  }
  
};

export const getValorizationByEmail = async (email: string) => {

  try {
   
    let response = await axios.get(`https://apidash.lokl.life/get_valorization_by_email?email=${email}`);
  
    return response;
    
  } catch (error) {
    
    throw error;
    
  }
  
};