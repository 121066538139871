import React from 'react'
import Suscription from './Suscription';

function Instalments() {
  return (
    <div id="installments" className='lg:flex lg:flex-col lg:justify-center lg:items-center py-24'>
      
      <div className="mx-10 lg:max-w-6xl">
        
        <Suscription/>

      </div>

    </div>
  )
}

export default Instalments;