import React from 'react'
import ownerSampleOne from '../../../assets/img/auth/owner-sample-1.png';
import ownerSampleTwo from '../../../assets/img/auth/owner-sample-2.png';
import ownerSampleThree from '../../../assets/img/auth/owner-sample-3.png';
import './hero.css'
import { unitMinimunPrice } from '../../../helpers/functions';

export const Hero = () => {
  return (
    <div className="background-register text-white h-full w-full flex flex-col justify-center items-center">
      <div>

        <div className="flex items-end gap-16">
          <h1 className="text-2xl font-bold">¿Por qué invertir en LOKL?</h1>
          <div>
            <p className="text-[16px]">
              Ya somos <span className="font-bold text-4xl">+ 1000</span>
            </p>
            <p className="text-[16px]">inversionistas creciendo juntos</p>
          </div>
        </div>

        <div className="flex gap-5 my-5">
          <img src={ownerSampleOne} alt="usuarios" />
          <img src={ownerSampleTwo} alt="usuarios" />
          <img src={ownerSampleThree} alt="usuarios" />
        </div>

        <div>
          <h2 className="text-4xl font-bold mb-2">Invierte de manera</h2>
          <h2 className="text-4xl font-bold mb-2">
            <span className="text-[#B1FFE0]">Simple, </span><span className="text-[#9AFFFF]">transparente, </span><span className="text-[#FFFFFF]">y </span><span className="text-[#FFC1FF]">flexible</span>
          </h2>
          <p>Conoce nuestro modelo de suscripción desde ${unitMinimunPrice()} COP </p>
        </div>

      </div>
    </div>
  )
}
