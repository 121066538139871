import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form"

interface CombinedInputProps {
  firstInputId: string
  firstInputLabel: string
  firstInputType?: string
  firstInputDisabled?: boolean
  firstInputRequired?: boolean
  secondInputId: string
  secondInputLabel: string
  secondInputType?: string
  secondInputDisabled?: boolean
  secondInputRequired?: boolean
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  touchedFields: any
}
const CombinedInput: React.FC<CombinedInputProps> = ({
  firstInputId,
  firstInputLabel,
  firstInputType = 'text',
  firstInputDisabled,
  firstInputRequired,

  secondInputId,
  secondInputLabel,
  secondInputType = 'text',
  secondInputDisabled,
  secondInputRequired,

  register,
  errors,
  touchedFields
}) => {
  return (
    <>
      <div className={`
        w-full
        flex
        border-[1px]
        justify-center
        items-center
        rounded-lg
        ${!errors[firstInputId] && !errors[secondInputId] ? 'focus-within:border-[#4743E0]' : 'focus-within:border-rose-500'}
        ${!errors[firstInputId] && !errors[secondInputId] && touchedFields[firstInputId] && touchedFields[secondInputId] ? 'border-green-500' : errors[firstInputId] || errors[secondInputId] ? 'border-rose-500' : 'border-gray-300'}
        `}
      >
        <input
          id={firstInputId}
          disabled={firstInputDisabled}
          {...register(firstInputId, { required: firstInputRequired })}
          placeholder={firstInputLabel}
          type={firstInputType}
          className={`
            peer
            w-full
            px-4
            py-2
            border-none
            rounded-md
            outline-none
            transition
            disabled:opacity-70
            disabled:cursor-not-allowed
          `}
        />
        <p className="text-gray-300 font-bold">
          |
        </p>
        <input
          id={secondInputId}
          disabled={secondInputDisabled}
          {...register(secondInputId, { required: secondInputRequired })}
          placeholder={secondInputLabel}
          type={secondInputType}
          className={`
            peer
            w-full
            px-4
            py-2
            border-none
            rounded-md
            outline-none
            transition
            disabled:opacity-70
            disabled:cursor-not-allowed
          `}
        />
      </div>
      {(errors[firstInputId] || errors[secondInputId]) && <p className="text-rose-500 text-sm m-0" id={`warning-required-${firstInputId}-${secondInputId}`}>{`* El ${firstInputLabel} y el ${secondInputLabel} son requeridos`}</p>}
    </>
  )
}

export default CombinedInput