import { useState } from "react"
import { FieldErrors, FieldValues, UseFormRegister, ValidationRule } from "react-hook-form"

interface PasswordInputProps {
  id: string
  label: string
  disabled?: boolean
  required?: boolean
  pattern?: ValidationRule<RegExp>
  register: UseFormRegister<FieldValues>
  errors: FieldErrors,
  requireErrorMessage?: string
  patternErrorMessage?: string
  touchedFields: any
}
const PasswordInput: React.FC<PasswordInputProps> = ({
  id,
  label,
  disabled,
  required,
  pattern,
  requireErrorMessage = "Este campo es requerido",
  patternErrorMessage = "Este campo debe ser válido",
  touchedFields,
  register,
  errors
}) => {

  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="w-full relative">
      <input
        id={id}
        disabled={disabled}
        {...register(id, { required, pattern })}
        placeholder={label}
        type={showPassword ? "text" : "password"}
        className={`
          peer
          w-full
          px-4
          py-2
          bg-white
          border-[1px]
          rounded-md
          outline-none
          transition
          disabled:opacity-70
          disabled:cursor-not-allowed
          ${!errors[id] && touchedFields[id] ? 'border-green-500' : errors[id] ? 'border-rose-500' : 'border-neutral-300'}
          ${errors[id] ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
        `}
      />
      <p
        className={`text-gray-400 absolute top-2 right-3 ${showPassword ? 'line-through' : ''} cursor-pointer`}
        onClick={() => setShowPassword(!showPassword)}
      >
        {!showPassword? 'Show' : 'Hide'}
      </p>
      {(errors[id])?.type === 'required' && <p className="text-rose-500 text-sm m-0" id={`warning-required-${id}`}>{requireErrorMessage}</p>}
      {(errors[id]?.type === 'pattern') && <p className="text-rose-500 text-sm m-0" id={`warning-pattern-${id}`}>{patternErrorMessage}</p>}
    </div>
  )
}

export default PasswordInput