import React from 'react'
import image1 from "../../assets/img/blog/invertir-en-bienes-raices/1-Inversion-inmobiliaria-accesible.jpg"
import image2 from "../../assets/img/blog/invertir-en-bienes-raices/2-propiedades-de-bajo-costo.jpeg"
import image3 from "../../assets/img/blog/invertir-en-bienes-raices/3-inversion-simplificada-para-principiantes.jpg"
import image4 from "../../assets/img/blog/invertir-en-bienes-raices/4-generar-ingresos-y-unirte-a-una-comunidad.jpg"

import img2 from "../../assets/img/blog/card-2.png";

function ArticleInvertirEnBienesRaices() {

  return (
    <section id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <div className="bg-note-img2 bg-cover bg-top h-[400px] md:h-[400px] xl:h-[430px] w-full flex items-center justify-center">

        <div className="wrapper-lokl px-10 md:px-0 text-white ">

          <div className="md:w-5/12 w-full">

            {/* <h1 className="font-lato font-extrabold md:text-[38px] text-[26px] mb-3">
              Inversión rentable, con poco dinero, en bienes raíces
            </h1> */}
          
          </div>

        </div>

      </div>

      <div className="wrapper-lokl px-10 md:px-0 space-y-20 py-16">

        <div className="w-full flex justify-center">

          <div className="w-full">

            <div className="blog-title-md mb-4 text-center">
              Conoce la guía que te ayudará a dar el paso e invertir con LOKL
            </div>

            <p className="text-base font-roboto w-full md:w-4/5 mx-auto text-center">
              En el mundo de las inversiones inmobiliarias, a menudo se piensa que se necesita una gran suma de dinero para comenzar.
              <br />
              Sin embargo, con <strong>LOKL</strong>, invertir en bienes raíces es más accesible de lo que parece. Siéntete seguro al dar tus primeros pasos considerando estos <strong>puntos clave:</strong>
            </p>

          </div>

          {/* <div className="w-1/2 h-auto justify-end items-start hidden md:flex">

            <div className="flex space-x-2 mr-0 md:mr-3">

              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>

            </div>

            <div className="underline cursor-pointer">5</div>

          </div> */}

        </div>

        <div className="w-full gap-5 flex justify-between">

          <div className='w-1/2 hidden md:flex'>
            <img className='' width={525} height={357} src={image1} alt="casa-lokl" />
          </div>

          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>1. Inversión inmobiliaria accesible</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
              El primer gasto que debes contemplar es la cantidad inicial. Con LOKL, puedes unirte a propiedades de bajo costo invirtiendo desde montos accesibles. Esto hace que la inversión sea más sencilla para principiantes, eliminando las barreras financieras que podrían desmotivar a muchos. Nuestra plataforma te permite diversificar tus inversiones sin comprometer grandes sumas.
              </p>

            </div>

          </div>

        </div>

        <div className="flex gap-5 justify-between">

          <div className="w-full md:w-1/2">
            <h2 className='blog-title-md mb-12'>2. Propiedades de bajo costo con gran potencial</h2>

            <div className="">

              <p className="text-base font-roboto mb-12">
                No todas las inversiones inmobiliarias implican lujo y grandes sumas. Existen propiedades de bajo costo en mercados en crecimiento que representan oportunidades de inversión. Puedes diversificar tu portafolio en LOKL sin exceder tu presupuesto, aprovechando la experiencia colectiva de nuestra comunidad para identificar propiedades con alto potencial.
              </p>

            </div>

          </div>

          <div className='w-1/2 hidden md:flex'>
            <img className='' width={525} height={357} src={image2} alt="casa-lokl" />
          </div>


        </div>

        <div className="flex gap-5 justify-between">

          <div className='w-1/2 hidden md:flex'>
            <img className='' width={525} height={357} src={image3} alt="casa-lokl" />
          </div>

          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>3. Inversión inmobiliaria simplificada para principiantes<br/><br/></h2>

            <div className="">

              <p className="text-base font-roboto mb-12">
                Uno de los gastos que puede asustar a los principiantes es la burocracia y gestión de la inversión. En LOKL, nos encargamos de todo para que tú puedas enfocarte en invertir. Desde la selección de propiedades hasta el cobro de beneficios, simplificamos los procesos legales y administrativos para ofrecerte una experiencia fluida y rentable.
              </p>

            </div>

          </div>

        </div>

        <div className="w-full flex gap-5">

          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>4. Generar ingresos pasivos y unirte a una comunidad <br/><br/></h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
                Al invertir con cantidades accesibles, puedes disfrutar de los mismos beneficios que cualquier inversor experimentado: generar ingresos pasivos, diversificar tu portafolio y formar parte de una comunidad vibrante que comparte su experiencia y conocimientos. Los gastos iniciales no son una barrera para empezar a construir tu futuro.
              </p>

            </div>

          </div>

          <div className='w-1/2 hidden md:flex'>
            <img className='' width={525} height={357} src={image4} alt="casa-lokl" />
          </div>



        </div>

        <div className="w-3/4 text-center mx-auto">
          {/* <h2 className='blog-title-md mb-12'>Conclusión</h2> */}
          <div className="flex flex-col justify-between">

            <p className="font-roboto text-lg">
              En <strong>LOKL</strong>, estamos para guiarte en cada paso, entendiendo tus preocupaciones financieras y ayudándote a alcanzar tus metas con una estrategia de inversión personalizada.
              <br />
              <strong>¿Listo para dejar tu huella en el mundo de las inversiones inmobiliarias?</strong>
              <br />
              ¡Descubre cómo <strong>LOKL</strong> puede ser tu aliado y empieza a construir el futuro que anhelas!
            </p>

          </div>
        </div>

        {/* <div className="w-3/4 text-center mx-auto">
          <p className="text-base font-roboto">
            <strong>¿Listo para dar el siguiente paso en tu aventura inmobiliaria? </strong>¡Regístrate en <a href="https://www.lokl.life/register?utm_source=Blog&utm_medium=TipsFinanzasInversiones">este link</a> y contacta con nuestro equipo! Estaremos encantados de ofrecerte una atención más personalizada y guiarte en este emocionante camino. ¡Es tu momento de invertir en el futuro!
          </p>
        </div> */}


        <div className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

          <div className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-0 md:mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                5 secretos para usar el <br /> dinero adecuadamente, <br /> planifica e invierte a largo <br /> plazo.
              </div>

            </div>

            <div 
              className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
              onClick={() => {
                window.location.href = "/blog/planificar-presupuesto-largo-plazo"
              }}
            >
              Ver nota
            </div>

          </div>


          <div className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-0 md:mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones
    
              </div>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante 
              </p>

              <div 
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" /> 

          </div>

        </div>
        
      </div>

    </section>
  )
}

export default ArticleInvertirEnBienesRaices;