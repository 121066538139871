import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { urls } from "../../../helpers/urls";
import { CreditCardItem } from './CreditCardItem';

interface PaymentSourcesProps {
  showForm: () => void;
}

export const PaymentSources: React.FC<PaymentSourcesProps> = ({showForm}) => {

  const [paymentSources, setPaymentSources] = useState<any[]>([])

  useEffect(() => {
    getPaymentSources()
  }, [])

  const getPaymentSources = async() => {
    try {
      const { data } = await axios.get(`${urls?.NEW_API_URL}user/paymentSources`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") ?? ''}`,
            'Content-Type': 'application/json',
          },
        }
      )
      setPaymentSources(data.data)
    } catch (error) {
    }
  }


  return (
    <div className="bg-white rounded-md w-full h-fit">
      <Accordion allowZeroExpanded>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                  <div className="flex pt-4 items-center gap-3 mb-3  px-10">
                    <div>
                      <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="45" height="43" rx="5" fill="#FFA9B8"/>
                        <path d="M13 21C13 17.2288 13 15.3431 14.1716 14.1716C15.3432 13 17.2288 13 21 13H25C28.7712 13 30.6569 13 31.8284 14.1716C33 15.3431 33 17.2288 33 21C33 24.7712 33 26.6569 31.8284 27.8284C30.6569 29 28.7712 29 25 29H21C17.2288 29 15.3432 29 14.1716 27.8284C13 26.6569 13 24.7712 13 21Z" stroke="black" strokeWidth="1.5"/>
                        <path d="M21 25H17" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M25 25H23.5" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M13 19H33" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                      </svg>
                    </div>
                    <div>
                      <h2 className="font-bold text-lg">Métodos de pago</h2>
                      <p>Débito automático de cuotas</p>
                    </div>
                  </div>
                  <hr />
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>

                <div className="px-10 pb-4">

                  {
                    paymentSources.map((paymentSource) => (
                      <CreditCardItem
                        key={paymentSource._id}
                        lastFourNumbers={paymentSource.lastFourNumbers}
                        brand={paymentSource.brand}
                        expMonth={paymentSource.expMonth}
                        expYear={paymentSource.expYear}
                      />
                    ) 
                    )
                  }

                  <div className="flex items-center cursor-pointer hover:bg-slate-200 py-2 rounded-lg justify-center mt-3" onClick={showForm}>
                    <div>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 19C6.19108 19 4.78661 19 3.77772 18.3259C3.34096 18.034 2.96596 17.659 2.67412 17.2223C2 16.2134 2 14.8089 2 12C2 9.19108 2 7.78661 2.67412 6.77772C2.96596 6.34096 3.34096 5.96596 3.77772 5.67412C4.78661 5 6.19108 5 9 5H15C17.8089 5 19.2134 5 20.2223 5.67412C20.659 5.96596 21.034 6.34096 21.3259 6.77772C22 7.78661 22 9.19108 22 12C22 14.8089 22 16.2134 21.3259 17.2223C21.034 17.659 20.659 18.034 20.2223 18.3259C19.2134 19 17.8089 19 15 19H9Z" stroke="#1C274C" strokeWidth="1.5"/>
                        <path d="M12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9Z" stroke="#1C274C" strokeWidth="1.5"/>
                        <path d="M5.5 15V9" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M18.5 15V9" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                      </svg>
                    </div>
                    <p className="ml-2">Configurar una cuenta bancaria</p>
                  </div>

                </div>


              </AccordionItemPanel>
          </AccordionItem>
      </Accordion>
    </div>
  )
}