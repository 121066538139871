import React, { useState } from 'react';
import './footer.scss';

import logo from '../../assets/img/footer/lokl-white.svg';

import icon_fb from '../../assets/img/footer/facebook.svg';
import icon_yt from '../../assets/img/footer/youtube.svg';
import icon_in from '../../assets/img/footer/linkedin-white.svg';
import icon_twitter from '../../assets/img/footer/twitter.svg';
import icon_ig from '../../assets/img/footer/instagram.svg';
import icon_ba from '../../assets/img/footer/bancolombia-white-icon.svg';
import icon_di from '../../assets/img/footer/DinersClub.svg';
import icon_mas from '../../assets/img/footer/Mastercard.svg';
import icon_pse from '../../assets/img/footer/pse.svg';
import icon_visa from '../../assets/img/footer/Visa.svg';
import { urls } from '../../helpers/urls';
import axios from 'axios';

function Footer() {

  /**
   * Estados
  */

   const [email, setEmail] = useState('');
   const [alert, setAlert] = useState('');

   const hiddenUrl = [
    "/login",
    "/register",
    "/confirmation-code",
    "/reset-password",
    "/new-password",
    "/dashboard"
   ]

   const footerActive = hiddenUrl.includes(window.location.pathname);

   /**
    * Funcion para suscribirse al newsletter
    * 
    * recibe: email
   */
   const suscribeNewsletter = () => {
     axios.post(`${urls.URL}newsletter`, {email})
     .then(response => {
       console.log('newsletter suscribed', response);
       setAlert(response.data.message);
      })
      .catch(error => {
        console.log('error newsletter', error);
        setAlert(error.message);
     })
     .finally(() => setTimeout(() => setAlert(''), 4000))
   }

  return (
    <>

      {/* <div className="footer-suscribe">

        <div className="footer-title">
          ¡SUSCRÍBETE!
        </div>

        <div className="footer-input">

          <div className="input">
            <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Dejanos tu correo y te contamos todo"/>
            <button onClick={() => suscribeNewsletter()} >enviar</button>
            { alert !== '' ? alert : null }
          </div>

          <span style={{ fontSize: '14px' }}>contacto@lokl.life / <strong>+57 314 6359224</strong></span>

        </div>

      </div> */}

      <footer className={`${ footerActive ? 'hidden' : 'flex' } footer lg:px-[8%] 2xl:px-[10%]`}>
        
        <div className="footer-items">

          <div className="footer-info footer-section">

            <div className="footer-logo">
              <img src={logo} alt="logo footer" />
            </div>

            <p className='footer-slogan'>
              Sé parte de una comunidad, crece tu patrimonio y construye tu futuro
            </p>

            <div className="icons">
              <a target="_blank"  rel="noreferrer" href="https://www.instagram.com/lokl.life/">
                <img src={icon_ig} alt="" />
              </a>
              <a target="_blank"  rel="noreferrer" href="https://www.facebook.com/lokl.life">
                <img src={icon_fb} alt="" />  
              </a>
              <a target="_blank"  rel="noreferrer" href="https://twitter.com/LoklLife">
                <img src={icon_twitter} alt="" />
              </a>
              <a target="_blank"  rel="noreferrer" href='https://www.youtube.com/channel/UCeoyCXhjNYWcqssgeW46Bsg'>
                <img src={icon_yt} alt="" />
              </a>
              <a target="_blank"  rel="noreferrer" href='https://www.linkedin.com/company/loklcollective/'>
                <img src={icon_in} alt="" />
              </a>
            </div>

          </div>

          <div className="footer-sections footer-section">

            <div className="footer-title">
              Sobre LOKL
            </div>

            {/* <div className='footer-item'><a href="/nido">Nido de Agua</a></div> */}
            {/* <div className='footer-item'><a href="/#projects">Nuestros Proyectos</a></div> */}
            {/* <div className='footer-item'><a href="/#simulator">Simula tu inversión</a></div> */}
            {/* <div className='footer-item'><a href="/nido#suscription">Modelo de suscripción</a></div> */}
            <div className='footer-item'><a href="/blog">Academia LOKL</a></div>
            <div className='footer-item'><a href="/#pqrs">Preguntas frecuentes</a></div>
            <div className='footer-item'><a href={"https://drive.google.com/file/d/1R6aOvsRjYVo-d398PskWJjwL4_WrY9PP/view?usp=drive_link"} target={'_blank'} rel="noreferrer">Términos y condiciones</a></div>

          </div>

          <div className="footer-us footer-section">

            <div className="footer-title">
              Nosotros
            </div>

            {/* <div className='footer-item'><a href="https://lokl.life/policy">Política de privacidad</a></div>
            <div className='footer-item'><a href="https://lokl.life/policy">Términos y condiciones</a></div> */}
            <div className='footer-item'><a href="https://api.whatsapp.com/send/?phone=573207379535">Habla con nosotros</a></div>
            <div className='footer-item'><a href="https://www.linkedin.com/company/lokllife/jobs/" target='_blank' rel='noreferrer'>Trabaja con nosotros</a></div>

          </div>

          <div className="footer-newsletters footer-section">

            <div className="footer-title">
              Newsletter
            </div>

            <div className="footer-input">

              <div className="input">
                <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Dejanos tu correo y te contamos todo"/>
                <button onClick={() => suscribeNewsletter()} >Enviar</button>
                { alert !== '' ? alert : null }
              </div>
          
              <div className='contact-info'>
                <div className='footer-contact desktop'>¡Contáctanos!</div>
                  <div className='footer-email'>felipe@lokl.life </div>
                  <div className='footer-phone'>+57 320 7379535</div>
              </div>
          
            </div>
          
          </div>
        
        </div>

        <div className="footer-divider"></div>
        <div className="footer-copyright">

          <div className="footer-text">
            ©TodoslosderechosreservadosLOKL.LIFE
          </div>

          <div className="footer-banca flex">

            <img style={{ marginRight: '15px' }} src={icon_visa} alt="" />
            <img style={{ marginRight: '15px' }} src={icon_mas} alt="" />
            <img style={{ marginRight: '15px' }} src={icon_di} alt="" />
            <img style={{ marginRight: '15px' }} src={icon_ba} alt="" />
            <img style={{ marginRight: '15px' }} src={icon_pse} alt="" />

          </div>
        </div>

      </footer>

    </>
  );
}

export default Footer;
