import { useState } from "react";
import imgPrincipal from "../assets/img/nido/nido-principal.png";
import imgRectangulo from "../assets/img/nido/nido-rectan.png";
import imgMini from "../assets/img/nido/nido-mini.png";

// import imgPrincipalMb from "../../assets/img/nido/nido-principal-mb.png";
// import imgRectanguloMb from "../../assets/img/nido/nido-rectan-mb.png";
// import imgMiniMb from "../../assets/img/nido/nido-mini-mb.png";

import playIcon from "../assets/img/nido/arrow-next.svg";
import { trigger } from "../helpers/events";

function GridImagesNido() {

  const [videoActived, setVideoActived] = useState<boolean>(false);

  const activeGallery = () => {
    trigger('closeGalleryNido')
  };

  return (
    <div className="hidden lg:block">

    <div className="mb-3 relative">

      {
        videoActived === true ?
        <iframe
          width={672}
          height={370}
          id="header-video"
          src="https://www.youtube.com/embed/VogBkJMkJhY?autoplay=1;start=2"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        :
        <>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-between text-white p-8 font-extrabold"
            onClick={() => setVideoActived(true)}
          >

            <div className="text-xl">
              Creemos que la vida es una sola, <br /> para solo vivirla los fines de semana.
            </div>
            
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer">

              <img className='opacity-60' width={50} height={50} src={playIcon} alt="play-video-youtube" />

            </div>

            <div className="flex items-center space-x-10">

            </div>

          </div>

          <img className='w-full min-h-[400px] z-0' src={imgPrincipal} alt="nido-de-agua-video" />
        </>
      }

    </div>

    <div className="flex justify-between w-full" onClick={activeGallery}>

      <img className="w-[65%]" src={imgRectangulo} alt="nido-de-agua-vista-previa" />

      <div className="relative cursor-pointer">

        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white p-8">

          <div className="text-4xl font-extrabold">+8</div>
          <div className="font-light underline">Ver más fotos</div>

        </div>

        <img className='z-0' src={imgMini} alt="nido-de-agua-galeria" />
      
      </div>

    </div>

  </div>
  )
}

export default GridImagesNido;