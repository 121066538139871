import React from 'react'
import Select from 'react-tailwindcss-select'
import { numberWithCommas } from '../../../../helpers/functions';

interface InvestFormProps {
  couponCode: string,
  setCouponCode: (couponCode: string) => void;
  unitAmount: string;
  setUnitAmount: (amount: string) => void;
  installmentsOptions: any;
  installmentSelected: any
  setInstallment: (value: any) => void
  totalInvestment: number;
  validateCoupon: () => void;
  disableCouponInput: boolean;
}

export const InvestForm: React.FC<InvestFormProps> = ({
  couponCode,
  setCouponCode,
  unitAmount,
  setUnitAmount,
  validateCoupon,
  installmentsOptions,
  installmentSelected,
  setInstallment,
  totalInvestment,
  disableCouponInput
}) => {

  const onHandleChangeInstallments = (value: any) => {
    setInstallment(value);
  };

  const onHandleChange = (e: any) => {
    const value = e.target.value;
    // Regular expression that matches only numbers
    const onlyNumbers = /^[0-9]*$/;
    if (!onlyNumbers.test(value)) {
      // If the input value is not a number, return immediately
      return;
    }
    setUnitAmount(value);
  }

  const onHandleCouponChange = (e: any) => {
    const value = e.target.value
    setCouponCode(value)
  }
  
  const onHandleValidateCoupon = () => {
    validateCoupon()
  }

  return (
    <div>

      <div className="my-3">
        <p>¿Cuánto quieres invertir?</p>
      </div>

      <div className="grid grid-cols-12 border focus:via-violet-700 px-4 py-1 my-3">
        <input
          type="text"
          placeholder="Units"
          className="outline-none text-lg border-r-gray-900 col-span-8 font-roboto font-semibold"
          value={unitAmount}
          onChange={onHandleChange}
        />
        <div className="flex flex-col items-center col-span-4">
          <p className="text-gray-400 font-bold font-roboto">${numberWithCommas(totalInvestment)}</p>
          <p className="text-xs text-gray-400">Precio</p>
        </div>
      </div>
      <div className='flex'>
        <input
        disabled={disableCouponInput}
          type="text"
          placeholder="Cupón"
          className="
            outline-none
            text-lg
            border-r-gray-900
            font-roboto
            font-normal
            border
            focus:via-violet-700
            px-4
            py-1
            w-full
            disabled:opacity-60
          "
          value={couponCode}
          onChange={onHandleCouponChange}
        />
        <button
          disabled={!couponCode}
          onClick={() => onHandleValidateCoupon()}
          className='
            w-1/2
            md:w-1/3
            font-roboto
            bg-green-400
            font-normal
            py-2
            px-4
            disabled:opacity-60
            disabled:cursor-not-allowed
          '
        >
          Validar cupón
        </button>
      </div>
      <div className="grid grid-cols-12 gap-4 my-3">
        <div className="col-span-6">
          <Select
            classNames={{
              menuButton: ({ isDisabled }) => `flex justify-between border-[1px] rounded-sm bg-white border-gray-300 py-1 px-4 rounded-lg outline-none cursor-pointer focus:border-violet-600 ${installmentSelected.value > 1 ? "border-[#3533FF]" : ""}`,
              list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52 rounded-none'
            }}
            primaryColor='violet'
            value={installmentSelected.value === '1' ? null : installmentSelected}
            onChange={onHandleChangeInstallments}
            options={installmentsOptions}
            placeholder="Cuotas mensuales"
          />
        </div>
        <div className="col-span-6">
          <div
            onClick={() => onHandleChangeInstallments({ value: '1', label: 'Pago único' })}
            className={`border-1 h-full flex justify-center items-center cursor-pointer ${installmentSelected.value === '1' ? 'text-[#3533FF] border-[#3533FF]' : ''}`}
          >
            <p>Pago único</p>
          </div>
        </div>
      </div>
    </div>
  )
}
