import axios from "axios";
import { urls } from "../helpers/urls";

// variable que devuelve el token
const getToken = () => localStorage.getItem("token");

export const getDividendPayout = async(projectId: string) => {
  try {
    const response = await axios.get(`${urls.NEW_API_URL}dividendPayout/${projectId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}
