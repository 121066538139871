import React, { useEffect, useState } from 'react';
import nido from "../../assets/img/webinars/nido-image.png";
import play from "../../assets/img/webinars/play.svg";
import YouTube from 'react-youtube';
// import { fetchWebinarURL } from '../../services/globalServices';
import moment from 'moment';
import { fetchWebinarURL } from '../../services/globalServices';
import { checkSessionStorageToken, isAuthenticated } from '../../helpers/functions';

function Webinars() {

  const [ webinarActive, setWebinarActive ] = useState(false); 
  const [ webinarURL, setWebinarURL ] = useState('H4feBHjl3ws');

  /**
   * 0 para espera
   * 1 para sí está logeado
   * 2 para no está logeado
   */
  const [ logged, setLogged ] = useState<0|1|2>(0);

  const today = moment();
  const formattedDate = today.format('DD [de] MMMM [de] YYYY');

  const WebinarNoActived = () => (
    <>

      { logged === 2 && <div className="font-black text-2xl font-lato">Debes registrarte para ver el live</div> }

      <div className="relative cursor-pointer">

        <img className='w-full' src={nido} alt="nido-de-agua-lokl" />

        <img 
          onClick={() => {
            isAuthenticated();
            setWebinarActive(true);
          }} 
          className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' src={play} alt="icon-play-lokl" 
        />

      </div>
    </>
  );

  const WebinarActived = () => (
    <div>

      <YouTube
        videoId={webinarURL}
        opts={{ width: '875', height: '530' }}
        // onReady={(event) => {
        //   // do something when the player is ready
        // }}
        // onPlay={(event) => {
        //   // do something when the video starts playing
        // }}
        // onPause={(event) => {
        //   // do something when the video is paused
        // }}
        // onEnd={(event) => {
        //   // do something when the video ends
        // }}
        // onError={(event) => {
        //   // do something when an error occurs
        // }}
      /> 
    </div>
  )

  const getAndSetURL = () => {
    fetchWebinarURL().then(res => {
      setWebinarURL(res?.name);
    })
  }

  useEffect(() => {
    
   getAndSetURL();

    if(checkSessionStorageToken()){
      setLogged(1)
    }else{
      setLogged(2)
    }

  }, [])

  return (
    <div className="pt-44 pb-20 bg-[#F5F5F5]">

      <div className="container mx-auto lg:max-w-5xl">

        <div className="flex">

          <div className="w-full">

            <div className="flex justify-between items-center mb-6">

              <div className="font-black text-2xl font-lato">¿Cómo calcular el retorno de tu inversión en bienes raíces?</div>
              <div className="">{formattedDate}</div>

            </div>

            { webinarActive === true ? WebinarActived() : WebinarNoActived() }

          </div>

          {/* <div className="w-[35%] rounded-2xl bg-white p-5 mt-14">

            <div className="font-bold text-xl">Preguntas</div>

            <div className="w-full h-[1px] bg-gray-400 mt-4"></div>

            <div className="w-full flex justify-center">
              
              <div className="w-11/12 flex flex-col justify-center items-center space-y-7">

                <div className="mb-2">

                  <div className="w-full mt-4 mb-2">Elizabeth</div>

                  <div className="text-sm font-light text-gray-400">
                    Quisiera tener más detalle del retorno que obtendré en Nido de agua si invierto 10M.
                  </div>

                </div>

                <div className="text-sm font-semibold text-center">
                  Nos comunicaremos lo más pronto posible para darte respuesta a tu solicitud
                </div>

                <button className='px-10 py-2 bg-[#ECECFD] text-[#3835FF] rounded-lg font-semibold' id='#investNidoWebinar'>Quiero invertir en Nido</button>

                <button className='w-full px-6 py-2.5 bg-black text-white rounded-full' id='#sendToTeam'>Envíar equipo LOKL</button>

              </div>

            </div>


          </div> */}

        </div>

      </div>
      
    </div>
  )
}

export default Webinars;
