import React from 'react'
import { ArticleHeader, ArticleCard, ArticleFooter, ArticlesCarousel, ArticleCover, ArticleTextCard } from './components';
import { ArticleCardModel } from './models/index'

interface ArticleBodyProps {
    title: string,
    titleHeader: string,
    descriptionHeader: string,
    articleCards: ArticleCardModel[]
}


export const ArticleBody: React.FC<ArticleBodyProps> = ({ title, titleHeader, descriptionHeader, articleCards }) => {
    return (
        <section id='article' className='pt-32 bg-[#F9F9F9]'>

            <ArticleCover title={title} />

            <div className="wrapper-lokl px-10 md:px-0 space-y-20 py-16">

                <ArticleHeader title={titleHeader}>
                    {descriptionHeader}
                </ArticleHeader>


                {   
                    articleCards.map((articleCard, index) => {
                        
                        if (articleCard.urlImagen) {
                            return (
                                <ArticleCard
                                    key={articleCard.title}
                                    title={articleCard.title}
                                    description={articleCard.description}
                                    urlImagen={articleCard.urlImagen}
                                    reverse={index % 2 === 1}
                                />
                            )
                        }
                        return (
                            <ArticleTextCard
                                key={articleCard.title}
                                title={articleCard.title}
                                title2={articleCard.title2}
                                description={articleCard.description}
                                description2={articleCard.description2}
                            />
                        )
                    })
                }

                <ArticleFooter />

                <ArticlesCarousel />

            </div>

        </section>
    )
}
