import reportIcon from '../../../assets/img/report-icon.svg';
import indieIcon from '../../../assets/img/indie-icon.svg';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { numberWithCommas } from '../../../helpers/functions';

interface ContractsIndieUniverseProps {
    userContract: any,
    pmsData: any
}

export const ContractsIndieUniverse: React.FC<ContractsIndieUniverseProps> = ({ userContract, pmsData }) => {
    const location = useLocation();
    const name = sessionStorage.getItem("name") || '';
    const downloadContract = async (url: string, name: string) => {

        const pdfBlob = await fetch(url).then(r => r.blob());
        saveAs(pdfBlob, `${name}_contrato_de_mandato_${location?.state?.title.replaceAll(' ', '_').toLowerCase()}.pdf`);

    }

    return (
        <div className='md:flex flex-row mt-4'>
            <div className='w-full md:max-w-1/2 md:w-fit'>
                <div className="flex flex-col lg:mr-5">
                    <div className='w-[330px] flex flex-wrap mr-4'>
                        <a
                            id='reserveIndieDetail'
                            className="
                      bg-[#EBE2DD]
                      hover:bg-[#EBE2DD]/80
                      hover:text-black
                        hover:no-underline
                        no-underline
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                        mr-5
                      "
                            href='https://wa.link/mheirj'
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <img className='mr-2' width={30} src={indieIcon} alt="" />
                            Reserva en <br /> Indie Universe
                        </a>

                        <button
                            id='downloadReport'
                            className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[16px]
                        flex
                        justify-center
                        items-center
                      "
                            onClick={() => window.open('https://docsend.com/view/c43a2bz2497jwywj', "blank")}
                        >
                            <img className='mr-2' width={30} src={reportIcon} alt="" />
                            Reporte <br /> trimestral 2022
                        </button>

                        <button
                            id='downloadReport'
                            className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                        mr-5
                      "
                            onClick={() => window.open('https://docsend.com/view/vduaf5ekw8gv5yjb', "blank")}
                        >
                            <img className='mr-2' width={30} src={reportIcon} alt="" />
                            Reporte 2022 (4) <br /> 2023 (1)
                        </button>
                        <button
                            id='downloadReport'
                            className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                      "
                            onClick={() => window.open('https://docsend.com/view/jifetre26z4nzcpu', "blank")}
                        >
                            <img className='mr-2' width={30} src={reportIcon} alt="" />
                            Reporte 2023 (2)
                        </button>
                        <button
                            id='downloadReport'
                            className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                        mr-5
                      "
                            onClick={() => window.open('https://docsend.com/view/brh54pepy8tcidq6', "blank")}
                        >
                            <img className='mr-2' width={30} src={reportIcon} alt="" />
                            Reporte 2023 (3)
                        </button>
                        <button
                            id='downloadReport'
                            className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                      "
                            onClick={() => window.open('https://docsend.com/view/cdjbiyxqy5a7fmkb', "blank")}
                        >
                            <img className='mr-2' width={30} src={reportIcon} alt="" />
                            Reporte 2024 (1)
                        </button>
                        <button
                            id='downloadReport'
                            className="
                      bg-[#E4E4E4]
                      hover:bg-[#E4E4E4]/80
                        w-[155px]
                        rounded 
                        py-3
                        font-medium 
                        text-sm
                        text-left
                        cursor-pointer
                        duration-300 
                        ease-in-out
                        mb-[20px]
                        flex
                        justify-center
                        items-center
                      "
                            onClick={() => window.open('https://docsend.com/view/pfd8hy8fpdv5g2as', "blank")}
                        >
                            <img className='mr-2' width={30} src={reportIcon} alt="" />
                            Reporte 2024 (2)
                        </button>
                    </div>
                </div>
                <div className="lg:w-[330px] bg-white p-[30px] rounded-2xl">

                    <div className="text-2xl font-lato font-black mb-3">
                        Contratos de mandato
                    </div>

                    {

                        userContract?.map((contract: any, i: any) =>

                            <div
                                key={i}
                                className="flex justify-between py-3 cursor-pointer border-b-[0.5px] border-gray-300 last:border-b-0 text-gray-500"
                                onClick={() => downloadContract(contract.contractFile, name)}
                            >

                                <div className="">{contract.id.slice(0, 5)}_contrato_mandato_{name}</div>
                                <svg className='text-gray-500 hover:text-black focus:text-black' width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                    <path d="M14.375 15.0938H17.7891C20.2598 15.0938 22.2812 14.141 22.2812 11.6977C22.2812 9.25436 19.9004 8.39725 17.9688 8.30156C17.5694 4.48051 14.7793 2.15625 11.5 2.15625C8.40039 2.15625 6.40406 4.21322 5.75 6.25312C3.05469 6.50918 0.71875 7.86492 0.71875 10.6734C0.71875 13.482 3.14453 15.0938 6.10938 15.0938H8.625M8.625 17.9732L11.5 20.8438L14.375 17.9732M11.5 10.0625V20.1263" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>


                            </div>

                        )

                    }


                </div>
            </div>
            <div className='w-full md:max-w-1/2 md:w-fit my-10 md:my-0'>
                <div className="flex flex-col justify-start lg:w-[330px] bg-white px-12 py-10 rounded-2xl lg:mr-5">

                    <div className="text-xl font-lato font-black mb-4">Reporte diario</div>

                    <div className="divide-y divide-slate-300 mb-3">

                        <div className="mb-3">

                            <div className="flex justify-between">

                                <div className="text-lg font-lato font-black">Dinero recaudado</div>
                                <div className="text-[#505050]">{numberWithCommas(pmsData.recaudo_p)} COP</div>

                            </div>

                            <div className="flex justify-between">

                                {/* <div className="text-sm text-[#858181]">Espacios terminados</div> */}
                                {/* <div className="text-sm">ver todo</div> */}

                            </div>

                        </div>

                        <div className="mb-3 pt-3">

                            <div className="flex justify-between">

                                <div className="text-lg font-lato font-black">Ocupación</div>
                                <div className="text-[#505050]">{pmsData.percent_occupied}%</div>

                            </div>

                            <div className="flex justify-between">

                                <div className="text-sm text-[#858181]">Historico 2023</div>
                                {/* <div className="text-sm">ver todo</div> */}

                            </div>

                        </div>
                        <div className="mb-3 pt-3">

                            <div className="flex justify-between">

                                <div className="text-lg font-lato font-black">Habitaciones ocupadas</div>
                                <div className="text-[#505050]">{pmsData.ocupadas}</div>

                            </div>

                            <div className="flex justify-between">

                                {/* <div className="text-sm text-[#858181]">Personas que recomiendan</div> */}
                                {/* <div className="text-sm">ver todo</div> */}

                            </div>

                        </div>

                    </div>

                    <div
                        className={`bg-[#DFDDEB] text-black hover:bg-gray-700 px-3 py-2.5 w-full rounded-full font-medium text-xs text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed`}
                    >
                        Recomendar Indie
                    </div>

                </div>
            </div>
        </div>
    )
}
