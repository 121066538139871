import React, { useEffect, useState } from 'react'
import { PayHistory } from '../components/PayHistory';
import { PaymentSources } from '../components/PaymentSources';
import { CreditCardForm } from '../components/CreditCardForm';
import { PayNow } from '../components/PayNow';
import { PsePayment } from '../components/PsePayment';
import { CreditCardPayment } from '../components/CreditCardPayment';
import { scrollToSection } from '../../../helpers/functions';

export const SubscriptionPage = () => {

  const [showFormCreatePaymentSource, setShowFormCreatePaymentSource] = useState(false);
  const [showFormPsePayment, setShowFormPsePayment] = useState(false);
  const [showFormCreditCardPayment, setShowFormCreditCardPayment] = useState(false);

  const [expandPayNow, setExpandPayNow] = useState(false);

  const resetShowForms = () => {
    setShowFormCreatePaymentSource(false);
    setShowFormPsePayment(false);
    setShowFormCreditCardPayment(false);
    setExpandPayNow(false);
    localStorage.removeItem('nextPayment')
    localStorage.removeItem('installmentsCount')
    localStorage.removeItem('totalInvestment')
  }

  const toggleShowFormCreatePaymentSource = () => {
    setShowFormCreatePaymentSource((value) => !value)
    setShowFormPsePayment(false)
    setShowFormCreditCardPayment(false)
  }

  const toggleShowFormPsePayment = () => {
    setShowFormCreatePaymentSource(false)
    setShowFormPsePayment((value) => !value)
    setShowFormCreditCardPayment(false)
  }

  const toggleShowFormCreditCardPayment = () => {
    setShowFormCreatePaymentSource(false)
    setShowFormPsePayment(false)
    setShowFormCreditCardPayment((value) => !value)
  }

  const handleSetInstallmentToPay = (nextPayment: any) => {
    scrollToSection('pay-now', 160)
    localStorage.setItem('nextPayment', JSON.stringify(nextPayment));
    setExpandPayNow(true);
  }

  useEffect(() => {

  }, [])

  return (
    <>
      <div className="w-full flex gap-4 py-4 flex-col lg:flex-row">

        {
          showFormCreatePaymentSource
          ? <CreditCardForm
              showForm={toggleShowFormCreatePaymentSource}
            />
          : showFormPsePayment
          ? <PsePayment resetShowForms={resetShowForms}/> 
          : showFormCreditCardPayment
          ? <CreditCardPayment 
              resetShowForms={resetShowForms}
            />
          : (
            <>
              <PayHistory
                setInstallmentToPay={handleSetInstallmentToPay}
              />
              <div className="w-full lg:w-2/5 flex flex-col gap-3">
                <PaymentSources
                  showForm={toggleShowFormCreatePaymentSource}
                />
                <PayNow
                  expandPayNow={expandPayNow}
                  setCreditCardPayment={toggleShowFormCreditCardPayment}
                  setPsePayment={toggleShowFormPsePayment}
                />
              </div>
            </>
          )
        }


      </div>
    </>
  )
}