import React, { useEffect, useState } from 'react'
import { CreditCardPaymentForm } from './CreditCardPaymentForm'
import { PaymentSummary } from './PaymentSummary'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { payInstallment } from '../../../services/paymentDataServices'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { scrollToSection } from '../../../helpers/functions'
import { getUserData } from '../../../services/ownerServices'

const CARD_FEE_PERCENTAGE = 0.025  // 2.5% fee for credit card payments

interface CreditCardPaymentProps {
  resetShowForms: () => void
}

export const CreditCardPayment: React.FC<CreditCardPaymentProps> = ({
  resetShowForms
}) => {
  const navigate = useNavigate()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ nextPayment, setNextPayment ] = useState<any>({})
  const [ totalMonths, setTotalMonths ] = useState<any>()
  const [ totalInvestment, setTotalInvestment ] = useState<any>()
  const [ totalToPay, setTotalToPay ] = useState<number>(0)
  const [ installmentId, setInstallmentId ] = useState<string>('')
  const [ user, setUser ] = useState<any>()
  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
    setValue
  } = useForm<FieldValues>({
    defaultValues: {
      cardNumber: '',
      cardHolder: '',
      expDate: '',
      cvc: '',
      automaticDebit: false
    }
  })

  const onSubmit: SubmitHandler<FieldValues> = (async(data) => {
    setIsLoading(true);
    const body = {
      cardNumber: data.cardNumber,
      customerFullName: data.cardHolder,
      customerEmail: user.email,
      expMonth: data.expDate.split('/')[0],
      expYear: data.expDate.split('/')[1],
      paymentMethod: 'CARD',
      installmentId: installmentId,
      customerDocumentType: user.documentType,
      customerDocumentNumber: user.documentNumber,
      customerCountryPhoneCode: user.countryPhoneCode,
      customerPhoneNumber: user.phone,
      cvc: data.cvc
    }
    if (!body.customerCountryPhoneCode) {
      delete body.customerCountryPhoneCode;
    }
    try {
      const response = await payInstallment(body);
      if(response.data.data.status === 'APPROVED') {
        toast.success('Pago realizado con éxito')
        resetShowForms();
        navigate(`/payment/successful?id=${response.data.data.id}`)
      } else if(response.data.data.status === 'PENDING') {
        toast('Pago pendiente de aprobación', {icon: '⏳'})
        resetShowForms();
      } else if(response.data.data.status === 'DECLINED') {
        toast.error('Pago declinado')
        resetShowForms();
      } else if(response.data.data.status === 'ERROR') {
        toast.error('Error en el pago')
        resetShowForms();
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data.message || ''
      const errorData = error?.response?.data?.data || ''
      toast.error(`${errorMessage}.\n\n${errorData}`, { duration: 5000})
      resetShowForms()
    } finally {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    // Set values from localStorage
    let nextPayment = localStorage.getItem('nextPayment')
    let totalMonths = localStorage.getItem('installmentsCount')
    let totalInvestment = localStorage.getItem('totalInvestment')
    const nextPaymentInJson = JSON.parse(nextPayment!)
    setTotalMonths(totalMonths)
    setNextPayment(nextPaymentInJson)
    setTotalInvestment(totalInvestment)
    setInstallmentId(nextPaymentInJson.id)
    getUserData().then((response) => {
      setUser(response.data.data)
    })
  }, [])

  useEffect(() => {
    setTotalToPay(Math.round(Number(nextPayment?.value + (nextPayment?.value * CARD_FEE_PERCENTAGE))))
  }, [nextPayment])

  useEffect(() => {
    scrollToSection('credit-card-payment', 160)
  }, [])

  return (
    <div className="w-full flex gap-4 py-4 flex-col lg:flex-row" id="credit-card-payment">
      <CreditCardPaymentForm resetShowForms={resetShowForms} setValue={setValue} register={register} errors={errors} />
      <PaymentSummary
        total={totalToPay}
        totalMonths={totalMonths}
        subTotal={Number(nextPayment?.value)}
        totalInvestment={Number(totalInvestment)}
        fees={Number(nextPayment?.value * CARD_FEE_PERCENTAGE)}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
      />
    </div>
  )
}
