import { useEffect, useState } from 'react'
import { getCreditCardFeePercentage, getFinancingPercentage, unitPrice } from '../helpers/functions';

export const useInvestSummary = (
  unitAmount: string,
  installmentsQty: string,
  paymentMethod: number | null,
  couponUnitPrice: number = 0,
  couponSubscriptionFeeFree: boolean = false
) => {

  const [investSummaryValues, setInvestSummaryValues] = useState({
    totalInvestment: 0,
    installments: 0,
    financingCost: 0,
    subtotal: 0,
    paymentMethodFee: 0,
    total: 0,
    monthValue: 0
  });

  useEffect(() => {

    let unitPriceValue = unitPrice();
    let financingPercentage = getFinancingPercentage();

    if (couponUnitPrice) {
      unitPriceValue = couponUnitPrice;
    }

    if (couponSubscriptionFeeFree) {
      financingPercentage = 0;
    }

    const unitAmountNumber = Number(unitAmount);
    const installmentsQtyNumber = Number(installmentsQty);
    const totalInvestment = unitAmountNumber * unitPriceValue;
    let monthlyFinancingCost = 0;
    let paymentMethodFee = 0;
    
    if (installmentsQtyNumber > 1) {
      monthlyFinancingCost = (totalInvestment * financingPercentage) / 100
    }

    const subtotal = totalInvestment / installmentsQtyNumber;

    const monthValue = subtotal + monthlyFinancingCost;

    if (paymentMethod === 1) {
      paymentMethodFee = (getCreditCardFeePercentage() * monthValue) / 100;
    }

    const total = parseInt((subtotal + paymentMethodFee + monthlyFinancingCost).toFixed(0));

    setInvestSummaryValues({
      totalInvestment,
      installments: installmentsQtyNumber,
      financingCost: monthlyFinancingCost,
      subtotal,
      paymentMethodFee,
      total,
      monthValue
    });
  }, [unitAmount, installmentsQty, paymentMethod, couponUnitPrice, couponSubscriptionFeeFree]);

  return {
    ...investSummaryValues
  }
}
