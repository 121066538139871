import React from 'react'
import banner from '../../../../assets/img/checkout/banner-complete.png';
import { unitPriceMulplicated } from '../../../../helpers/functions';



export const Banner = () => {
  return (
    <div className="rounded-lg relative overflow-hidden">
      {/* FIXME: Agregar valor dinámico */}
      <div>
        <img src={banner} alt="Banner" />
      </div>
      <div className="absolute top-1/2 mx-10 -translate-y-1/2 text-white">
        <p className="text-xl font-bold">Estás a un paso <br /> de obtener más beneficios <br /> invirtiendo <span className="text-[#FFA8E1]">${unitPriceMulplicated(250)}</span></p>
      </div>
    </div>

  )
}
