// import React, { useCallback, useEffect, useState } from 'react'
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { generateCode, isAuthenticated, unitPrice } from '../../helpers/functions';
// import DragAndDrop from '../../Payment/dragAndDrop';
// import jwt_decode from "jwt-decode";
// import {
//   getUserData,
//   signContract,
// } from '../../services/ownerServices';
// import { SubmitHandler, useForm } from 'react-hook-form';
// import moment from 'moment';
// import PhoneInput from 'react-phone-number-input';
// import es from 'react-phone-number-input/locale/es.json';
import 'react-phone-number-input/style.css';
// import Datepicker from "react-tailwindcss-datepicker";
// import Select from "react-tailwindcss-select";
// import { fetchCities, fetchCountries, fetchRegions } from '../../services/regionsServices';
// import backIcon from "../../assets/img/checkout/back-icon.svg";
// import { urls } from '../../helpers/urls';
// import { trigger } from '../../helpers/events';
import { PersonalDataX } from '../Checkout/PersonalData';

// type FormValues = {
//   country: string;
//   first_name: string;
//   last_name: string;
//   document: number;
//   document_type: string;
//   date_birth: Date;
//   address: string;
//   state: string;
//   city: string;
//   type: string;
//   phone: number;
//   phone_code: number;
//   imgFront: any;
//   referral_code: string;
// };

function PersonalData() {
  return (
    <div>
      <PersonalDataX />
    </div>
  )
}

export default PersonalData;