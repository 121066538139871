import React from 'react'
import { numberWithCommas } from '../../../../helpers/functions';
import moment from 'moment';
// import { getLastIdProject } from '../../../../services/ownerServices';

interface SummaryCardProps {
  totalInvestment: number;
  installments: number;
  financingCost: number;
  subtotal: number;
  paymentMethodFee: number;
  total: number;
  goNextStep: () => void;
  error: string | null;
  isLoading: boolean;
  coupon?: string;
}

export const SummaryCard: React.FC<SummaryCardProps> = ({
  totalInvestment,
  installments,
  financingCost,
  subtotal,
  paymentMethodFee,
  total,
  goNextStep,
  error,
  isLoading,
  coupon
}) => {

  return (
    <div className="border rounded-md border-black px-12 py-10">
      <div className="mb-8 flex flex-col gap-3">
        <div>
          <h2 className="font-extrabold text-2xl font-lato mb-2">Resumen de inversión</h2>
          <p className="text-sm text-gray-500">Orden de inversión: {moment().format('MMM D, YYYY')}</p>
        </div>
        <hr className="border-gray-400" />
        <div>
          <p>Proyecto: {'Nido de agua'}</p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="font-semibold text-lg">Pago por suscripción</p>
          <div className="flex justify-between items-center">
            <p>Inversión total</p>
            <p className="text-lg font-semibold">${numberWithCommas(totalInvestment)} <span>COP</span></p>
          </div>
          <div className="flex justify-between items-center">
            <p>Cuotas diferidas</p>
            <p className="text-lg font-semibold">{installments > 1 ? `${installments} meses` : `${installments} mes`}</p>
          </div>
          {
            coupon &&
            <div className="flex justify-between items-center">
              <p>Cupón</p>
              <p className="text-lg font-semibold bg-green-400 px-2 rounded-md">{coupon}</p>
            </div>
          }
        </div>
        <hr className="border-gray-400" />
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <p className="font-semibold text-lg">Subtotal</p>
            <p className="text-lg font-semibold">${numberWithCommas(subtotal)} <span>COP</span></p>
          </div>
          <div>
            {
              installments > 1 && 
              <p className="text-sm text-gray-500">Próximo pago {moment().add(1, 'months').format('MMM D, YYYY')}</p>
            }
          </div>
          <div className="flex justify-between items-center">
            <p>Costo de financiación</p>
            <p className="text-lg font-semibold">${numberWithCommas(financingCost)} <span>COP</span></p>
          </div>
          <div className="flex justify-between items-center">
            <p>Tarifa pago con tarjeta <br /> de crédito +2,5%</p>
            <p className="text-lg font-semibold">${numberWithCommas(paymentMethodFee)} <span>COP</span></p>
          </div>

        </div>
        <hr className="border-gray-400" />
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg">Total</p>
          <p className="text-lg font-semibold">${numberWithCommas(total)} <span>COP</span></p>
        </div>
      </div>

      <div>
        <button
          onClick={goNextStep}
          disabled={isLoading}
          className={`bg-black text-white rounded-full py-2 px-4 font-semibold w-full ${isLoading ? 'opacity-50' : ''}`}
        >
          {
            isLoading
              ? <div><svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
              </svg>
                <span>Cargando...</span></div>
              : <span>Continuar</span>
          }
        </button>
      </div>
      {/* Error filling data */}
      {
        error && 
        <div className="bg-[#FF7272] rounded-md flex gap-2 items-center py-3 justify-center mt-6">
          <div>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.64062 0C2.97898 0 0 2.97898 0 6.64062C0 10.3023 2.97898 13.2812 6.64062 13.2812C10.3023 13.2812 13.2812 10.3023 13.2812 6.64062C13.2812 2.97898 10.3023 0 6.64062 0ZM6.64062 2.72266C6.81137 2.72266 6.97827 2.77329 7.12024 2.86815C7.2622 2.963 7.37285 3.09783 7.43819 3.25557C7.50353 3.41332 7.52063 3.58689 7.48732 3.75436C7.45401 3.92182 7.37179 4.07564 7.25106 4.19637C7.13032 4.3171 6.9765 4.39932 6.80904 4.43263C6.64158 4.46594 6.46801 4.44885 6.31026 4.38351C6.15252 4.31817 6.01769 4.20752 5.92283 4.06555C5.82797 3.92358 5.77734 3.75668 5.77734 3.58594C5.77734 3.35698 5.8683 3.1374 6.03019 2.97551C6.19209 2.81361 6.41167 2.72266 6.64062 2.72266ZM8.23438 10.2266H5.3125C5.1716 10.2266 5.03648 10.1706 4.93685 10.071C4.83722 9.97133 4.78125 9.83621 4.78125 9.69531C4.78125 9.55442 4.83722 9.41929 4.93685 9.31966C5.03648 9.22003 5.1716 9.16406 5.3125 9.16406H6.24219V6.24219H5.71094C5.57004 6.24219 5.43492 6.18622 5.33529 6.08659C5.23566 5.98696 5.17969 5.85183 5.17969 5.71094C5.17969 5.57004 5.23566 5.43492 5.33529 5.33529C5.43492 5.23566 5.57004 5.17969 5.71094 5.17969H6.77344C6.91433 5.17969 7.04946 5.23566 7.14909 5.33529C7.24872 5.43492 7.30469 5.57004 7.30469 5.71094V9.16406H8.23438C8.37527 9.16406 8.5104 9.22003 8.61003 9.31966C8.70965 9.41929 8.76562 9.55442 8.76562 9.69531C8.76562 9.83621 8.70965 9.97133 8.61003 10.071C8.5104 10.1706 8.37527 10.2266 8.23438 10.2266Z" fill="white" />
            </svg>
          </div>
          <p className="text-white font-medium">{error}</p>
        </div>
      }
      <div>

      </div>
    </div>
  )
}
