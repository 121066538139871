import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { numberWithCommas, unitPrice } from '../../../../helpers/functions';
import moment from 'moment';

interface InstallmentCardProps {
  installmentQty: string;
  unitsAmount?: string;
  installmentArray?: { value: string, label: string }[];
  totalPayment: number;
}

export const InstallmentCard: React.FC<InstallmentCardProps> = ({
  installmentQty,
  installmentArray,
  unitsAmount = '0',
  totalPayment
}) => {

  const swiperRef = useRef<SwiperType>();

  const [totalUnitPrice, setTotalUnitPrice] = React.useState<number>(0);
  const [estimatedReturn, setEstimatedReturn] = React.useState<number>(0);

  useEffect(() => {
    const installmentIndex = installmentArray?.findIndex(installment => installment.value === installmentQty) || 0;
    if (installmentIndex === -1) {
      swiperRef.current?.slideTo(0)
    };
    swiperRef.current?.slideTo(installmentIndex + 1);
  }, [installmentQty, installmentArray]);

  useEffect(() => {
    if(!unitsAmount || !totalPayment) return
    const unitsAmountNumber = parseInt(unitsAmount);
    const totalUnitPrice = totalPayment * parseInt(installmentQty) / unitsAmountNumber;
    setTotalUnitPrice(totalUnitPrice)
    let unitPriceValue = unitPrice();
    let estimatedReturn = (((13 * unitPriceValue) / 100) / totalUnitPrice) * 100;
    setEstimatedReturn(parseFloat(estimatedReturn.toFixed(2)))
  }, [totalPayment, unitsAmount, installmentQty]);

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      lazy={true}
      loop={false}
      preventInteractionOnTransition={true}
      allowTouchMove={false}
      keyboard={{
        enabled: false,
      }}
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
    >
      <SwiperSlide>
        <div className="border rounded-lg my-3 px-10 py-4">
          <div className="grid grid-cols-3 mb-3">
            <div className="col-span-1 flex justify-start items-center">
              <div className="flex justify-center items-center gap-2">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5499 0.89373C5.85111 -0.0236742 7.14889 -0.023675 7.4501 0.893729L8.28067 3.4234C8.41546 3.83393 8.79868 4.11146 9.23077 4.11146H11.8962C12.8674 4.11146 13.2685 5.35639 12.4799 5.92338L10.3416 7.46082C9.98745 7.71545 9.83921 8.17026 9.97528 8.58468L10.7963 11.0852C11.0982 12.0047 10.0482 12.774 9.2624 12.2091L7.08377 10.6426C6.73499 10.3919 6.26501 10.3919 5.91623 10.6426L3.7376 12.2091C2.95184 12.774 1.90184 12.0047 2.20373 11.0852L3.02473 8.58468C3.16079 8.17026 3.01255 7.71545 2.65839 7.46082L0.520074 5.92338C-0.268505 5.35639 0.132592 4.11146 1.10384 4.11146H3.76923C4.20132 4.11146 4.58454 3.83393 4.71933 3.4234L5.5499 0.89373Z" fill="#5452F6" />
                </svg>
                <p className="font-medium text-[#5452F6]">Recomendado</p>
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <h3 className="font-semibold text-xl">1 mes</h3>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div>
              <div className="flex justify-between items-center">
                <p>Un pago de:</p>
                <p className="text-xl font-bold">${numberWithCommas(totalPayment)} <span className="text-sm">COP</span></p>
              </div>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500">Units:</p>
              <p className="font-semibold text-lg">{unitsAmount}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500">Valor del unit</p>
              <p className="font-semibold text-lg">${numberWithCommas(totalUnitPrice)} COP</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500">Retorno estimado anual:</p>
              <p className="font-semibold text-lg">13%</p>
            </div>

            <div className="flex justify-between">
              <div className="font-semibold text-sm">* Incluye valorización y rentas</div>
            </div>

          </div>
        </div>
      </SwiperSlide>
      {
        installmentArray?.map((installment, index) => (
          <SwiperSlide key={index}>
            <div className="border rounded-lg my-3 px-10 py-4">
              <div>
                <div className="flex justify-center items-center">
                  <h3 className="font-semibold text-xl">{installment.label}</h3>
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <div>
                  <div className="flex justify-between items-center">
                    <p>Cuotas mensuales de:</p>
                    <p className="text-xl font-bold">${numberWithCommas(totalPayment)} <span className="text-sm">COP</span></p>
                  </div>
                  <p className="text-sm text-gray-500">Próximo pago {moment().add(1, 'months').format('MMM D, YYYY')}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Units:</p>
                  <p className="font-semibold text-lg">{unitsAmount}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Valor del unit:</p>
                  <p className="font-semibold text-lg">${numberWithCommas(totalUnitPrice)} COP</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Retorno estimado anual:</p>
                  <p className="font-semibold text-lg">{estimatedReturn}%</p>
                </div>

                <div className="flex justify-between">
                  <div className="font-semibold text-sm">* Incluye valorización y rentas</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))
      }
    </Swiper>
  )
}
