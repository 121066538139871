export const ArticleFooter = () => {
    return (
        <div className="w-3/4 text-center mx-auto">
            <div className="flex flex-col justify-between">

                <p className="font-roboto text-lg">
                    En <strong>LOKL</strong>, estamos para guiarte en cada paso, entendiendo tus preocupaciones financieras y ayudándote a alcanzar tus metas con una estrategia de inversión personalizada.
                    <br />
                    <strong>¿Listo para dejar tu huella en el mundo de las inversiones inmobiliarias?</strong>
                    <br />
                    ¡Descubre cómo <strong>LOKL</strong> puede ser tu aliado y empieza a construir el futuro que anhelas!
                </p>

            </div>
        </div>
    )
}
