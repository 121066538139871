import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface WritingEffectProps {
  text: string;
  fontSize?: string;
  fontWeight?: string;
}

const WritingEffect: React.FC<WritingEffectProps> = ({ text, fontSize = '24px', fontWeight = 'bold' }) => {
  const [displayText, setDisplayText] = useState('');
  const controls = useAnimation();

  useEffect(() => {
    const animateText = async () => {
      for (let i = 0; i < text.length; i++) {
        setDisplayText(text.substring(0, i + 1));
        await controls.start({ opacity: 1, transition: { duration: 0.1 } });
        // await controls.start({ opacity: 0, transition: { duration: 0.3 } });
      }
    };

    animateText();
  }, [controls, text]);

  return (
    <div>
      <motion.span style={{ fontSize, fontWeight }} animate={controls}>
        <span dangerouslySetInnerHTML={{ __html: displayText }} />
      </motion.span>
    </div>
  );
};

export default WritingEffect;
