import React, { useState, useEffect, useRef } from 'react'
import closeIcon from '../assets/img/home/close-white-2.svg'
// import { on } from '../helpers/events';
// import { getNameEmail } from '../helpers/functions';
import { signContract, verifySignContract } from '../services/ownerServices';
import loklProfile from '../assets/img/lokl-profile.png';

const ModalContractCode: React.FC = () => {

  // const [count, setCount] = useState(30);
  let textInitial = 'Tú contrato no se término de firmar, revisa de nuevo tu correo para continuar con el proceso';
  
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const modalOverlayRef = useRef<HTMLDivElement>(null);
  
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>(textInitial);

  function closeModal() {
    setIsOpen(false);
  }

  const removeUrlParam = (paramKey: string): string => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
  
    if (searchParams.has(paramKey)) {
      searchParams.delete(paramKey);
      url.search = searchParams.toString();
    }
  
    return url.search;
  };
  
  
  const goToPay = () => {
    // restartTimer();
    
    if(errorMsg === true){
      const sentData = JSON.parse(localStorage.getItem('dtdataBilling') || '')
      signContract('1', sentData)
      .then(res => {
        setErrorMsg(true);
      })
      .catch(err => {
        setErrorMsg(false);
      })
    }else{
      window.location.href = '/payment/complete' + removeUrlParam('code');
    }
    // console.log('go to pay', '/payment/complete' + removeUrlParam('code'));

  }

  // const restartTimer = () => {
  //   setCount(30);
  // };

  // const formatTime = (time: number): string => {
  //   const minutes = Math.floor(time / 60).toString().padStart(2, '0');
  //   const seconds = (time % 60).toString().padStart(2, '0');
  //   return `${minutes}:${seconds}`;
  // };

  useEffect(() => {

    const url = new URL(window.location.href);

    const codeValue = url.searchParams.get('code')

    if(codeValue !== null){
      
      setIsOpen(true)

      signContract('2', {code: codeValue})
      .then(res => {
        if(res?.data?.code === "00"){
          setErrorMsg(false);
        }else{
          setErrorMsg(true);
          let msg = res?.data?.message;
          if(msg !== null || msg !== undefined){
            msg = msg.replaceAll("OTP", "Código");
          }
          setErrorText(msg)
        }
      })
      .catch(err => {
        setErrorMsg(true)
      })

      const reference = localStorage.getItem("reference") || '';
      
      verifySignContract(reference)
      .then(res => {
        if(res?.data?.code === "00"){
          // setErrorMsg(false);
          console.log('contrato firmado');
        }else{
          // setErrorMsg(true);
          console.log('contrato no firmado');
        }
      })
      .catch(err => {
        console.log('contrato no firmado');
        // setErrorMsg(true)
      })

    }

    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        modalOverlayRef.current &&
        !modalOverlayRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  
    // eslint-disable-next-line
  }, []);

  return (

    isOpen === true ?

    <div className={`fixed top-0 left-0 right-0 bottom-0 z-[100] ${ errorMsg === true ? 'bg-red-600/75' : 'bg-[#96DEDA]/75' } bg-opacity-75 ${ isOpen === true ? 'flex' : 'hidden' } items-center justify-center`} ref={modalOverlayRef}>
      <div className="relative w-96 h-96 bg-white rounded-xl p-6 flex flex-col justify-center items-center" ref={modalRef}>

        <img onClick={() => {
          setIsOpen(false);
          sessionStorage.setItem("modalIsClose", 'true');
        }} 
        width={12} height={12} className='absolute -top-5 -right-0 lg:-right-[7%] cursor-pointer' src={closeIcon} alt="close" />

        <img className='mb-3' src={loklProfile} alt="lokl profile" />

        {
          errorMsg === true ?
          <div className="w-[90%] font-lato text-base text-center mb-2">
            <strong className='text-xl'>¡Algo salio mal!</strong> <br /> {errorText}
          </div>
          :
          <>
          
          <div className="w-[90%] font-lato text-base text-center mb-2">
            <strong className='text-xl'>¡Bien hecho!</strong> <br /> Tú contrato se ha firmado exitosamente, continúa en el proceso de pago
          </div>

          </>
        }


        <div 
          className="btn-black mt-3"
          onClick={() => goToPay()}
        >

          { errorMsg === true ? 'Volver a firmar' : 'Continuar' }
          
        </div>

      </div>
    </div>

    : null

  )
}

export default ModalContractCode;