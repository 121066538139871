import React, { useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import Checkbox from '../../components/Checkbox';
import { trigger } from '../../helpers/events';
import { serviceLogin } from '../../services/authService';

import nidoLogin from '../../assets/img/auth/nido-login.jpg';
import logo from '../../assets/img/footer/lokl-white.svg';
import Input from '../../components/inputs/Input';
import PasswordInput from '../../components/inputs/PasswordInput';

function Login() {

  // states
  const [terms, setTerms] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<FieldValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onTouched'
  });

  const redirectRegister = () => {

    const url = new URL(window.location.href);
    return url.search;

  }

  /**
   * Se ejecuta al enviar hacer en boton de iniciar sesión
   * o al hacer enter en algún compo
   * 
   * @param data 
   * @param event 
   */
  const onSubmit: SubmitHandler<FieldValues> = (data, event) => {

    // console.log('data login', data);
    setBtnActive(true);

    const url = new URL(window.location.href);
    const getParam = url.searchParams.get('redirect_to');
    const getHash = url.hash || '';

    serviceLogin({ email: data.email, password: data.password })
    .then(res => {
      if(res.data){
        setBtnActive(false);
        let token = res.data.data; 
        localStorage.setItem('token', token);
        trigger('authed');
        trigger('reload:dashboard');
        

        if(getParam){
          if(getParam.startsWith('http://checkout.lokl.life')) {
            window.location.href = getParam + `&token=${data.token}`;
          } else if(getParam === "/" && getHash === "") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = getParam + getHash;
          }
        }else{
          window.location.href = "/dashboard";
        }
      
      }
    })
    .catch(err => {
      setBtnActive(false);
      setErrorMsg(err.message);
      setTimeout(() => {
        setErrorMsg('');
      }, 5000);
    });

  }

  return (
    <div className='flex justify-center pt-[34px] min-h-[calc(100vh-21px)]'>

      <div className="w-full lg:w-[800px] flex justify-center items-center">

        <div className="max-w-[370px] lg:max-w-[400px] px-10 pt-0 lg:pt-10 lg:mt-4">

          <div className="font-black font-lato text-3xl mb-3">
            Inicia sesión
          </div>

          <div className="font-noto text-base text-gray-500 mb-16">

            <div className="">Hola de nuevo, un gusto tenerte de vuelta, conoce los nuevos proyectos que tenemos para ti.</div>   
            
            { errorMsg !== '' && <div className="text-red-400">{errorMsg}</div> }
          
          </div>


          <form id="formLogin" className='mb-16 space-y-4' onSubmit={handleSubmit(onSubmit)}>

            <Input
              id='email'
              label='Correo electrónico'
              type='email'
              required={true}
              register={register}
              errors={errors}
              touchedFields={touchedFields}
              pattern={/\S+@\S+\.\S+/}
              patternErrorMessage='El correo electrónico no es válido'
              requireErrorMessage='El correo electrónico es requerido'
            />

            <PasswordInput
              id='password'
              label='Contraseña'
              required={true}
              register={register}
              errors={errors}
              touchedFields={touchedFields}
              requireErrorMessage='El correo electrónico es requerido'
            />

            <div className="mt-3">
              <a className="text-violet-lokl" href='/reset-password'>Olvide mi contraseña</a>
            </div> 

            <Checkbox setTerms={setTerms} terms={terms} name="Guardar en este navegador" />

          </form>

          <div className="flex justify-between items-center mb-4">

            <button 
              id="btnLogin" 
              form='formLogin' 
              className="w-full btn-login"
              disabled={btnActive}
            >
              Ingresar
            </button>

          </div>

          <div className="w-full justify-center items-center text-center mb-16">

            <div className="text-gray-400">¿Aún no tienes una cuenta?</div>
            <a id="redirectRegister" className='text-violet-lokl no-underline' href={`/register${redirectRegister()}`}>Registrate aquí</a>

          </div>
        
        </div>

      </div>

      <div className={`hidden lg:flex relative w-full h-screen`}
        style={{
          backgroundImage: 'url('+nidoLogin+')',
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >

        <div className="absolute top-24 left-28 h-[80%] w-[80%]">

          <div className=" text-white font-extrabold text-4xl">
            
            Creemos que la <span className='text-[#DEDABA]'>vida es una sola</span>
            <br />
            para solo vivirla <span className='text-[#DEDABA]'>los fines de semana</span>

            <div className="text-2xl font-normal mt-2">Bienvenidos Nido de Agua | Proyecto en lanzamiento</div>

          </div>

          <div className="absolute top-[90%] text-white">
            Guatapé, Antioquia
          </div>

          <div className="w-[100px] h-[30px] absolute top-[90%] left-[85%]">
            <img src={logo} alt="logo-lokl" />
          </div>

        </div>

      </div>

    </div>
  )
}

export default Login;
