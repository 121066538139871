import React, { useCallback, useEffect, useState } from 'react'
import { getOwnersSubcription } from '../../../services/dashboardServices';
import { numberToCurrencyFormat } from '../../../utils';
import { NextPayment } from './NextPayment';
import moment from 'moment';
import { useParams } from 'react-router-dom';

interface PayHistoryProps {
  setInstallmentToPay: (installment: any) => void;
}

export const PayHistory: React.FC<PayHistoryProps> = ({
  setInstallmentToPay
}) => {

  const { projectId = '' } = useParams()

  const [subscriptionHistory, setSubscriptionHistory] = useState<any[]>([]);
  const [approvedInstallments, setApprovedInstallments] = useState<any[]>([]);
  const [nextPayment, setNextPayment] = useState<any>({});

  const handleApprovedPayments = useCallback(() => {
    const approvedInstallments = subscriptionHistory.filter((sus: any) => sus.status === "APPROVED");
    setApprovedInstallments(approvedInstallments);
  }, [subscriptionHistory])

  const handleNextPayment = useCallback(() => {
    const pendingInstallments = subscriptionHistory.filter((sus: any) => sus.status === "PENDING");
    const nextMonth: any = pendingInstallments[0]
    setNextPayment({
      value: parseInt(nextMonth?.installmentValue),
      date: nextMonth?.installmentDate,
      id: nextMonth?.installmentId,
      subscriptionFee: nextMonth?.subscriptionFeeValue,
      investmentValue: nextMonth?.investmentValue,
    })
  }, [subscriptionHistory])

  const getSubscription = async () => {
    const { data } = await getOwnersSubcription(projectId);
    const installments = data?.data?.installments || [];
    localStorage.setItem('totalInvestment', data.data.totalInvestmentValue);
    localStorage.setItem('installmentsCount', JSON.stringify(data?.data?.installments?.length));
    if(data?.data){
      setSubscriptionHistory(installments);
    }    
  }

  useEffect(() => {
    getSubscription();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    handleApprovedPayments();
    handleNextPayment();
  }, [handleNextPayment, handleApprovedPayments])

  const handleSetInstallmentToPay = () => {
    setInstallmentToPay(nextPayment);
  }


  return (
    <div className="bg-white rounded-md px-10 py-4 w-full lg:w-2/4 xl:w-2/5 h-fit">
      <div className="flex items-center gap-3 mb-3">
        <div>
          <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="45" height="43" rx="5" fill="#54FFCC"/>
            <path d="M12 21C12 16.286 12 13.9289 13.4645 12.4645C14.9289 11 17.286 11 22 11C26.714 11 29.0711 11 30.5355 12.4645C32 13.9289 32 16.286 32 21C32 25.714 32 28.0711 30.5355 29.5355C29.0711 31 26.714 31 22 31C17.286 31 14.9289 31 13.4645 29.5355C12 28.0711 12 25.714 12 21Z" stroke="#1C274C" strokeWidth="1.5"/>
            <path d="M16 24.8L17.1429 26L20 23" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 17.8L17.1429 19L20 16" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23 18H28" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M23 25H28" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
          </svg>
        </div>
        <h2 className="font-bold text-lg">Historial de pagos</h2>
      </div>

      <div className="mb-3">
        <p className="font-semibold">Cuotas mensuales</p>
      </div>

      <div>
        {
          approvedInstallments.map((pay) => (
              <div key={pay.installmentId}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4 my-3">
                    <div className={`w-[11px] h-[11px] rounded-full ${pay.status === 'APPROVED'? 'bg-green-400': 'bg-red-400'}`}>
                    </div>
                    <p className="font-bold font-epilogue">{moment(pay.installmentDate).format('DD/MM/YYYY')}</p>
                  </div>
                  <div>
                    <p className="font-bold text-[16px] font-epilogue">{numberToCurrencyFormat(pay.installmentValue)}</p>
                  </div>
                </div>
                <hr className="border-slate-400" />
              </div>
            )
          )
        }
      </div>

      <div>
        {
          nextPayment.value > 0 
          ?<NextPayment amount={nextPayment.value} date={nextPayment.date} setInstallment={handleSetInstallmentToPay} />
          : <div className="flex justify-center mt-4">
              <p className="font-semibold">No hay cuotas pendientes</p>
          </div>
        }
      </div>
    </div>
  )
}