export const payoutBanks = [
  { "label": "santander", "value": "santander" },
  { "label": "coofinep", "value": "coofinep" },
  { "label": "avvillas", "value": "avvillas" },
  { "label": "cotrafa", "value": "cotrafa" },
  { "label": "movii", "value": "movii" },
  { "label": "confiar", "value": "confiar" },
  { "label": "union", "value": "union" },
  { "label": "rappipay", "value": "rappipay" },
  { "label": "nequi", "value": "nequi" },
  { "label": "bancoagrario", "value": "bancoagrario" },
  { "label": "bancoomeva", "value": "bancoomeva" },
  { "label": "bancamia", "value": "bancamia" },
  { "label": "coopcentral", "value": "coopcentral" },
  { "label": "citibank", "value": "citibank" },
  { "label": "lulo", "value": "lulo" },
  { "label": "bancodeoccidente", "value": "bancodeoccidente" },
  { "label": "falabella", "value": "falabella" },
  { "label": "bancodebogota", "value": "bancodebogota" },
  { "label": "bancocajasocial", "value": "bancocajasocial" },
  { "label": "serfinanza", "value": "serfinanza" },
  { "label": "cfa", "value": "cfa" },
  { "label": "coltefinanciera", "value": "coltefinanciera" },
  { "label": "pichincha", "value": "pichincha" },
  { "label": "iris", "value": "iris" },
  { "label": "ban100", "value": "ban100" },
  { "label": "bancopopular", "value": "bancopopular" },
  { "label": "daviplata", "value": "daviplata" },
  { "label": "bancolombia", "value": "bancolombia" },
  { "label": "finandina", "value": "finandina" },
  { "label": "scotiabankcolpatria", "value": "scotiabankcolpatria" },
  { "label": "gnbsudameris", "value": "gnbsudameris" },
  { "label": "itau", "value": "itau" },
  { "label": "bbva", "value": "bbva" },
  { "label": "davivienda", "value": "davivienda" },
  { "label": "mibanco", "value": "mibanco" },
  { "label": "multibank", "value": "multibank" },
  { "label": "mundomujer", "value": "mundomujer" },
  { "label": "procreditcolombia", "value": "procreditcolombia" },
  { "label": "bancow", "value": "bancow" },
  { "label": "bancoldex", "value": "bancoldex" },
  { "label": "juriscoop", "value": "juriscoop" },
  { "label": "itauhelm", "value": "itauhelm" },
  { "label": "jpmorgancolombia", "value": "jpmorgancolombia" },
  { "label": "asopagos", "value": "asopagos" },
  { "label": "pibank", "value": "pibank" },
  { "label": "jfk", "value": "jfk" },
  { "label": "ding", "value": "ding" },
  { "label": "uala", "value": "uala" },
  { "label": "btgpactual", "value": "btgpactual" },
  { "label": "powwi", "value": "powwi" },
  { "label": "bold", "value": "bold" },
  { "label": "coink", "value": "coink" },
  { "label": "santanderconsumer", "value": "santanderconsumer" },
  { "label": "global66", "value": "global66" },
  { "label": "nu", "value": "nu" }
]