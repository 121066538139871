import React from 'react'
import cardOn from '../../../assets/img/payment/visa-on.svg';
import cardOff from '../../../assets/img/payment/visa-off.svg';
import pseOn from '../../../assets/img/payment/ban-on.svg';
import pseOff from '../../../assets/img/payment/ban-off.svg'

interface PaymentMethodSelectProps {
  selectedMethod: number | null,
  handleMethodSelected: (method: number) => void
  showOne?: boolean
}

export const PaymentMethodSelect: React.FC<PaymentMethodSelectProps> = ({
  selectedMethod,
  handleMethodSelected,
  showOne = false
}) => {

  return (
    <div className="my-3 w-full flex flex-col gap-3">
      <div className={`
        flex
        justify-between
        items-center
        p-4
        border-[1px]
        ${selectedMethod === 1 ? 'border-violet-600' : 'border-gray-400'}
        cursor-pointer rounded
        ${selectedMethod === 2 && showOne ? 'hidden' : ''}
        `
      }
        onClick={() => {
          handleMethodSelected(1);
        }}
      >
        <div className="flex items-center">
          <div className="mr-5">
            <img src={selectedMethod === 1 ? cardOn : cardOff} alt="" />
          </div>
          <div className="">
            <div className={`font-semibold ${selectedMethod === 1 ? 'text-violet-600' : 'text-gray-400'} `}>Tarjetas de crédito</div>
            <div className={`${selectedMethod === 1 ? 'text-violet-600' : 'text-gray-400'}`}>VISA, Mastercard, American</div>
          </div>
        </div>
        <div className={`border-[1px] ${selectedMethod === 1 ? 'border-violet-600' : 'border-gray-400'} rounded-full p-2`}>
          <div className={`w-2 h-2 rounded-full ${selectedMethod === 1 ? 'bg-violet-600' : 'bg-gray-400'}`}></div>
        </div>
      </div>

      <div className={`
        flex
        justify-between
        items-center
        p-4
        border-[1px]
        ${selectedMethod === 2 ? 'border-violet-600' : 'border-gray-400'}
        cursor-pointer rounded
        ${selectedMethod === 1 && showOne ? 'hidden' : ''}
        `
      }
        onClick={() => {
          handleMethodSelected(2);
        }}
      >
        <div className="flex items-center">
          <div className="mr-5">
            <img src={selectedMethod === 2 ? pseOn : pseOff} alt="" />
          </div>
          <div className="">
            <div className={`font-semibold ${selectedMethod === 2 ? 'text-violet-600' : 'text-gray-400'}`}>Cuenta de ahorros o corriente</div>
            <div className={`${selectedMethod === 2 ? 'text-violet-600' : 'text-gray-400'}`}>Bancolombia, Davivienda...</div>
          </div>
        </div>
        <div className={`border-[1px] ${selectedMethod === 2 ? 'border-violet-600' : 'border-gray-400'} rounded-full p-2`}>
          <div className={`w-2 h-2 rounded-full ${selectedMethod === 2 ? 'bg-violet-600' : 'bg-gray-400'}`}></div>
        </div>
      </div>
    </div>
  )
}
