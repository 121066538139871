import React, { useState } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es.json';
import Select from 'react-tailwindcss-select';

interface CreditCardFormProps {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  isLoading?: boolean
  setValue: any
  setCustomValue?: any
  clearErrors?: any
}

export const CreditCardForm: React.FC<CreditCardFormProps> = ({
  register,
  errors,
  isLoading,
  setCustomValue,
  clearErrors,
  setValue
}) => {

  const [documentTypes] = useState<any>([
    { value: 'CC', label: 'Cédula de ciudadanía' },
    { value: 'NIT', label: 'Nit' },
  ]);

  const [phone, setPhone] = useState('');
  const [documentType, setDocumentType] = useState<any>(null);

  const handlePhoneChange = (value: any) =>  {
    setCustomValue('phone', value);
    setPhone(value);
    clearErrors('phone');
  }

  const handleDocumentType = (val: any) => {
    setCustomValue('documentType', val.value);
    setDocumentType(val);
    clearErrors('documentType');
  }

  const [expDate, setExpDate] = useState<any>('');

  const expriy_format = (value: any) => {
    const expdate = value;
    const expDateFormatter = expdate.replace(/\//g, "").substring(0, 2) + (expdate.length > 2 ? "/" : "") + expdate.replace(/\//g, "").substring(2, 4);
    return expDateFormatter;
  };

  const onChangeExp = (e: any) => {
    setExpDate(e.target.value);
    setValue('expDate', e.target.value);
  };

  return (
    <div className="grid grid-cols-12 gap-y-2">
      <div className="flex flex-col col-span-12">
        <label htmlFor="cardNumber" className="text-gray-500 mb-1">Numero de tarjeta</label>
        <input
          {...register('cardNumber', { required: true })}
          type="text"
          id='cardNumber'
          className={`
          border-[1px]
          ${!errors.cardNumber ? 'border-gray-300' : 'border-red-500'}
          ${errors.cardNumber ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
          py-2.5
          px-4
          rounded-l-sm
          outline-none
        `} />
        {(errors.cardNumber)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-6 mr-3">
        <label htmlFor="expDate" className="text-gray-500 mb-1">Fecha de vencimiento</label>
        <input
          {...register('expDate', { required: true })}
          type="text"
          id='expDate'
          className={`
            border-[1px]
            ${!errors.expDate ? 'border-gray-300' : 'border-red-500'}
            ${errors.expDate ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
            py-2.5
            px-4
            rounded-l-sm
            outline-none
          `}
          placeholder="mm / yy"
          onChange={onChangeExp}
          value={expriy_format(expDate)}
          maxLength={5}
        />
        {(errors.expDate)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-6 ml-3">
        <label htmlFor="cvc" className="text-gray-500 mb-1">CVC</label>
        <input
          {...register('cvc', { required: true })}
          type="text"
          id='cvc'
          className={`
          border-[1px]
          ${!errors.cvc ? 'border-gray-300' : 'border-red-500'}
          ${errors.cvc ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
          py-2.5
          px-4
          rounded-l-sm
          outline-none
        `} />
        {(errors.cvc)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="cardHolder" className="text-gray-500 mb-1">Nombre en la tarjeta</label>
        <input
          {...register('cardHolder', { required: true })}
          type="text"
          id='cardHolder'
          className={`
          border-[1px]
          ${!errors.cardHolder ? 'border-gray-300' : 'border-red-500'}
          ${errors.cardHolder ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
          py-2.5
          px-4
          rounded-l-sm
          outline-none
        `} />
        {(errors.cardHolder)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="address" className="text-gray-500 mb-1">Dirección de domicilio</label>
        <input
          {...register('address', { required: true })}
          type="text"
          id='address'
          className={`
          border-[1px]
          ${!errors.address ? 'border-gray-300' : 'border-red-500'}
          ${errors.address ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
          py-2.5
          px-4
          rounded-l-sm
          outline-none
        `} />
        {(errors.address)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-6">
        <label htmlFor="documentType" className="text-gray-500 mb-1">Tipo de documento*</label>
        <Select
          classNames={{
            menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${!errors.documentType ? 'border-gray-300' : 'border-red-500'} py-[2px] px-4 outline-none cursor-pointer focus:border-violet-600`,
            ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
          }}
          primaryColor='violet'
          value={documentType}
          loading={documentTypes.length <= 0}
          options={documentTypes}
          placeholder="Tipo de documento"
          onChange={(val: any) => {
            handleDocumentType(val)
          }}
        />
        {(errors.documentType)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-6">
        <label htmlFor="documentNumber" className="text-gray-500 mb-1">Número de documento*</label>
        <input
          {...register('documentNumber', { required: true })}
          type="text"
          id='documentNumber'
          className={`
          border-[1px]
          ${!errors.documentNumber ? 'border-gray-300' : 'border-red-500'}
          ${errors.documentNumber ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
          py-2.5
          px-4
          rounded-l-sm
          outline-none
        `} />
        {(errors.documentNumber)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="phone" className="text-gray-500 mb-1">Teléfono*</label>
        <PhoneInput
          className={`
            border-[1px]
            py-3
            px-4

            ${errors.phone ? 'border-red-500' : 'border-gray-300'}
          `}
          international
          countryCallingCodeEditable={false}
          defaultCountry='CO'
          labels={es}
          placeholder="Teléfono"
          value={phone}
          onChange={(value: any) => {
            handlePhoneChange(value);
          }}
        />
        {(errors.phone)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
      </div>
      <div className="flex flex-col col-span-12">
        <label htmlFor="email" className="text-gray-500 mb-1">Correo electrónico*</label>
        <input
          {...register('email', {
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Debe ser un correo válido",
            },
          })}
          type="email"
          id='email'
          className={`
          border-[1px]
          ${!errors.email ? 'border-gray-300' : 'border-red-500'}
          ${errors.email ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
          py-2.5
          px-4
          rounded-l-sm
          outline-none
        `} />
        {(errors.email)?.type === 'required' && <p className="text-rose-500 text-sm m-0">Este campo es requerido</p>}
        {(errors.email?.type === 'pattern') && <p className="text-rose-500 text-sm m-0">Debe ser un correo válido</p>}
      </div>

    </div>
  )
}
