import React, { useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Input } from './Input';
import { Checkbox } from './Checkbox';
import { Button } from './Button';
import { urls } from "../../../helpers/urls";
import axios from 'axios';
import toast from 'react-hot-toast';

interface CreditCardFormProps {
  showForm: () => void;
}

export const CreditCardForm: React.FC<CreditCardFormProps> = ({showForm}) => {

  const [ isLoading, setIsLoading ] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {
      errors,
    }
  } = useForm<FieldValues>({
    defaultValues: {
      cardNumber: '',
      cardHolder: '',
      expDate: '',
      cvc: '',
      automaticDebit: false
    }
  })

  const onSubmit: SubmitHandler<FieldValues> = (async(data) => {
    setIsLoading(true)
    const expMonth = data.expDate.split('/')[0]
    const expYear = data.expDate.split('/')[1]
    delete data.expDate
    // TODO: Move request to service
    try {
      const response = await axios.post(`${urls?.URL}payment/payment-sources/create`,
        {
          ...data,
          expMonth,
          expYear
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") ?? ''}`,
            'Content-Type': 'application/json',
          },
        });
      toast.success(response.data.message);
      showForm();
      return response.data.data;
    } catch(error) {
      console.error(error);
      toast.error('Error al guardar la fuente de pago')
      throw new Error('Error al guardar la fuente de pago');
    } finally {
      setIsLoading(false)
    }
  })

  return (
    <div className="bg-white rounded-md w-full md:w-3/4 xl:w-2/4 py-4 px-10 max-w-2xl">
      <div className="flex items-center gap-3">
        <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="45" height="43" rx="5" fill="#FFDDA9"/>
          <path d="M13 21C13 17.2288 13 15.3431 14.1716 14.1716C15.3432 13 17.2288 13 21 13H25C28.7712 13 30.6569 13 31.8284 14.1716C33 15.3431 33 17.2288 33 21C33 24.7712 33 26.6569 31.8284 27.8284C30.6569 29 28.7712 29 25 29H21C17.2288 29 15.3432 29 14.1716 27.8284C13 26.6569 13 24.7712 13 21Z" stroke="black" strokeWidth="1.5"/>
          <path d="M21 25H17" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
          <path d="M25 25H23.5" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
          <path d="M13 19H33" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>

        <h2 className="font-bold text-lg">Agregar método de pago</h2>
      </div>

      <div className="grid gap-3 grid-cols-8 mt-4 w-full xl:w-2/3 mx-auto">
        <div className="col-span-8">
          <Input
            id="cardHolder"
            label="Nombre en la tarjeta"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
          />
        </div>

        <div className="col-span-8">
          <Input
            id="cardNumber"
            label="Número de la tarjeta"
            disabled={isLoading}
            register={register}
            errors={errors}
            maxLength={19}
            type='number'
            required
          />
        </div>

        <div className="col-span-5">
          <Input
            id="expDate"
            label="Fecha de expiración"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
          />
        </div>

        <div className="col-span-3">
          <Input
            id="cvc"
            label="CVC"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
          />
        </div>
        <div className="col-span-8">
          <Checkbox
            id={'automaticDebit'}
            required={false}
            label={'Guardar datos de tarjeta de crédito para débito automático'}
            register={register}
            errors={errors}
          />
        </div>

        <div className="col-span-8 md:col-span-6 md:col-start-2">
          <Button label='Agregar método de pago' disabled={isLoading} onSubmit={handleSubmit(onSubmit)} />
        </div>
      </div>

      <div>
      </div>
    </div>
  )
}