import React, { useState } from 'react'
import { Input } from './Input'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

interface CreditCardPaymentFormProps {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  isLoading?: boolean
  setValue: any
  resetShowForms: () => void
}

export const CreditCardPaymentForm: React.FC<CreditCardPaymentFormProps> = ({
  register,
  errors,
  isLoading,
  setValue,
  resetShowForms
}) => {

  const [expDate, setExpDate] = useState<any>('');

  const expriy_format = (value: any) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  const onChangeExp = (e: any) => {
    setExpDate(e.target.value);
    setValue('expDate', e.target.value);
  };

  return (
    <div className="bg-white rounded-md w-full md:w-3/4 xl:w-2/4 py-4 px-10 max-w-2xl">
      <div className="flex items-center gap-3">
        <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="45" height="43" rx="5" fill="#A9DBFF" />
          <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
          <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
        <h2 className="font-bold text-lg">Pago con tarjeta de crédito</h2>
      </div>

      <div className="grid gap-3 grid-cols-8 mt-4 w-full xl:w-2/3 mx-auto">
        <div className="col-span-8">
          <Input
            id="cardHolder"
            label="Nombre en la tarjeta"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
          />
        </div>

        <div className="col-span-8">
          <Input
            id="cardNumber"
            label="Número de la tarjeta"
            disabled={isLoading}
            register={register}
            errors={errors}
            maxLength={19}
            type='number'
            required
          />
        </div>

        <div className="col-span-5">
          <input
            className={`
            w-full
            py-[10px]
            font-light
            bg-white
            border-1
            rounded-md
            outline-none
            disabled:opacity-70
            disabled:cursor-not-allowed
            pl-4
            ${errors['expDate'] ? 'border-rose-500' : 'border-neutral-300'}
            ${errors['expDate'] ? 'focus:border-rose-500' : 'focus:border-black'}
          `}
            {...register('expDate', { required: true })}
            type="text"
            name="expiry-data"
            placeholder="mm / yy"
            onChange={onChangeExp}
            value={expriy_format(expDate)}
            maxLength={5}
          />

        </div>


        <div className="col-span-3">
          <Input
            id="cvc"
            label="CVC"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
          />
        </div>

      </div>

      <div className="w-full flex justify-center">
        <button
          className="font-medium mt-4 underline"
          onClick={resetShowForms}
        >
          Regresar
        </button>
      </div>
    </div>
  )
}
