import React from 'react'
import { HistoricalItem } from './HistoricalItem'
import { HistoricalInterface } from './Interfaces'

interface HistoryListProps {
  title: string,
  historicalList: HistoricalInterface[]
}

export const HistoricalList: React.FC<HistoryListProps> = ({ title, historicalList }) => {
  return (
    <div className='animate_fadeInUp'>
      <h1 className="font-lato font-extrabold text-2xl">{title}</h1>
      <div className="h-64 overflow-y-auto px-4 mt-2 bg">
        {historicalList.map((historical, index) => (
          <HistoricalItem historical={historical} index={index} />
        ))}
      </div>
    </div>
  )
}
