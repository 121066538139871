import React, { useState, useRef, useEffect } from 'react'
import img from '../assets/img/home/img-share-code-unit-2.png'
import closeIcon from '../assets/img/home/close-white-2.svg'
import { 
  FacebookShareButton, 
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  // EmailShareButton,
  // EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { getUserData } from '../services/ownerServices';
import jwt_decode from "jwt-decode";
import { checkSessionStorageToken } from '../helpers/functions';

const ModalShareCodeUnit: React.FC = () => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const modalOverlayRef = useRef<HTMLDivElement>(null);
  const [code, setCode] = useState('');
  const [codeCopied, setCodeCopied] = useState(false);

  const getData = async (id: string) => {
    const { data } = await getUserData();
    const userCode = data.data.uniqueCode;
    setCode(userCode);
  }

  const copyTextToClipboard = () => {

    navigator.clipboard.writeText(`Hola me gustaría que conocieras este increíble proyecto. Usa mi link de referido para registrarte https://www.lokl.life?code=${code}`)
    setCodeCopied(true);
    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);

  }

  useEffect(() => {
    
    const token = localStorage.getItem("token") ?? '';
    const decoded: any = jwt_decode(token);
    
    if(checkSessionStorageToken()){
      getData(decoded.id);
    }

    setTimeout(() => {
      setIsOpen(true);
    }, 3000);

  }, []);

  return (

    <div className={`fixed top-0 left-0 right-0 bottom-0 z-[100] bg-black/30 bg-opacity-75 ${ isOpen === true ? 'flex' : 'hidden' } items-center justify-center`} ref={modalOverlayRef}>
      <div className="relative w-96 bg-white rounded-xl p-6 flex flex-col justify-between items-center" ref={modalRef}>

        <img onClick={() => setIsOpen(false)} width={12} height={12} className='absolute -top-5 -right-0 lg:-right-[7%] cursor-pointer' src={closeIcon} alt="close" />

        <div className="font-lato text-xl font-bold text-center mb-2">
          Sabemos que las mejores recomendaciones vienen de amigos.
        </div>
        
        <div className="w-[94%] font-roboto text-base text-center mb-8">
          Refiere a tus amigos  <br /> <span className='text-[#3533FF] font-roboto text-xl font-bold'>¡Te obsequiamos 1UNIT!</span>
        </div>

        <div className="flex justify-between items-center w-[92%] border p-2 rounded-full mb-3">

          <div className="text-gray-400 text-sm">www.lokl.life?code={code}</div>

          <div
            className="bg-[#3533FF] text-white text-sm rounded-full py-2 px-3 cursor-pointer"
            onClick={() => copyTextToClipboard()}
          >
            {codeCopied ? 'Copiado!' : 'Compartir'}
          </div>

        </div>

        <div className="w-[92%] mb-4 flex justify-center gap-3">

          {/* <EmailShareButton
            url={`https://www.lokl.life?code=${code}`}
          >
            <EmailIcon size={35} round />
          </EmailShareButton> */}

          <FacebookShareButton
            url={`https://www.lokl.life?code=${code}`}
            quote={'Me gusta invertir en lokl'}
            hashtag="#lokl"
          >
            <FacebookIcon size={35} round />
          </FacebookShareButton>

          <TwitterShareButton
            url={`https://www.lokl.life?code=${code}`}
          >
            <TwitterIcon size={35} round />
          </TwitterShareButton>

          <WhatsappShareButton
            url={`https://www.lokl.life?code=${code}`}
          >
            <WhatsappIcon size={35} round />
          </WhatsappShareButton>

          <TelegramShareButton
            url={`https://www.lokl.life?code=${code}`}
          >
            <TelegramIcon size={35} round />
          </TelegramShareButton>

          <LinkedinShareButton
            url={`https://www.lokl.life?code=${code}`}
          >
            <LinkedinIcon size={35} round />
          </LinkedinShareButton>

        </div>

        <img className='rounded-xl mb-2' src={img} alt="pop-growup-unit" />


      </div>
    </div>

  )
}

export default ModalShareCodeUnit;