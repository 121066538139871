import axios from "axios"
import { urls } from "../helpers/urls";


const getToken = () => localStorage.getItem("token") ?? '';

export const validateCoupon = async(couponCode: String)=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}coupon/validate/${couponCode}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    console.log(error)
  }
}