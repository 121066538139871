import axios from "axios";
import { urls } from "../helpers/urls";

// variable que devuelve el token
const getToken = () => localStorage.getItem("token");

/**
 *
 * @param id
 * @param data
 * @returns
 *
 * Funcion para actulizar los datos del usuario
 *
 */
export const updateOwnerData = async (id: any, data: any) => {
  const newOwnerData = data;
  try {
    const response = await axios.put(`${urls.URL}owners/${id}`, newOwnerData, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const getOwnerContract = async (value: any) => {

  const idProject = sessionStorage.getItem('id_project');

  try {
    let token = getToken();
    if (!token && process.env.REACT_APP_LOCAL) {
      token = await getUser();
    }

    const response = await axios.get(`${urls.URL}contract/${value}/${idProject}`, {
      headers: {
        Authorization: `Bearer ${
          token
            ? token
            : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNmE1Y2U4YWMzMGJkZTUyOGE2ZmFhZCIsImVtYWlsIjoid2lsbGlhbUBsb2tsLmxpZmUiLCJleHAiOjE2NjgxMTA0MjcsImlhdCI6MTY2ODEwNjgyN30.m-L7S_dGo7-gBXRoC4giObN3xm1ipYmLPvCgRygOOTs"
        }`,
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(
    //   "🚀 ~ file: ownerServices.tsx ~ line 49 ~ getOwnerContract ~ response",
    //   response
    // );

    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getOwnerContractStatus = async () => {
  try {
    let token = getToken();
    if (!token && process.env.REACT_APP_LOCAL) {
      token = await getUser();
    }

    const response = await axios.get(`${urls.URL}statuscontract/`, {
      headers: {
        Authorization: `Bearer ${
          token
            ? token
            : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNmE1Y2U4YWMzMGJkZTUyOGE2ZmFhZCIsImVtYWlsIjoid2lsbGlhbUBsb2tsLmxpZmUiLCJleHAiOjE2NjgxMTA0MjcsImlhdCI6MTY2ODEwNjgyN30.m-L7S_dGo7-gBXRoC4giObN3xm1ipYmLPvCgRygOOTs"
        }`,
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(
    //   "🚀 ~ file: ownerServices.tsx ~ line 49 ~ getOwnerContract ~ response",
    //   response
    // );

    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getOwnerContractDoc = async (code: any) => {
  try {
    const response = await axios.get(`https://api.auco.ai/document?code=${code}`, {
      headers: {
        Authorization: `puk_AEDQSK73sXdSnSfOzgZ1l2xC76T6qxJq`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getUser = async () => {
  try {
    let token: any = "";
    if (!getToken()) {
      const data = {
        email: "william@lokl.life",
        password: "Lokl2022*",
      };

      const loginR = await axios.post(`${urls.URL_API}owners/login`, data);
      token = loginR.data.token;
      console.log(
        "🚀 ~ file: ownerServices.tsx ~ line 86 ~ getUser ~ token",
        token
      );
      sessionStorage.setItem("token", token);
    } else token = getToken();

    return token;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getOwnerInvertment = (idProject: string | undefined) => {
  var config = {
    method: "get",
    url: "",
  };
  let params = {
    ...config,
    url: `${urls.URL}/owners-investment/${idProject}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios(params);
};

export const getLastIdProject = async () => {

  try {
    
    const response = await axios.get(`${urls.URL}properties/marketables`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

};

export const saveUrlContract = async (url: any, idProject: any, reference: any) => {
  
  const data = {
    url,
    id: idProject,
    reference,
  };

  try {

    const response = await axios.post(`${urls.URL}saveUrlContract/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

};
export const saveIdentityVerification = async (processId: string, status: string, backgroundCheckScore: number) => {
  const data = {
    processId,
    status,
    score: backgroundCheckScore
  };
  try {
    const response = await axios.post(`${urls.NEW_API_URL}identityValidation`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const completeOwnerData = async (
    firstName: string, 
    lastName: string, 
    documentType: string, 
    documentNumber: string, 
    birthDate: string, 
    address: string, 
    phone: string,
    countryPhoneCode: string,
    state: string,
    city: string
  ) => {
  const data = {
    firstName,
    lastName,
    documentType,
    documentNumber,
    birthDate,
    address,
    phone,
    countryPhoneCode,
    state,
    city
  };

  try {
    const response = await axios.patch(`${urls.NEW_API_URL}/user`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error: any) {
    throw error;
  } 
}

export const getUserData = async (id: string = ''): Promise<any> => {
  try {

    const response = await axios.get(`${urls.NEW_API_URL}user`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

};

export const changePassword = async (ownerId: any, password: any, token: any) => {
  try {
    const response = await axios.put(
      `${urls.URL}/owners/${ownerId}`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};

interface SignContractProps {
  first_name: string,
  last_name: string,
  document_type: string,
  document_number: number,
  document_date: string,
  document_from: string,
  address: string,
  phone: number,
  reference_pay: string,
  state: string,
  city: string,
  document_front: any,
  document_back: any,
  referral_code: any
}

interface CodeContractProps {
  code: string
}

export const signContract = async (flag: string, data: SignContractProps | CodeContractProps) => {
  try {
    const response = await axios.post(
      `${urls.URL}sign-contract/${flag}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const verifySignContract = async (reference_pay: string) => {
  try {
    const response = await axios.post(
      `${urls.URL}verify-sign-contract/`,
      reference_pay,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getLastInvestments = async () => {
  try {
    const response = await axios.get(`${urls.URL}lastInvestments`)
    return response
  } catch (error: any) {
    throw error.response.data;
  }
}

