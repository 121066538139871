import { FieldErrors, FieldValues, UseFormRegister, ValidationRule } from "react-hook-form"

interface InputProps {
  id: string
  label: string
  type?: string
  disabled?: boolean
  required?: boolean
  pattern?: ValidationRule<RegExp>
  register: UseFormRegister<FieldValues>
  errors: FieldErrors,
  requireErrorMessage?: string
  patternErrorMessage?: string
  touchedFields: any
}
const Input: React.FC<InputProps> = ({
  id,
  label,
  type = 'text',
  disabled,
  required,
  pattern,
  requireErrorMessage = "Este campo es requerido",
  patternErrorMessage = "Este campo debe ser válido",
  touchedFields,
  register,
  errors
}) => {
  return (
    <div className="w-full relative">
      <input
        id={id}
        disabled={disabled}
        {...register(id, { required, pattern })}
        placeholder={label}
        type={type}
        className={`
          peer
          w-full
          px-4
          py-2
          bg-white
          border-[1px]
          rounded-md
          outline-none
          transition
          disabled:opacity-70
          disabled:cursor-not-allowed
          ${!errors[id] && touchedFields[id] ? 'border-green-500' : errors[id] ? 'border-rose-500' : 'border-neutral-300'}
          ${errors[id] ? 'focus:border-rose-500' : 'focus:border-[#4743E0]'}
        `}
      />
      {(errors[id])?.type === 'required' && <p className="text-rose-500 text-sm m-0" id={`required-warning-${id}`}>{requireErrorMessage}</p>}
      {(errors[id]?.type === 'pattern') && <p className="text-rose-500 text-sm m-0" id={`pattern-warning-${id}`}>{patternErrorMessage}</p>}
    </div>
  )
}

export default Input