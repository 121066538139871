import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { resetPassword } from '../../services/authService';

type ResetValues = {
  email: string,
}

type msgValues = {
  type: 'error' | 'success',
  msg: string,
}

function Reset() {

  // states
  const [btnActive, setBtnActive] = useState(false);
  const [alertMsg, setAlertMsg] = useState<msgValues>({
    type: 'error',
    msg: ''
  });
  // variables from useForm
  const {
    register,
    handleSubmit,
    // setValue,
    formState: { errors },
    // setError,
    // clearErrors,
  } = useForm<ResetValues>();

  const redirectRegister = () => {

    const url = new URL(window.location.href);
    console.log('url data', url.search);
    return url.search;

  }

  /**
   * Se ejecuta al enviar hacer en boton de iniciar sesión
   * o al hacer enter en algún compo
   * 
   * @param data 
   * @param event 
   */
  const onSubmit: SubmitHandler<ResetValues> = (data, event) => {

    setBtnActive(true);

    resetPassword(data.email)
    .then(res => {
      if(res.data){

        setBtnActive(false);
        setAlertMsg({
          type: 'success',
          msg: res?.data?.message
        });
        setTimeout(() => {
          setAlertMsg({
            ...alertMsg,
            msg: ''
          })
        }, 5000);

      }
    })
    .catch(err => {

      setBtnActive(false);
      
      if(typeof err?.message === "string"){
        
        setAlertMsg({
          type: 'error',
          msg: err?.message
        })
        setTimeout(() => {
          setAlertMsg({
            ...alertMsg,
            msg: ''
          })
        }, 5000);
      
      }else{

        setAlertMsg({
          type: 'error',
          msg: 'Ha ocurrido un error en plataforma, contacte a atención al cliente.'
        })
        setTimeout(() => {
          setAlertMsg({
            ...alertMsg,
            msg: ''
          })
        }, 5000);

      }

    });

  }

  return (
    <div className='py-40'>

      <div className="w-full flex justify-center items-center">

        <div className="max-w-[360px] lg:border-[1px] border-gray-200 rounded px-14 pt-10">

          <div className="mb-14">
            <a className="no-underline hover:text-black" href='/'>Volver</a>
          </div>


          <div className="font-black font-lato text-3xl mb-3">
            Olvidaste tu contraseña
          </div>

          <div className="font-noto text-base text-gray-500 mb-16">

            <div className="">Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.</div>   
            
            { alertMsg.msg !== '' && <div className={alertMsg.type === 'error' ? 'text-red-400' : 'text-black'}>{alertMsg.msg}</div> }
          
          </div>


          <form id="formResetPass" className='mb-16' onSubmit={handleSubmit(onSubmit)}>

            <div className="text-sm">Ingresa tu correo</div>

            <input 
              className={`w-full h-[50px] border-[1px] ${ !errors.email ? 'border-gray-300' : 'border-red-500' } py-3 px-4 rounded-sm outline-none focus:border-violet-600`} 
              type="email" 
              placeholder='Correo'
              {...register("email", {required: true})}
            />

          </form>

          <div className="flex justify-center items-center mb-14">

            <button 
              id="btnResetPass" 
              form='formResetPass' 
              className="btn-login"
              disabled={btnActive}
            >
              Enviar correo
            </button>

          </div>

          <div className="w-full justify-center items-center text-center mb-16">

            <div className="text-gray-300">¿No tienes una cuenta?</div>
            <a id="redirectRegister" className='text-violet-lokl no-underline' href={`/register${redirectRegister()}`}>Registrate aquí</a>

          </div>
        
        </div>

      </div>

    </div>
  )
}

export default Reset;