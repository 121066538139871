import React, { useState, useEffect, useRef } from 'react'
import closeIcon from '../assets/img/home/close-white-2.svg'
import { on } from '../helpers/events';
// import { getNameEmail } from '../helpers/functions';
// import { signContract } from '../services/ownerServices';
// import loklProfile from '../assets/img/lokl-profile.png';

const ModalContract: React.FC = () => {
  
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const modalOverlayRef = useRef<HTMLDivElement>(null);
  
  const [errorMsg, setErrorMsg] = useState<boolean>(false);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    console.log(errorMsg)
    on('openModalContract', (data: any) => {
      setIsOpen(true);
      console.log('data contrat', data.detail.error);
      setSignUrl(data.detail.signUrl)
      setErrorMsg(data.detail.error);
    });

    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        modalOverlayRef.current &&
        !modalOverlayRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  
    // eslint-disable-next-line
  }, []);

  const [signUrl, setSignUrl] = useState('')

  return (

    isOpen === true ?
    

    <div className={`fixed top-0 left-0 right-0 bottom-0 z-[100] bg-[#A7A6FF]/75 bg-opacity-75 ${ isOpen === true ? 'flex' : 'hidden' } items-center justify-center`} ref={modalOverlayRef}>
      <div className="relative w-full h-[90vh] md:w-11/12 lg:4/5 bg-white rounded-xl p-6 flex flex-col justify-center items-center" ref={modalRef}>
        <img onClick={() => {
            setIsOpen(false);
            sessionStorage.setItem("modalIsClose", 'true');
          }}
          width={12} height={12} className='absolute -top-5 -right-0 lg:-right-[7%] cursor-pointer' src={closeIcon} alt="close" />
        {
          signUrl && <iframe src={signUrl} title="eSign" width="100%" height="800px" allow="camera"></iframe>
        }
      
        <button onClick={() => setIsOpen(false)}>Test button</button>
      </div>
    </div>

    : null

  )
}

export default ModalContract;