// Price in $1.000.000 format
export const numberToCurrencyFormat = (number: any) => {
  number = Number(number)
  let currencyFormat = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
  }).format(number);

  return currencyFormat;

}