import React, { useEffect, useState } from 'react'
import { PaymentSummary } from './PaymentSummary'
import { PsePaymentForm } from './PsePaymentForm'
import { payInstallment } from '../../../services/paymentDataServices';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { scrollToSection } from '../../../helpers/functions';
import { getUserData } from '../../../services/ownerServices';
import { parsePhoneNumber } from 'react-phone-number-input'
import { urls } from '../../../helpers/urls';

const PSE_FEE_PERCENTAGE = 0;

interface PsePaymentProps {
  resetShowForms: () => void
}

export const PsePayment: React.FC<PsePaymentProps> = ({
  resetShowForms
}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ nextPayment, setNextPayment ] = useState<any>({})
  const [ totalMonths, setTotalMonths ] = useState<any>()
  const [ totalInvestment, setTotalInvestment ] = useState<any>()
  const [ totalToPay, setTotalToPay ] = useState<number>(0)
  const [ installmentId, setInstallmentId ] = useState<string>('')
  const [ user, setUser ] = useState<any>()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
    setValue,
    setError,
    clearErrors
  } = useForm<FieldValues>({
    defaultValues: {
      financialInstitutionCode: '',
      userType: '',
      documentType: '',
      documentNumber: '',
      customerEmail: '',
      phone: ''
    }
  })

  useEffect(() => {
    // Set values from localStorage
    let nextPayment = localStorage.getItem('nextPayment')
    let totalMonths = localStorage.getItem('installmentsCount')
    let totalInvestment = localStorage.getItem('totalInvestment')
    const nextPaymentInJson = JSON.parse(nextPayment!)
    setTotalMonths(totalMonths)
    setNextPayment(nextPaymentInJson)
    setTotalInvestment(totalInvestment)
    setInstallmentId(nextPaymentInJson.id)
    getUserData().then((response) => {
      setUser(response.data.data)
    })
  }, [])

  useEffect(() => {
    setTotalToPay(Math.round(Number(nextPayment?.value + (nextPayment?.value * PSE_FEE_PERCENTAGE))))
  }, [nextPayment])

  const onSubmit: SubmitHandler<FieldValues> = (async(data) => {
    console.log(data.financialInstitutionCode)
    if(!data.financialInstitutionCode) {
      setError('financialInstitutionCode', {type: 'required'});
      return
    }
    if(!data.userType) {
      setError('userType', {type: 'required'});
      return
    }
    if(!data.documentType) {
      setError('documentType', {type: 'required'});
      return
    }
    if(!data.phone) {
      setError('phone', {type: 'required'});
    }

    const phoneNumberFormatted = parsePhoneNumber(data.phone)
    const countryPhoneCode = phoneNumberFormatted?.countryCallingCode
    const phone = phoneNumberFormatted?.nationalNumber

    const body = {
      financialInstitutionCode: data.financialInstitutionCode,
      redirectUrl: `${urls.URL_FRONT}payment/successful`,
      customerFullName: `${user.firstName} ${user.lastName}`,
      customerEmail: user.email,
      paymentMethod: 'PSE',
      installmentId: installmentId,
      customerUserType: data.userType,
      customerDocumentType: data.documentType,
      customerDocumentNumber: data.documentNumber,
      customerCountryPhoneCode: countryPhoneCode,
      customerPhoneNumber: phone,
    }
    try {
      setIsLoading(true);
      const response = await payInstallment(body);
      if(response?.data?.data?.payment_method && response?.data?.data?.payment_method.extra.async_payment_url) {
        window.location.href = response?.data?.data.payment_method.extra.async_payment_url;
      } else {
        setIsLoading(false)
        toast.error('No se pudo redireccionar a PSE', { duration: 5000})
        resetShowForms();
      }
    } catch (error: any) {
      setIsLoading(false)
      const errorMessage = error?.response?.data.message || ''
      const errorData = error?.response?.data?.data || ''
      toast.error(`${errorMessage}.\n\n${errorData}`, { duration: 5000})
      resetShowForms();
    }
  })

  const setCustomValue = (id: string, value: any, required: boolean) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  useEffect(() => {
    scrollToSection('pse-payment', 70)
  }, [])
  
  return (
    <div className="w-full flex gap-4 py-4 flex-col md:flex-row" id="pse-payment">
      <PsePaymentForm
        register={register}
        errors={errors}
        setCustomValue={setCustomValue}
        clearErrors={clearErrors}
        resetShowForms={resetShowForms}
      />
      <PaymentSummary
        total={totalToPay}
        totalMonths={totalMonths}
        subTotal={Number(nextPayment?.value)}
        totalInvestment={Number(totalInvestment)}
        fees={Number(nextPayment?.value * PSE_FEE_PERCENTAGE)}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
      />
      
    </div>
  )
}
