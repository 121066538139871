import React from 'react';

const CircularBar: React.FC<{ percentage: number }> = ({ percentage }) => {
  const strokeWidth = 7; // Width of the circular bar stroke
  const radius = 65; // Radius of the circular bar
  const normalizedRadius = radius - strokeWidth;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  const gradientId = 'gradient'; // Unique ID for the gradient

  return (
    <svg height={radius * 2} width={radius * 2}>
      <g transform={`rotate(85 ${radius} ${radius})`}>
        <circle
          className="stroke-current text-[#B8CCFF]"
          stroke={`url(#${gradientId})`}
          strokeDasharray={`${circumference + 10} ${circumference + 10}`}
          style={{ strokeDashoffset }}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeLinecap="round" // Rounded tip
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </g>
    </svg>
  );
};

export default CircularBar;
