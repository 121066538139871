import React from 'react';
import './mobile-menu.scss';

import logo from "../../assets/img/logo-white.svg";
import close from "../../assets/img/close-outline.svg";
import { Link, useNavigate } from 'react-router-dom';
import { checkSessionStorageToken, scrollToSection } from '../../helpers/functions';

function MenuMobile({showProp, setShow}:{showProp: boolean, setShow: any}) {

  const navigate = useNavigate();

  const authed = checkSessionStorageToken();

  const goToStreamsHome = () => {
    setShow(false);
    const url = window.location.pathname;
    if (url !== "/"){
      navigate("/#streams")
    }
    scrollToSection('streams', 160);
  }

  const goToSuscriptionNido = () => {
    setShow(false);
    if (authed === true){
      window.location.href = "/nido#simulator"
    }else{
      window.location.href = "/login?redirect_to=/nido#simulator"
    }
  }

  const goToSimulatorHome = () => {
    setShow(false);
    const url = window.location.pathname;
    if (url !== "/"){
      navigate("/#simulator")
    }
    scrollToSection('simulator', 140);
  }

  return showProp === true ? (
    <div className="mobile-menu">
      <div className="navbar-menu">

        <div className="w-20">
          {/* <a href='/nido' >¡Invierte <br /> ahora!</a> */}
        </div>

        <div className="mobile-logo">
          <img src={logo} alt="lokl-logo" />
        </div>
        
        <div onClick={() => setShow(false) }>
          <img src={close} alt="mobile-avatar"  className=''/>
        </div>
      
      </div>

      <div className="desc-menu mt-20 mx-[50px]">

        <div className="big-titles">

          <div className="title" onClick={() => setShow(false)}><Link to="/dashboard">Mis inversiones</Link></div>
          <div className="title" onClick={() => goToSimulatorHome()}>Simulador</div>
          <div className="title" onClick={() => goToSuscriptionNido()}>Modelo de suscripción</div>
          <div className="title" onClick={() => goToStreamsHome()}>Webinars</div>

        </div>
        
        <div className="titles">

          {/* <div className="title"><a href="https://lokl.life/policy" >Política de privacidad</a></div> */}
          {/* <div className="title"><a href="https://lokl.life/policy">Términos y condiciones</a></div> */}
          <div 
            className="title"

          >
            <a href="/register">Únete a LOKL</a>
          </div>

        </div>
        
      </div>
      <div className="info">
        Sé parte de una comunidad, crece tu patrimonio y construye tu futuro con LOKL
      </div>

    </div>
  ) : null
}

export default MenuMobile;
