import React from 'react'

export interface CheckboxValue {
  name: any;
  terms: boolean;
  setTerms: (terms: boolean) => void;
}

function Checkbox({name, terms, setTerms}:CheckboxValue) {

  return (
    <div>
      
      {/* checkbox de terminos y condiciones */}

      <div className="flex items-start">

        <div className="mt-0.5 cursor-pointer">

          {

            terms === false ?

            <div onClick={() => setTerms(true)} className="w-[16px] h-[16px] m-[2px] mr-2 border-[1px] border-black rounded-sm"></div>

            :

            <div onClick={() => setTerms(false)} className="mr-[5px]">
              
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.625 1.875H4.375C3.71218 1.87572 3.07672 2.13935 2.60803 2.60803C2.13935 3.07672 1.87572 3.71218 1.875 4.375V15.625C1.87572 16.2878 2.13935 16.9233 2.60803 17.392C3.07672 17.8607 3.71218 18.1243 4.375 18.125H15.625C16.2878 18.1243 16.9233 17.8607 17.392 17.392C17.8607 16.9233 18.1243 16.2878 18.125 15.625V4.375C18.1243 3.71218 17.8607 3.07672 17.392 2.60803C16.9233 2.13935 16.2878 1.87572 15.625 1.875ZM14.2285 7.27695L8.97852 13.527C8.92093 13.5955 8.84927 13.651 8.76839 13.6894C8.68752 13.7279 8.59932 13.7486 8.50977 13.75H8.49922C8.41162 13.75 8.325 13.7315 8.24499 13.6959C8.16497 13.6602 8.09335 13.6081 8.03477 13.543L5.78477 11.043C5.72762 10.9824 5.68317 10.9109 5.65403 10.8329C5.62488 10.7549 5.61162 10.6718 5.61504 10.5886C5.61846 10.5054 5.63848 10.4237 5.67392 10.3483C5.70936 10.2729 5.75952 10.2054 5.82143 10.1496C5.88335 10.0939 5.95578 10.0511 6.03447 10.0238C6.11315 9.99648 6.19651 9.98516 6.27963 9.99051C6.36276 9.99585 6.44398 10.0178 6.51851 10.0549C6.59305 10.0921 6.6594 10.1438 6.71367 10.207L8.48281 12.1727L13.2715 6.47305C13.3789 6.34886 13.5309 6.27193 13.6945 6.25889C13.8582 6.24584 14.0205 6.29774 14.1462 6.40335C14.2719 6.50896 14.351 6.6598 14.3664 6.82327C14.3818 6.98675 14.3323 7.14971 14.2285 7.27695Z" fill="black"/>
              </svg>

            </div>

          }

        </div>

        <div className="cursor-pointer text-gray-500">
          
          {name}
        
        </div>

      </div>

    </div>
  )
}

export default Checkbox;