import React from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

interface InputProps {
  id: string
  label: string
  type?: string
  disabled?: boolean
  formatPrice?: boolean
  required?: boolean
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  maxLength?: number
  minLength?: number
  accept?: string
}

export const Input: React.FC<InputProps> = ({
  id,
  label,
  type = 'text',
  disabled,
  formatPrice,
  required,
  register,
  errors,
  maxLength = 80,
  minLength,
  accept = ''
}) => {
  return (
    <div className="w-full relative">
      <input
        accept={accept}
        maxLength={maxLength}
        minLength={minLength}
        id={id}
        disabled={disabled}
        {...register(id, { required, maxLength, minLength })}
        placeholder={label}
        type={type}
        className={`
          w-full
          py-[10px]
          font-light
          bg-white
          border-1
          rounded-md
          outline-none
          disabled:opacity-70
          disabled:cursor-not-allowed
          pl-4
          ${errors[id] ? 'border-rose-500' : 'border-neutral-300'}
          ${errors[id] ? 'focus:border-rose-500' : 'focus:border-black'}
        `}
      />
    </div>
  )
}