import axios from "axios"
import { urls } from "../helpers/urls";

const zapSignToken = process.env.REACT_APP_ZAPSIGN_API_TOKEN;

const getToken = () => localStorage.getItem("token") ?? '';

export const getTemplateList = async() => {
  try {
    const result = await axios.get(`https://api.zapsign.com.br/api/v1/templates/?page=1/`, {
      headers: {
        'Authorization': `Bearer ${zapSignToken}`
      }
    })
    console.log(result)
  } catch (error) {
      console.log(error)
  }
}

export const createDocumentFromTemplate = async(data: any)=> {
  try {
    const body = {
      cartId: data.cartId,
    }
    const result = await axios.post(`${urls.NEW_API_URL}contract`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getContractStatus = async(documentToken: string)=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}contract/contractDetail/${documentToken}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    
  }
}

export const getUserContracts = async()=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}contract/user`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const saveContractFile = async(data: any)=> {
  try {
    const result = await axios.post(`${urls.NEW_API_URL}contract/saveContractFile`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    console.log(error)
    throw error
  }
}