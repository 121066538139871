import React from 'react'
import { Button } from './Button'
import { numberWithCommas } from '../../../helpers/functions'
import moment from 'moment'

interface NextPaymentProps {
  date: string
  amount: number,
  setInstallment: () => void
}

export const NextPayment: React.FC<NextPaymentProps> = ({
  date,
  amount,
  setInstallment
}) => {
  return (
    <div className="bg-[#54FFCC] rounded-lg px-4 py-8">
      <div className="mb-2">
        <p className="text-sm font-bold">Próximo pago</p>
      </div>
      <div className="flex items-center justify-between flex-col md:flex-row gap-3 md:gap-0">
        <div className="flex gap-2 items-center">
          <div className={`w-[11px] h-[11px] rounded-full bg-red-400 mr-2`}>
          </div>
          <p className="font-epilogue font-bold text-sm leading-">{moment(date).format('DD/MM/YYYY')}</p>
          <p className="font-epilogue font-bold text-sm leading-none">|</p>
          <p className="font-epilogue font-bold text-base leading-none">${numberWithCommas(amount)}</p>
        </div>
        <div className="ml-3">
          <Button label='Pagar ahora' onSubmit={setInstallment}/>
        </div>

      </div>
    </div>
  )
}
