import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import success from '../../assets/img/checkout/success.png';
import imagemb from '../../assets/img/nido/banner-pg-mb.jpg';
import share from '../../assets/img/payment/icon-hands-share.svg';
import { numberWithCommas, unitPriceMulplicated } from '../../helpers/functions';
import moment from 'moment';
import { getTransaction } from '../../services/paymentDataServices';
import { useEffectOnce } from '../../helpers/hooks';
// import jwt_decode from "jwt-decode";
import ModalShareCodeUnit from '../../components/ModalShareCodeUnits';

function Success() {

  const [transaction, setTransaction] = useState<any>({});

  const useQuery = () => new URLSearchParams(useLocation().search);
  const idTransaction = useQuery().get('id');
  const navigate = useNavigate();
  const name = sessionStorage.getItem('first_name');

  const units: any = localStorage.getItem('investUnitAmount');

  // TODO: Verify if is possible to improve this with react-router-dom config
  // Code to scroll to top when change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    
    try {

      const transaction = await getTransaction(idTransaction);
      
      let dataTransaction: any = {
        inversion: transaction?.data?.data?.amount_in_cents / 100,
        idtransaccion: transaction?.data?.data?.id,
        referencia: transaction?.data?.data?.reference,
        estado: transaction?.data?.data?.status,
      }

      dataTransaction['tiempo'] = transaction?.data?.data?.created_at
      
      setTransaction(dataTransaction);

      const status = transaction?.data?.data?.status;

      if(status !== "APPROVED"){
        
        navigate('/payment/error');

      }
      
    } catch (error: any) {
     
      navigate('/payment/error');

    }
  
  }

  useEffectOnce(() => {
    
    getData();

  });
  
  return (
    <>
      <div className='hidden lg:w-[1024px] lg:flex lg:space-x-16 py-20'>

        <div className="relative lg:w-1/2 lg:h-[480]">
          
          <img className='absolute' src={success} alt="" />

          <div className="flex flex-col justify-end items-center absolute w-full h-full">

            <div className="text-white font-extrabold text-4xl text-center">
             {name} Bienvenido <br /> a Nido de Agua
            </div>
            <div className="text-white text-xl text-center">
              Un bosque que te atiende de lunes <br /> a domingo 
            </div>

          </div>

        </div>

        <div className="lg:w-1/2 lg:space-y-7">

          <div className="text-xl text-green-400 font-medium">
            Transacción aprobada <br />
            Ya eres inversionista LOKL
          </div>

          <div className="text-gray-500 text-sm">
            {moment(transaction.tiempo).format('LL')} 
            <br /> 
            {moment(transaction.tiempo).format('hh:mm a')}
          </div>

          <div className="flex items-end">

            <div className="">

              <div className="font-extrabold font-lato text-3xl">
                Nido de agua
              </div>

              <div className="font-extrabold font-lato text-3xl">
                ${numberWithCommas(transaction.inversion)} /mes
              </div>

              <div className="text-gray-500 text-sm">
                Un mes único y total de inversión
              </div>

            </div>

            {/* <div className="ml-8">
              Descargar contrato
            </div> */}


          </div>

          <div className="flex divide-x-2 space-x-2 text-sm leading-tight">

            <div className="">Etapa 1</div>

            <div className="pl-2">Total: {parseFloat(units).toFixed(2)} Units</div>

            <div className="pl-2">Costo por Unit: ${unitPriceMulplicated(1)}</div>

          </div>

          <div className="w-[360px] p-4 flex border-[1px] rounded">

            <img src={share} alt="" />

            <div className="ml-5 text-sm">
            
              <div className="">Comparte con un amigo</div>
              <div className="">Obtén puntos y canjearlos por beneficios</div>
              <div className="">Próximamente</div>
            
            </div>

          </div>

          <div className="">

            <button 
              className="bg-gray-900 text-white hover:bg-gray-700 w-60 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out"
              onClick={() => navigate('/dashboard')}
            >
              Continuar
            </button>
            
          </div>
          
        </div>

      </div>

      <div className="lg:hidden relative flex justify-center h-[500px]">

        <img className="absolute" src={imagemb} alt="" />

        <div className="absolute flex flex-col justify-center items-center">

          <div className=" bg-green-lokl text-black text-center py-4 px-11 mt-20 rounded">

            <div className="">Transacción aprobada</div>
            <div className="font-bold">¡Bien hecho!</div>
            <div className="">Ya eres inversionista LOKL</div>
          
          </div>

          <div className="mt-56 mb-4">

            <div className="font-semibold text-center text-4xl text-white">
              {name} bienvenido <br />
              a Nido de Agua
            </div>

            <div className="text-white text-center">
              Un bosque que te atiende de <br /> lunes a domingo 
            </div>

          </div>

          <button 
            className="bg-white text-black hover:bg-gray-400 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => {
              navigate('/dashboard')
            }}
          >
            Comencemos
          </button>

        </div>

      </div>

      <ModalShareCodeUnit/>

    </>
  )

}

export default Success;