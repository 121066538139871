import React from 'react'

interface CreditCardItemProps {
  brand: string;
  lastFourNumbers: string;
  expMonth: string;
  expYear: string;
}

export const CreditCardItem: React.FC<CreditCardItemProps> = (
  {
    brand,
    lastFourNumbers,
    expMonth,
    expYear
  }) => {
  return (
    <div className="flex items-center justify-between my-3">
      <div className="flex items-center w-full">
        {
          brand === 'VISA' && 
          <div className="w-[45px] flex justify-center">
            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.2908 14.7746L18.418 28.2053H14.9432L17.8163 14.7746H21.2908ZM35.909 23.447L37.7378 18.4035L38.7902 23.447H35.909ZM39.7871 28.2053H43L40.1931 14.7746H37.2294C36.5615 14.7746 35.9984 15.1618 35.7492 15.7591L30.5356 28.2053H34.1847L34.9091 26.1995H39.3663L39.7871 28.2053ZM30.7166 23.8206C30.7317 20.276 25.8166 20.0797 25.8495 18.4959C25.8601 18.0147 26.3192 17.5021 27.3227 17.371C27.8202 17.3069 29.1932 17.255 30.7495 17.9721L31.3582 15.1229C30.5223 14.8205 29.4466 14.5293 28.1081 14.5293C24.6732 14.5293 22.2569 16.3539 22.2375 18.9686C22.2155 20.9023 23.9636 21.9804 25.2779 22.6244C26.6331 23.2826 27.0871 23.7046 27.0805 24.2928C27.0711 25.1939 25.9999 25.5929 25.0025 25.608C23.2546 25.6352 22.2415 25.1356 21.434 24.7592L20.8033 27.7036C21.6165 28.0757 23.1148 28.3995 24.6662 28.4162C28.3181 28.4162 30.7057 26.613 30.7166 23.8206ZM16.3258 14.7746L10.696 28.2053H7.02363L4.25297 17.4866C4.08501 16.8275 3.9385 16.5852 3.42769 16.3067C2.59214 15.853 1.21285 15.4286 0 15.1646L0.0821664 14.7746H5.99445C6.74753 14.7746 7.4251 15.2757 7.59758 16.1433L9.06115 23.9158L12.6755 14.7746H16.3258Z" fill="#1434CB"/>
          </svg>

          </div>
        }
        {
          brand === 'MASTERCARD' && 
          <div className="w-[45px] flex justify-center">
            <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.503 9.26855H13.498V26.7618H23.503V9.26855Z" fill="#FF5F00"/>
              <path d="M14.133 18.0158C14.1314 16.3311 14.5238 14.6681 15.2805 13.1527C16.0371 11.6374 17.1381 10.3093 18.5002 9.26919C16.8135 7.97918 14.7877 7.17694 12.6544 6.95416C10.5212 6.73139 8.3666 7.09707 6.43688 8.00942C4.50715 8.92176 2.88017 10.344 1.7419 12.1134C0.603633 13.8829 0 15.9283 0 18.0158C0 20.1033 0.603633 22.1487 1.7419 23.9182C2.88017 25.6877 4.50715 27.1099 6.43688 28.0222C8.3666 28.9345 10.5212 29.3002 12.6544 29.0775C14.7877 28.8547 16.8135 28.0524 18.5002 26.7624C17.1381 25.7223 16.0371 24.3943 15.2805 22.8789C14.5238 21.3635 14.1315 19.7005 14.133 18.0158Z" fill="#EB001B"/>
              <path d="M37.0001 18.0158C37.0002 20.1033 36.3967 22.1486 35.2585 23.9181C34.1203 25.6876 32.4933 27.1098 30.5636 28.0222C28.634 28.9345 26.4794 29.3002 24.3462 29.0774C22.213 28.8547 20.1872 28.0524 18.5005 26.7624C19.8614 25.7212 20.9616 24.393 21.7181 22.8779C22.4746 21.3627 22.8677 19.7003 22.8677 18.0158C22.8677 16.3313 22.4746 14.6689 21.7181 13.1538C20.9616 11.6386 19.8614 10.3104 18.5005 9.26919C20.1872 7.97917 22.213 7.17693 24.3462 6.95416C26.4794 6.73139 28.634 7.09708 30.5636 8.00943C32.4933 8.92179 34.1203 10.344 35.2585 12.1135C36.3967 13.883 37.0002 15.9283 37.0001 18.0158Z" fill="#F79E1B"/>
              <path d="M35.9093 24.9094V24.5512H36.0577V24.4783H35.6797V24.5512H35.8282V24.9094H35.9093ZM36.6431 24.9094V24.4775H36.5273L36.394 24.7746L36.2607 24.4775H36.1447V24.9094H36.2266V24.5836L36.3515 24.8645H36.4364L36.5614 24.5829V24.9094H36.6431Z" fill="#F79E1B"/>
            </svg>
          </div>
        }

        <p className="font-epilogue text-[16px] ml-8"><span className="hidden xl:inline">• • • • </span>{lastFourNumbers}</p>
        <p className="font-epilogue text-[16px] ml-16">{expMonth}/{expYear}</p>
      </div>

      <div className="rounded-full bg-[#54FFCC] px-3 py-1">
        <p className="text-[11px] font-epilogue font-semibold">Activo</p>
      </div>
    </div>
  )
}