import React from 'react'
import { Outlet } from 'react-router-dom';
import ModalContract from '../../components/ModalContract';
import ModalContractCode from '../../components/ModalContractCode';

function Payment() {

  return (
    // <div className="container px-7 lg:px-40 py-28">
    <div className="lg:flex lg:flex-col lg:justify-center lg:items-center"> 

      <div className="mx-9 lg:max-w-6xl py-28">

        <Outlet/>

      </div>

      <ModalContract/>
      <ModalContractCode/>

    </div>
  )
}

export default Payment;