import React, { ChangeEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { confirmCode } from '../../services/authService';
// import { trigger } from '../../helpers/events';
import ReCAPTCHA from "react-google-recaptcha";

// import { trigger } from '../../helpers/events';
import { Hero } from './components/Hero';
import ModalPostRegister from '../../components/ModalPostRegister';

type ConfirmationValues = {
  email: string,
  otp: string,
}

function ConfirmationCode() {

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ConfirmationValues>();

  const email: string = sessionStorage.getItem('emailConfirm') || '';

  const [btnActive, setBtnActive] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [captcha, setCaptcha] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const redirectLogin = () => {

    const url = new URL(window.location.href);
    return url.search;

  }

    /**
   * Se ejecuta al enviar hacer en boton de registrar
   * o al hacer enter en algún compo
   * 
   * @param data 
   * @param event 
   */
  const onSubmit: SubmitHandler<ConfirmationValues> = (data, event) => {

    if(captcha === true){

      setBtnActive(true);
  
      const url = new URL(window.location.href);
      const getParam = url.searchParams.get('redirect_to');
      const getHash = url.hash || '';

      confirmCode(data.email, data.otp)
      .then(res => {
        if(res.data){

          // console.log(res.data)

          sessionStorage.removeItem('emailConfirm');
          let data = res.data;
          // console.log("data", data);
          setBtnActive(false);
          if(getParam){
            if(getParam.startsWith('http://checkout.lokl.life') || getParam.startsWith('http://localhost:4200')) {
              window.location.href = getParam +  `token=${data?.data?.token}`;
            } else {
              window.location.href = getParam + getHash;
            }
          }else{
            if(res.data.code === '00') {
              setOpenModal(true)
            }
          }

        }
      })
      .catch(err => {
        console.log('error register', err.message);
        setBtnActive(false);
        setErrorMsg(err.message);
        setTimeout(() => {
          setErrorMsg('');
        }, 5000);
      });
      
    }else{
      setErrorMsg('Deber aceptar el captcha');
      setTimeout(() => {
        setErrorMsg('');
      }, 5000);
    }

  }

  /**
   * capcha
   */
  const changeCapcha = (value: any) => {
    const recaptchaValue = value;
    if (recaptchaValue) {
      setCaptcha(true);
    }
  }

  const handleInputOTP = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.slice(0, 4);
  };

  useEffect(() => {

    if(email !== ''){
      setValue('email', email)
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ModalPostRegister handleOpen={openModal} />
      <div className='flex justify-center pt-[34px] min-h-[calc(100vh-21px)]'>

        <div className="w-full lg:w-[800px] flex justify-center items-center">

          <div className="max-w-[370px] lg:max-w-[400px] px-10 pt-0 lg:pt-10 lg:mt-4">

            <div className="font-black font-lato text-3xl mb-2">
              Verificación de código
            </div>

            <div className="font-noto text-base text-gray-500 mb-9">

              <div className="">Por favor, introduce el correo y el código recibido por correo a continuación:</div>
              
              { errorMsg !== '' && <div className="text-red-400">{errorMsg}</div> }
            
            </div>


            <form id="formConfirmationCode" className='mb-3 space-y-2' onSubmit={handleSubmit(onSubmit)}>

              <div className="">

                <input 
                  className={`w-full h-[40px] border-[1px] ${ !errors.email ? 'border-gray-300' : 'border-red-500' } py-3 px-4 rounded-lg outline-none focus:border-violet-600`} 
                  type="email" 
                  placeholder='Correo electrónico'
                  {...register("email", {required: true})}
                />

                { errors.email?.type === "required" && <div className='text-red-500 text-sm'>* Algo anda mal, revisa tu correo</div> }

              </div>

              <div className="">

                <input 
                  className={`w-full h-[40px] border-[1px] ${ !errors.otp ? 'border-gray-300' : 'border-red-500' } py-3 px-4 rounded-lg outline-none focus:border-violet-600`} 
                  type="number" 
                  placeholder='Código de verificación'
                  onInput={handleInputOTP}
                  {...register("otp", {required: true})}
                />

                { errors.otp?.type === "required" && <div className='text-red-500 text-sm'>* Algo anda mal, revisa tu código</div> }

              </div>

              <ReCAPTCHA
                sitekey="6LdJ7wklAAAAAHMgAGZJul66MFfF_ujDC_vhoAJS"
                onChange={changeCapcha}
                size={'normal'}
              />

            </form>

            <div className="flex flex-col justify-between items-center mb-10">

              <button 
                id="btnConfirmationCode" 
                form='formConfirmationCode' 
                className="w-full mt-4 btn-login"
                disabled={btnActive}
                >Confirmar</button>

            </div>

            <div className="w-full justify-center items-center text-center mb-16">

              <div className="text-gray-400">¿Ya tienes una cuenta?</div>
              <a id="redirectRegister" className='text-violet-lokl no-underline' href={`/login${redirectLogin()}`}>Iniciar sesión</a>

            </div>
          
          </div>

        </div>

        <div className="hidden lg:flex items-center relative w-full">
          <Hero />

        </div>

      </div>
    </>
  )
}

export default ConfirmationCode;