import React from 'react'
import closeIcon from '../../../../assets/img/home/close-white-2.svg'

interface ContractSignModalProps {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  signUrl: string;
}

export const ContractSignModal: React.FC<ContractSignModalProps> = ({
  isOpen,
  setIsOpen,
  signUrl
}) => {
  return (
    <>
      {

        isOpen === true && signUrl ?

          <div className={`fixed top-0 left-0 right-0 bottom-0 z-[100] bg-[#A7A6FF]/75 bg-opacity-75 ${isOpen === true ? 'flex' : 'hidden'} items-center justify-center`}>
            <div className="relative w-full h-[90vh] md:w-11/12 lg:4/5 bg-white rounded-xl p-6 flex flex-col justify-center items-center">
              <img onClick={() => {
                setIsOpen(false);
                sessionStorage.setItem("modalIsClose", 'true');
              }}
                width={14} height={14} className='absolute -top-5 right-0 lg:-right-5 cursor-pointer' src={closeIcon} alt="close" />
              {
                signUrl && <iframe src={signUrl} title="eSign" width="100%" height="800px" allow="camera"></iframe>
              }

            </div>
          </div>

          : null
      }
    </>
  )
}
