import React from 'react';

interface ArticleCardProps {
    title: string;
    description: string;
    urlImagen?: string;
    reverse?: boolean;
}

export const ArticleCard: React.FC<ArticleCardProps> = ({ title, description, urlImagen, reverse = false }) => {
    return (
        <div className={`w-full gap-5 flex justify-between ${reverse ? 'flex-row-reverse' : 'flex-row'}`}>
            <div className='w-1/2 hidden md:flex'>
                <img className='' width={525} height={357} src={urlImagen} alt="imagen-lokl" />
            </div>

            <div className="w-full md:w-1/2">
                <h2 className='blog-title-md mb-12'>{title}</h2>
                <div className="flex flex-col justify-between">
                    <p className="text-base font-roboto mb-12">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
};
