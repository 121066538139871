import axios from "axios"
import { urls } from "../helpers/urls";



export const getProjects = async()=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}project`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return result?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getProjectById = async(id: string)=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}project/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return result?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}