// import React, { useEffect, useState } from 'react'
// import { SubmitHandler, useForm } from 'react-hook-form';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Select from 'react-tailwindcss-select';

// import pseOn from '../../assets/img/payment/ban-on.svg';
// import cardOn from '../../assets/img/payment/visa-on.svg';

// import banner from '../../assets/img/checkout/banner-complete.png';
// import { formatDate, generateCode, isAuthenticated, numberWithCommas } from '../../helpers/functions';
// import { useEffectOnce } from '../../helpers/hooks';
// import { createTransaction, fetchBanks } from '../../services/paymentDataServices';
// import { fetchCities, fetchRegions } from '../../services/regionsServices';
// import backIcon from "../../assets/img/checkout/back-icon.svg";
// import moment from 'moment';
// import { getLastIdProject, signContract, verifySignContract } from '../../services/ownerServices';
import { Payment } from '../Checkout/Payment';

// type FormValues = {
//   name: string;
//   region: string;
//   exp_month: string;
//   exp_year: string;
//   type_document: string;
//   number_document: number;
//   number: number;
//   numberCard: string;
//   email: string;
//   cvc: string;
//   payPerMonth: number;
//   address: string;
//   bank_code: string;
//   type_client: number;
//   installments: number;
//   type: string;
//   city: string;
// };

function Complete() {
  
  return (

    <div>
      <Payment />
    </div>
  )
}

export default Complete;