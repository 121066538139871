// import React, { createRef, useEffect, useState } from 'react'
// import { createPopper } from '@popperjs/core';
// import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
// import arrowRight from '../../assets/img/home/arrow-right.svg';
// import arrowLeft from '../../assets/img/home/arrow-left.svg';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import pseOn from '../../assets/img/payment/ban-on.svg';
// import pseOff from '../../assets/img/payment/ban-off.svg';
// import cardOn from '../../assets/img/payment/visa-on.svg';
// import cardOff from '../../assets/img/payment/visa-off.svg';
// import iconInfo from "../../assets/img/infocircle.svg";

// import banner from '../../assets/img/checkout/banner.png';
// import { isAuthenticated, numberWithCommas, unitPrice, formatDate } from '../../helpers/functions';
// import moment from 'moment';
// import { getLastIdProject } from '../../services/ownerServices';
// import PopupDisclaimerRetorno from '../../components/PopupDisclaimerRetorno';
import { InvestX } from '../Checkout/invest';

function Invest() {
  
  return (

    <div className=''>

      <div>
        <InvestX />
      </div>

    </div>
  )
}

export default Invest;