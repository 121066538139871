import axios from "axios";
import { urls } from "../helpers/urls";

// variable que devuelve el token
const getToken = () => localStorage.getItem("token");

export const createCustomer = async(data: any) => {
  try {
    const response = await axios.post(`${urls.NEW_API_URL}payouts`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}

export const createPayoutTarget = async(data: any) => {
  try {
    const formData = new FormData();
    formData.append('projectId', data.projectId)
    formData.append('payoutCustomerId', data.payoutCustomerId)
    formData.append('bank', data.bank)
    formData.append('accountType', data.accountType)
    formData.append('accountNumber', data.accountNumber)
    formData.append('bankCertificate', data.bankCertificate)
    const response = await axios.post(`${urls.NEW_API_URL}payouts/payoutTarget`, formData, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}

export const getCurrentAccount = async() => {
  try {
    const response = await axios.get(`${urls.NEW_API_URL}payouts/`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPayoutTargetStatus = async(payoutTargetId: string) => {
  try {
    const response = await axios.get(`${urls.NEW_API_URL}payouts/payoutTarget/${payoutTargetId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}