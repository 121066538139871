import React, { useEffect } from 'react'
import img2 from "../../assets/img/blog/card-2.png";
import image from "../../assets/img/blog/image-article.png";
import image2 from "../../assets/img/blog/image-2-article.png";
/**

 import vecindario from "../../assets/img/blog/vecindario.svg";
 import lokl from "../../assets/img/blog/lokl-black.svg";
 import forbes from "../../assets/img/blog/forbes.svg";
 import republica from "../../assets/img/blog/republica.svg";
 import semana from "../../assets/img/blog/semana.svg";
 */
// import { Tab, TabList } from 'react-tabs';
// import { scrollToSection } from '../../helpers/functions';

function Article() {

  // const optionsList = [
  //   "Dejanos tus comentario",
  //   "Comentarios",
  // ];

  // const [tabOptions /*, setTabOptions*/] = useState(optionsList);
  // const [activeTabIndex /*, setActiveTabIndex */] = useState(0);

  // TODO: Verify if is possible to improve this with react-router-dom config
  // Code to scroll to top when change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <section id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <div className="bg-note-img bg-cover bg-center h-[432px] w-full flex items-center justify-center">

        <div className="wrapper-lokl text-white ">

          <div className="w-5/12">

            <h1 className="font-lato font-extrabold text-[38px] mb-3">
              ¿Dónde invertir en Colombia en el 2023?
            </h1>

            <p className="text-xl">
              Una mirada en busca de nuevas oportunidades que beneficie a todos.
            </p>
          
          </div>

        </div>

      </div>

      <div className="wrapper-lokl space-y-20 py-16">

        <div className="w-full flex justify-between">

          <div className="w-1/2 mr-10">

            <div className="blog-title-md mb-4">
              Te has preguntado...
            </div>

            <p className="text-base font-roboto text-justify">
              ¿Qué tipo de oportunidades puedes tener para hacer crecer tu dinero? Hoy te queremos contar sobre <span className='font-bold'>5 alternativas para invertir en este 2023</span>, basados en tendencia actuales y proyecciones económicas:
            </p>

          </div>

          <div className="w-1/2 h-auto flex justify-end items-start">

            <div className="flex space-x-2 mr-3">

              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>

            </div>

            <div className="underline cursor-pointer">5</div>

          </div>

        </div>

        <div className="w-full flex justify-between">

          <img className='' width={525} height={357} src={image} alt="casa-lokl" />

          <div className="flex flex-col justify-center ml-14">

            <h2 className='blog-title-md mb-12'>1. Mercado inmobiliario</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12 text-justify">
                El mercado inmobiliario es otra opción a considerar en el 2023. Aunque la situación puede variar según la región o el país, en general, la inversión en bienes raíces puede ofrecer potencial de apreciación del capital y generar ingresos pasivos a través de alquileres. Es importante investigar y evaluar cuidadosamente el mercado inmobiliario antes de invertir, considerando factores como ubicación, demanda, regulaciones y proyecciones de crecimiento. <b>Conoce más sobre nuestros proyectos. <a className='hover:text-[#17008A]' href="/nido">Invierte en Nido de agua</a></b>
              </p>

              <div className="">

                <div className="">
                  Lee también: <a className='hover:text-[#17008A]' href="/blog/inversiones-colaborativas-hospitality"> <b> Inversiones colaborativas en <br /> hospitality </b> </a>
                </div>

                {/* <div className="flex items-center mt-2">

                  <img className='mr-4' src={lokl} alt="lokl" />
                  <img src={vecindario} alt="vecindario" />
                
                </div> */}

              </div>

            </div>

          </div>

        </div>

        <div className="blog-title-md text-center py-20">

          ¡Descubre el poder de la inversión! Genera <br /> 
          ingresos pasivos, crea patrimonio, obtén <br />
          beneficios y asegura tu futuro financiero con <br />
          propiedades inmobiliarias solo en LOKL.
  
        </div>

        <div className="flex">

          <div className="flex flex-col justify-center">

            <h2 className='blog-title-md mb-12'>2. Técnología e innovación</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12 text-justify">
                El avance tecnológico continúa siendo una fuerza impulsora en la economía global. Empresas involucradas en áreas como inteligencia artificial, ciberseguridad, energías renovables y blockchain, entre otras, podrían presentar <a className='hover:text-[#17008A]' href="/nido"><b>oportunidades de inversión interesantes en el 2023</b></a>. A medida que la tecnología sigue transformando diferentes sectores, invertir en empresas que lideran la innovación podría resultar beneficioso en el largo plazo.
              </p>

              {/* <div className="">

                <div className="">
                  Algunos de ellos:
                </div>

                <div className="flex justify-between items-center">

                  <img src={forbes} alt="forbes" />
                  <img src={republica} alt="republica" />
                  <img src={semana} alt="semana" />
                
                </div>

              </div> */}

            </div>

          </div>

          <div className="flex flex-col justify-center ml-14">

            <h2 className='blog-title-md mb-12'>3. Energías renovables</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12 text-justify">
                Con un mayor enfoque en la sostenibilidad y la lucha contra el cambio climático, las energías renovables han ganado impulso en los últimos años y podrían presentar oportunidades de inversión en el 2023. La inversión en proyectos de energía solar, eólica, hidroeléctrica u otras fuentes de energía renovable, podría ser atractiva para los inversionistas interesados en apoyar iniciativas sostenibles y potencialmente obtener rendimientos financieros.
              </p>

              {/* <div className="">

                <div className="">
                  Algunos de ellos:
                </div>

                <div className="flex justify-between items-center">

                  <img src={forbes} alt="forbes" />
                  <img src={republica} alt="republica" />
                  <img src={semana} alt="semana" />
                
                </div>

              </div> */}

            </div>

          </div>

        </div>

        <div className="w-full flex justify-between">

          <div className="w-1/2">

            <img src={image2} alt="casa-lokl" />

            <div className="h-0 mt-4 mb-3 border border-solid border-t-0 border-slate-800 opacity-70" />

            <div className="text-xl">
              Recuerda <a className='hover:text-[#17008A]' href="/register"><b>registrarte</b></a> para recibir información de primera mano, sobre nuestros proyectos.
            </div>
          
          </div>

          <div className="w-1/2 flex flex-col justify-start ml-14">

            <h2 className='blog-title-md mb-12'>4. Diversificación de cartera</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12 text-justify">

                Aunque puede ser tentador enfocarse en inversiones específicas, diversificar una cartera de inversión sigue siendo una estrategia prudente en el 2023. Distribuir los fondos en diferentes clases de activos, como acciones, bonos, bienes raíces y otros instrumentos financieros, puede ayudar a reducir el riesgo y maximizar el potencial de retorno.
        
              </p>

              <div className="space-y-2">

                <div className="">
                  Empieza a invertir en el futuro que quieres vivir. <br /> <a className='hover:text-[#17008A]' href="/#projects"><b>Invierte con LOKL</b></a>.
                </div>

                <div className="">
                  Escrito por Veronica Porras.
                </div>

                {/* <div className="flex justify-between items-center">

                  <img src={forbes} alt="forbes" />
                  <img src={republica} alt="republica" />
                  <img src={semana} alt="semana" />

                </div> */}

              </div>

            </div>

          </div>

        </div>

        {/* <div id='comments'>

          <TabList className="flex space-x-6 font-bold text-lg list-none mb-8">

            {
              tabOptions.map((tab, key) => 
                <Tab key={key} className={ `min-w-[100px] cursor-pointer outline-none text-center font-roboto ${ key === activeTabIndex ? ' text-black ' : 'text-gray-400' }` }>
                  {tab}
                  { key === activeTabIndex && <div className='w-full h-1 bg-black mt-2'></div> }
                </Tab>
              )
            }

          </TabList>

          <div className="bg-white w-2/3 p-12 flex flex-col items-center rounded-xl">

            <div className="flex flex-col w-full">

              <label htmlFor="commentInput">¿Tienes preguntas?</label>
              <textarea className='outline-none' name="" id="commentInput" cols={30} rows={10}></textarea>

            </div>

            <button className="btn-primary">
              Comentar
            </button>

          </div>

        </div> */}

        <div className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

          <div className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                5 secretos para usar el <br /> dinero adecuadamente, <br /> planifica e invierte a largo <br /> plazo.
              </div>

            </div>

            <div 
              className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
              onClick={() => {
                window.location.href = "/blog/planificar-presupuesto-largo-plazo"
              }}
            >
              Ver nota
            </div>

          </div>


          <div className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones
    
              </div>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante 
              </p>

              <div 
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" /> 

          </div>

        </div>
        
      </div>

    </section>
  )
}

export default Article;