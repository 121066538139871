import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { PersonalDataForm } from './components/PersonalDataForm'
import { createDocumentFromTemplate, saveContractFile } from '../../../services/e-signService'
import { createWebIntegrationToken, getBackgroundCheck, getProcessState } from '../../../services/kycService'
import { completeOwnerData, getUserData, saveIdentityVerification } from '../../../services/ownerServices'
import toast, { Toaster } from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import { ContractSignModal } from './components/ContractSignModal'
import { IdentityValidation } from './components/IdentityValidation'
import { ContractSign } from './components/ContractSign'
import { isAuthenticated, scrollToSection } from '../../../helpers/functions'
import { ConfirmationModal } from './components/ConfirmationModal'
import { parsePhoneNumber } from 'react-phone-number-input'

export const PersonalDataX = () => {

  const [showLowScoreAlert, setShowLowScoreAlert] = useState<boolean>(false);

  // TODO: Verify if is possible to improve this with react-router-dom config
  // Code to scroll to top when change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [queryParameters, setSearchParams] = useSearchParams()

  const [confirmationModal, setConfirmValidationModal] = useState<any>({
    title: '',
    description: '',
    type: '',
    nextStep: false
  })
  const [confirmationModalIsOpen, setConfirmValidationModalIsOpen] = useState<boolean>(false)
  const [userValidationScore, setUserValidationScore] = useState<any>(null)

  const [recursionMaxAttempts, setRecursionMaxAttempts] = useState<number>(12)
  const [validationIsPending, setValidationIsPending] = useState<boolean>(false)

    const [phone, setPhone] = useState('');
  // Get identity authentication result
  useEffect(() => {
    const kycProcessId = queryParameters.get('process_id')
    if(kycProcessId) {
      scrollToSection('identity-validation-section', 200);
      //Make the first call after 5 second
      setValidationIsPending(true)
      setTimeout(() => {
        processIdentityValidation(kycProcessId)
      } , 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters])

  // Recursive function to get the result of the identity validation
  const processIdentityValidation = (kycProcessId: string) => {
    console.log('Llamada a la recursion')
    const delayTime = 8000
    getProcessState(kycProcessId).then(async(result: any) => {
      if(result?.data.status === 'failure') {
        setValidationIsPending(false)
        setConfirmValidationModalIsOpen(true)
        setConfirmValidationModal({
          title: '¡Algo salió mal!',
          description: 'No pudimos validar tu identidad, por favor intenta nuevamente o comunícate con nosotros para ayudarte.',
          type: 'error',
          communityRedirectionLink: 'https://wa.link/z8zvp3'
        })
        await saveIdentityVerification(kycProcessId, result?.data.status, 0)
      } else if(result?.data.status === 'success' && result.data?.validations[0].validation_status === 'success') {
        getBackgroundCheck(result.data?.check_id).then(async(bgCheckResult: any) => {
          // Clear the query parameters
          queryParameters.delete('process_id')
          setSearchParams(queryParameters);
          console.log(bgCheckResult.data)
          // Conditional to validate the score of the background check
          if(bgCheckResult?.data?.check.score >= 0.9) {
            setUserValidationScore(bgCheckResult?.data?.check?.score)
            setConfirmValidationModalIsOpen(true)
            setConfirmValidationModal({
              title: '¡Validación exitosa!',
              description: 'Tu identidad ha sido validada exitosamente. Ahora puedes firmar el contrato de vinculación.',
              type: 'success'
            })
            setValidationIsPending(false)
            scrollToSection('contract-sign-section', 210);
            await saveIdentityVerification(kycProcessId, result.data?.validations[0]?.validation_status, bgCheckResult?.data?.check?.score)
          } else {
            setUserValidationScore(bgCheckResult?.data?.check?.score)
            setConfirmValidationModalIsOpen(true)
            setConfirmValidationModal({
              title: '¡Algo salió mal!',
              description: 'Tu validación tiene novedades. Por favor comunícate con nosotros para ayudarte en el proceso.',
              type: 'error',
              communityRedirectionLink: 'https://wa.link/z8zvp3'
            })
            await saveIdentityVerification(kycProcessId, result.data?.validations[0]?.validation_status, bgCheckResult?.data?.check?.score)
            setShowLowScoreAlert(true)
            setValidationIsPending(false)

          }
          //
        })
      } else if(result?.data.status === 'pending') {
        if(recursionMaxAttempts > 0) {
          setValidationIsPending(true)
          console.log('Se esta llamando la recursion. Iteracion #: ', recursionMaxAttempts)
          setRecursionMaxAttempts((state) => state--)
          setTimeout(() => {
            processIdentityValidation(kycProcessId)
          }, delayTime)
        } else {
          setConfirmValidationModalIsOpen(true)
          setConfirmValidationModal({
            title: '¡Algo salió mal!',
            description: 'La validación no pudo ser completada, por favor comunícate con nosotros para ayudarte.',
            type: 'error',
            communityRedirectionLink: 'https://wa.link/z8zvp3'
          })
          setValidationIsPending(false)
        }
      } else {
        setConfirmValidationModalIsOpen(true)
        setConfirmValidationModal({
          title: '¡Algo salió mal!',
          description: 'No pudimos validar tu identidad, por favor intenta nuevamente o comunícate con nosotros para ayudarte.',
          type: 'error',
          communityRedirectionLink: 'https://wa.link/z8zvp3'
        })
        setValidationIsPending(false)
      }
    }).catch((error) => {
      console.log(error)
      setConfirmValidationModalIsOpen(true)
      setConfirmValidationModal({
        title: '¡Algo salió mal!',
        description: 'No pudimos validar tu identidad, por favor intenta nuevamente o comunícate con nosotros para ayudarte.',
        type: 'error',
        communityRedirectionLink: 'https://wa.link/z8zvp3'
      })
      setValidationIsPending(false)
    }).finally(() => {
      setValidationIsPending(false)
    })
  }

  // Get user data
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIdentityValidationButtonLoading(true)
    if(token) {
      getUserData().then((result) => {
        const data = result.data.data
        setValue('firstName', data.firstName)
        setValue('lastName', data.lastName)
        setValue('documentNumber', data.documentNumber)
        setValue('birthDate', data.birthDate)
        setValue('address', data.address)
        setValue('documentType', data.documentType)
        setValue('state', data.state)
        setValue('city', data.city)
        // Code to try to set up the phone and country phone code
        const phone = data.phone || ''
        const formatPhoneNumber = parsePhoneNumber(phone)
        let phoneFormatted = formatPhoneNumber?.nationalNumber
        const countryPhoneCode = formatPhoneNumber?.countryCallingCode
        if(data.countryPhoneCode && data.countryPhoneCode.includes('+')) {
          setValue('phone', `${data.countryPhoneCode}${data.phone}`)
          setPhone(`${data.countryPhoneCode}${data.phone}`)
        } else if(data.countryPhoneCode) {
          setValue('phone', `+${data.countryPhoneCode}${data.phone}`)
          setPhone(`+${data.countryPhoneCode}${data.phone}`)
        } else if(phoneFormatted && countryPhoneCode && countryPhoneCode.includes('+')) {
          setPhone(`${countryPhoneCode}${phoneFormatted}`)
          setValue('phone', `${countryPhoneCode}${phoneFormatted}`)
        } else if(phoneFormatted && countryPhoneCode) {
          setPhone(`+${countryPhoneCode}${phoneFormatted}`)
          setValue('phone', `+${countryPhoneCode}${phoneFormatted}`)
        }
        //
        const identityValidation = data.identityValidations.find((element: any) => element.status === 'success')
        if(identityValidation) {
          setUserValidationScore(identityValidation.score)
          if(identityValidation.score >= 0.9) {
            scrollToSection('identity-validation-section', 200);
          } else {
            setShowLowScoreAlert(true)
          }

        }
        setIdentityValidationButtonLoading(false)
      }).catch((error) => {
        console.log(error)
        setIdentityValidationButtonLoading(false)
      })
    } else {
      isAuthenticated();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
    setValue,
    clearErrors,
    setError,
    getValues
  } = useForm<FieldValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      documentType: '',
      documentNumber: '',
      birthDate: '',
      address: '',
      state: '',
      city: ''
    }
  })

  // const [isLoading, setIsLoading] = useState(false)
  const [signUrl, setSignUrl] = useState('')
  const [documentInfo, setDocumentInfo] = useState<any>({})
  const [identityValidationButtonLoading, setIdentityValidationButtonLoading] = useState(false)
  const [contractSignButtonLoading, setContractSignButtonLoading] = useState(false)
  const [isSignModalOpen, setIsSignModalOpen] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupResolve, setPopupResolve] = useState<(() => void) | null>(null);

  const handleClosePopup = () => {
    setShowPopup(false);
    if (popupResolve) popupResolve();
  };
  // Function to initialize the identity validation process
  const openIdentityVerification = async(data: any) => {

    await new Promise<void>((resolve) => {
      setShowPopup(true);
      setPopupResolve(() => resolve);
    });
    isAuthenticated();
    // Validation for required custom fields
    if (!getValues("phone")) {
      setError('phone', {type: 'required'});
      return
    }
    if(!getValues("state")) {
      setError('state', {type: 'required'});
      return
    }
    if(!getValues("documentType")) {
      setError('documentType', {type: 'required'});
      return
    }
    if(!getValues("city")) {
      setError('city', {type: 'required'});
      return
    }
    try {
      setIdentityValidationButtonLoading(true)
      const updateResponse = await updateData(data)
      if(updateResponse?.data) {
        const result = await createWebIntegrationToken()
        if(result?.data.api_key) {
          return window.location.href = `https://identity.truora.com/?token=${result?.data.api_key}`;
        }
      }
      setIdentityValidationButtonLoading(false)
    } catch (error) {
      toast.error('Error creando el token de validación de identidad, por favor verifica que se hayan llenado bien los campos.')
      setIdentityValidationButtonLoading(false)
      console.log(error)
    }
  }

  // Function to initialize the contract sign process
  const onSignContractSubmit = async (data: any) => {
    if (!getValues("phone")) {
      setError('phone', {type: 'required'});
      return
    }
    await updateData(data)
    // Set phone and phone code
    try {
      const token = localStorage.getItem('token');
      const cartId = localStorage.getItem('cartId');
      if(token) {
        setIsSignModalOpen(true);
        setContractSignButtonLoading(true)
        // Value must be change in the unit increment event
        const result = await createDocumentFromTemplate({
          cartId
        })
        setDocumentInfo(result?.data?.data)
        setContractSignButtonLoading(false)
        setSignUrl(result?.data.data.signUrl)
      }
      
    } catch (error) {
      setContractSignButtonLoading(false)
      console.log('Error onSignContract', error)
    }
  }

  const updateData = async (data: any) => {
    try {
      const formatPhoneNumber = parsePhoneNumber(data.phone)
      let phone = formatPhoneNumber?.nationalNumber || ''
      const countryPhoneCode = formatPhoneNumber?.countryCallingCode || ''
      // This validation is for user without phone code
      if (!phone || !countryPhoneCode) {
        phone = data.phone
      }
      // Update owner information
      const result = await completeOwnerData(
        data.firstName,
        data.lastName,
        data.documentType,
        data.documentNumber,
        data.birthDate,
        data.address,
        phone,
        countryPhoneCode,
        data.state,
        data.city
      )
      return result
    } catch (error: any) {
      console.log(error)
      if(error?.response.status === 403) {
        toast.error('Tu sesión ha expirado, por favor inicia sesión nuevamente')
      }
    }
  }

  useEffect(() => {
    let handleMessage: (e: any) => void;
    if(documentInfo?.token) {
      handleMessage = (e: any) => {
        if (e.data === 'zs-signed-file-ready') {
          saveContractFile({contractId:documentInfo.contractId}).then((result) => {
            setIsSignModalOpen(false);
            setConfirmValidationModalIsOpen(true)
            setConfirmValidationModal({
              title: '¡Contrato firmado!',
              description: '¡Excelente! Has firmado el contrato de vinculación exitosamente.',
              type: 'success',
              nextStep: true
            })
          }).catch((error) => {
            console.log(error)
            setIsSignModalOpen(false);
            setConfirmValidationModalIsOpen(true)
            setConfirmValidationModal({
              title: '¡Algo salió mal!',
              description: 'No pudimos guardar tu contrato firmado, por favor comunícate con nosotros para ayudarte.',
              type: 'error',
              communityRedirectionLink: 'https://wa.link/z8zvp3'
            })
          })

        }
      };
      // Attach the event listener when the component mounts
      window.addEventListener('message', handleMessage);
    }


    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentInfo]);


  const setCustomValue = (id: string, value: any, required: boolean) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  const onError = (errors: any, event: any) => {
    isAuthenticated();
    if (!getValues("phone")) {
      setError('phone', {type: 'required'});
    }
    if(!getValues("state")) {
      setError('state', {type: 'required'});
    }
    if(!getValues("documentType")) {
      setError('documentType', {type: 'required'});
    }
    if(!getValues("city")) {
      setError('city', {type: 'required'});
    }
  }

  return (
    <>
      <Toaster
        toastOptions={{
          success: {
            style: {
              fontWeight: 'bold'
            },
          },
          error: {
            style: {
              background: '#FF0F0F',
              color: 'white',
              fontWeight: 'bold'
            }
          },
          style: {
            fontWeight: 'bold'
          },
          duration: 4000
        }}
      />
      <div className="mt-8 md:mt-32">
        <div className="grid grid-cols-2 gap-10">
          <div className="col-span-2 md:col-span-1">

            <div>
              <h2 className="text-2xl font-bold mb-4">
                Información de Pago
              </h2>
              <p className="text-gray-500">
                Utilizamos esta información para registrar productos, asegurar tu identidad y calcular impuestos y tarifas. Se requieren todos los campos a menos que se indique lo contrario.
              </p>
            </div>

            <PersonalDataForm
              setPhone={setPhone}
              phone={phone}
              errors={errors}
              register={register}
              setValue={setValue}
              setCustomValue={setCustomValue}
              clearErrors={clearErrors}
              getValues={getValues}
            />

          </div>
          {showPopup && (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
              <div className='bg-white p-8 rounded-lg shadow-lg text-center'>
                  <h2 className='text-2xl mb-4'>Validación de identidad</h2>
                  <p className='mb-6 max-w-4xl'>Para completar la validación de identidad, es necesario que la persona titular de la cuenta realice este proceso. Por favor, asegúrate de que la persona a la que pertenece la cuenta esté presente para continuar.</p>
                  <button
                    onClick={handleClosePopup}
                    className='bg-blue-500 text-white px-4 py-2 rounded mr-2'
                  >
                    Continuar
                  </button>
              </div>
            </div>
          )}
          <div className="col-span-2 md:col-span-1">
            <div id='identity-validation-section'>
              <IdentityValidation
                onSubmit={handleSubmit(openIdentityVerification, onError)}
                identityValidationLoadingButton={identityValidationButtonLoading}
                validationIsPending={validationIsPending}
                userValidationScore={userValidationScore}
                showLowScoreAlert={showLowScoreAlert}
              />
            </div>
            <div className="my-10">
              <hr className="border-gray-400" />
            </div>
            <div id='contract-sign-section'>
              < ContractSign
                userValidationScore={userValidationScore}
                onSubmit={handleSubmit(onSignContractSubmit, onError)}
                contractSignButtonLoading={contractSignButtonLoading}
              />
            </div>
          </div>

        </div>

        <ConfirmationModal
          isOpen={confirmationModalIsOpen}
          title={confirmationModal.title}
          description={confirmationModal.description}
          type={confirmationModal.type}
          nextStep={confirmationModal.nextStep}
          setIsOpen={setConfirmValidationModalIsOpen}
          communityLink={confirmationModal.communityRedirectionLink}
        />

        <ContractSignModal isOpen={isSignModalOpen} setIsOpen={setIsSignModalOpen} signUrl={signUrl} />

      </div>
    </>
  )
}
