import React, { createRef, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { ResponsiveLine } from '@nivo/line';

import starIcon from '../../../assets/img/dashboard/star-yellow.svg';
// import roiUpIcon from '../../../assets/img/dashboard/upRoi.svg';
import backIcon from '../../../assets/img/dashboard/backIcon.svg';
// import arrowRight from '../../../assets/img/dashboard/arrow-right.svg';
// import arrowLeft from '../../../assets/img/dashboard/arrow-left.svg';
import reportIcon from '../../../assets/img/report-icon.svg';
import indieIcon from '../../../assets/img/indie-icon.svg';
import iconInfo from "../../../assets/img/infocircle.svg";

import { getNameEmail, numberWithCommas, unitPrice } from '../../../helpers/functions';
import { saveAs } from 'file-saver';
// import { urls } from '../../../helpers/urls';
import { getInfoPMS, getOwnersInvestment, getValorizationByEmail } from '../../../services/dashboardServices';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

// import moment from 'moment';
// import ReactPaginate from 'react-paginate';
import { getOwnerInvertment } from '../../../services/ownerServices';
import { urls } from '../../../helpers/urls';
import GridImagesNido from '../../../components/GridImagesNido';
import GridImagesIndie from '../../../components/GridImagesIndie';
// import Menu from '../../../components/MenuTabs';
import PopupDisclaimerRetorno from '../../../components/PopupDisclaimerRetorno';
import { SubscriptionPage } from '../subscription/SubscriptionPage';
import { Toaster } from 'react-hot-toast';
import { getUserContracts } from '../../../services/e-signService';
import { InvestmentDetail } from './sections/InvestmentDetail';
import { ContractsIndieUniverse } from '../components/ContractsIndieUniverse';
import { ContractsNidoDeAgua } from '../components/ContractsNidoDeAgua';

// const checkoutAppBaseUrl = 'https://checkout.lokl.life/'; // TODO: Setup url in a env file


function DashboardDetail() {

  const { projectId } = useParams();

  const name = sessionStorage.getItem("name") || '';
  const navigate = useNavigate();

  const [project, setProject] = useState<any>({});
  const [porcentNido, setPorcentNido] = useState<any>('');
  // const [suscription, setSuscription] = useState<any>([]);
  // const [suscriptionPagination, setSuscriptionPagination] = useState<any>([]);
  // const [totalSuscription, setTotalSuscription] = useState<any>(0);
  // const [nextSuscription, setNextSuscription] = useState<any>({
  //   value: 0,
  //   date: 0,
  //   reference: '',
  // });
  const [pmsData, setPmsData] = useState<any>({});
  const [activeItem, setActiveItem] = useState< 'proyecto' | 'inversion' | 'projectSubscription' | 'contracts' >('proyecto');

  const [valorization, setValorization] = useState<any>();
  const [valorizationLoader, setValorizationLoader] = useState<boolean>(false);

  const [popoverShow, setPopoverShow] = useState(false);
  const [userContract, setUserContract] = useState([]);

  const btnTriggerRef: any = createRef();

  useEffect(() => {
    getUserContracts().then(res => {
      console.log('contracts', res.data.data);
      setUserContract(res.data.data);
    }).catch(err => {
      console.log('contracts', err)
    })
  } , []);

  const setColor = (): string => {

    const projectName = project?.title;

    if(projectName?.includes('indie')){
      return 'bg-[#DFDDEB]';
    }

    if(projectName?.includes('nido')){
      return 'bg-[#EBE2DD]';
    }

    return '';

  }

  const setSecondColor = (): string => {

    const projectName = project?.title;

    if(projectName?.includes('indie')){
      return 'bg-[#C8BFFF]';
    }
    
    if(projectName?.includes('nido')){
      return 'bg-[#E3BFBF]';
    }

    return '';

  }
  
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const tabQueryParam = searchParams.get('tab');

  const [tabIndex, setTabIndex] = useState(0);


  useEffect(() => {
    if(tabQueryParam === 'inversion'){
      setActiveItem('inversion');
      setTabIndex(1);
    } else if(tabQueryParam === 'proyecto'){
      setActiveItem('proyecto');
      setTabIndex(0);
    }
  }, [tabQueryParam]);

  /**
   * Funcion para obtener el porcentaje de nido
   */
   const getPorcentProject = async () => {

    const getId = async () => {
      // const idProject: any = await getLastIdProject();

      const { data } = await getOwnerInvertment(urls.ID_NIDO);
      // console.log('projects', data.data);
      setPorcentNido(data?.data?.percent);

    }

    getId();

  }

  /**
   * funcion para descarlos contratos
   */
   const downloadContract = async (url: string, name: string) => {

    const pdfBlob = await fetch(url).then(r => r.blob());
    saveAs(pdfBlob, `${name}_contrato_de_mandato_${location?.state?.title.replaceAll(' ', '_').toLowerCase()}.pdf`);

  }

  // const getSubcription = async () => {
  //   const { data } = await getOwnersSubcription(project.projectId);
  //   // console.log('data getSubcription', data?.data);
  //   if(data?.data){
  //     setSuscription(data?.data?.installments);
  //   }
  // }

  const topBarBytab = () => {

    if(activeItem === 'proyecto'){

      return (
        <div className={`hidden lg:flex items-center justify-between w-full h-[86px] ${setColor()} divide-x divide-slate-400/90 my-3 px-8 rounded-md`}>

          <div className="font-lato px-4">

            <div className="font-medium text-sm 2xl:text-base">Valoración estimada del proyecto</div>
            <div className="font-extrabold text-lg">$ {numberWithCommas(project.min_invested)}</div>

          </div>
          <div className="font-lato px-4">

            <div className="font-medium text-sm 2xl:text-base">Retorno anual promedio estimado</div>
            <div className="font-extrabold text-lg flex">
              +{ project?.title?.includes('indie') ? '12' : '15' }%
              <span 
                onClick={() => { setPopoverShow(!popoverShow) }}
                className="flex justify-end items-end relative text-sm ml-2 mb-[2px] border border-black"
              > 
                E.A
                <img className='mb-1 absolute -top-2' width={13} height={13} src={iconInfo} alt="" />
              </span>
            </div>

          </div>

          <div className="font-lato px-4">

            <div className="font-medium text-sm 2xl:text-base">Rentas</div>
            <div className="font-extrabold text-lg">-</div>

          </div>
          <div className="font-lato px-4">

            <div className="font-medium text-sm 2xl:text-base">Units disponibles</div>
            <div className="font-extrabold text-lg">-</div>

          </div>
          <div className="font-lato px-4">

            <div className="font-medium text-sm 2xl:text-base">Valor por Unit</div>
            <div className="font-extrabold text-lg">${project?.title?.includes('indie') ? '125.000' : numberWithCommas(unitPrice()) }</div>

          </div>

        </div>
      )

    }else if(activeItem === 'inversion'){

      return (
        <div className="hidden lg:flex items-center w-full">

          <div className={`flex w-full items-center justify-between h-[86px] ${setColor()} divide-x divide-slate-400/90 my-3 px-8 rounded-l-md`}>

            <div className="font-lato px-4">

              <div className="font-medium text-sm 2xl:text-base">Monto invertido</div>
              <div className="font-extrabold text-lg whitespace-nowrap">$ {numberWithCommas(project?.investment)} COP</div>

            </div>

            <div className="font-lato px-4">

              <div className="font-medium text-sm 2xl:text-base">Units propios</div>
              <div className="font-extrabold text-lg">{project?.shares + project?.unitsByReferral}</div>

            </div>

            <div className="font-lato px-4">

              <div className="font-medium text-sm 2xl:text-base">Compra de unit</div>
              <div className="font-extrabold text-lg">-</div>

            </div>

            <div className="font-lato px-4">

              <div className="font-medium text-sm 2xl:text-base">Rentas</div>
              <div className="font-extrabold text-lg">-</div>

            </div>

          </div>

          <div className={`flex items-center ${setSecondColor()} h-[86px] rounded-r-md px-8`}>

            <div className="font-lato px-4">

              <div className="font-medium text-sm 2xl:text-base whitespace-nowrap">Porcentaje de valorización</div>
              {
                valorizationLoader
                ? <p>Cargando valor...</p>
                : <div className="font-extrabold text-lg">{project?.title?.includes('indie') ? parseFloat(valorization?.gain_indie?.toFixed(1)??0)  : parseFloat(valorization?.gain_nido?.toFixed(1)??0) }%</div>
              }
              

            </div>

          </div>

        </div>
      )

    }

  }

  useEffect(() => {

    // setProject(location.state);
    getPorcentProject();
    getInfoPMS()
    .then(res => {
      setPmsData(res.data)
      console.log('pms data', res.data);
    })
    .catch(err => {

      console.log('pms err', err);
    
    })

    getInvestmentInfo();

    const email = getNameEmail().email;

    setValorizationLoader(true)
    getValorizationByEmail(email)
    .then((res: any) => {
      console.log('Valorization data', res.data);
      setValorization(res?.data)
    })
    .catch((err: any) => {

      console.log('Valorization data', err);
    
    }).finally(() => {
      setValorizationLoader(false)
    })
    
    // eslint-disable-next-line
  }, []);

  const getInvestmentInfo = async () => {
    const { data } = await getOwnersInvestment();
    const project = data.find((project: any) => project.projectId === projectId);
    if(!project) {
      return navigate('/dashboard')
    }
    localStorage.setItem('projectId', project.projectId)
    setProject({
      projectId: project.projectId,
      title: project.projectName,
      description: project.projectDescription,
      investment: project.totalInvestmentValue,
      shares: parseFloat(project.units.toFixed(2)),
      unitsByReferral: project.unitsByReferral,
      location: '',
      roi: project.projectName.includes('nido') ? 15 : 14,
      rate: '5',
      min_invested: parseInt(project.projectValuation),
      data: [
        {
          name: 'Valor del Unit',
          value: numberWithCommas(project.averageUnitValue),
        },
        {
          name: 'Etapa',
          value: 'Finalizado',
        },
        {
          name: 'Cupo de inversión',
          value: numberWithCommas(parseInt(project.projectValuation)),
        },
        {
          name: 'Estado del proyecto',
          value: project.projectName === "nido de agua" ? 'En proceso' : 'Completado',
        },
        {
          name: 'Detalles de pago',
          value: 'Aprobado',
        },
      ]
    })
  }


  return (
    <>
      <Toaster
        toastOptions={{
          success: {
            style: {
              fontWeight: 'bold'
            },
          },
          error: {
            style: {
              background: '#FF0F0F',
              color: 'white',
              fontWeight: 'bold'
            }
          },
          style: {
            fontWeight: 'bold'
          },
          duration: 4000
        }}
      />
    <Tabs className={' flex flex-col w-full'} selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

      <div className="flex items-start mt-44 lg:mt-0">

        <div 
          className="flex justify-center items-start mr-[20px] cursor-pointer"
          onClick={() => navigate('/dashboard')}
        >

          <img className="mr-[10px] mt-[3px]" src={backIcon} alt="" />

          <span className="leading-none">volver</span>

        </div>

        <div className="lg:hidden">

          <h2 className="text-[23px] font-lato font-black mb-[17px]">Mis inversiones</h2>

          <div className="flex mb-[25px]">

            <div className="text-[14px] text-gray-400 ml-[5px]">{project?.location}</div>
          
          </div>

        </div>

      </div>

      <TabList className="flex space-x-3 lg:mt-3 border-[#B49BC0] border-b-4  lg:border-b-0 mb-4 lg:mb-0 h-14 lg:h-auto">

        <Tab
          onClick={() => setActiveItem('proyecto')}
        >
          <div className={`${ activeItem === 'proyecto' && 'bg-[#B49BC0] lg:bg-transparent rounded-t-xl  lg:rounded-none lg:border-b-4'} ${ project?.title?.includes('indie') ? 'border-[#B49BC0] text-[#B49BC0]' : 'border-[#6D5555] text-[#6D5555]' } inline-block cursor-pointer py-2 px-2 font-roboto font-normal text-lg`}>
            <span className='hidden lg:block'>Detalles del proyecto</span>
            <svg className={`flex lg:hidden w-10 h-10 fill-current hover:text-[#716178] ${ activeItem === 'proyecto' ? 'text-[#ffffff]' : 'text-[#B49BC0]' }`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2c0 0 0 0 0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4c0 0 0 0 0 0c19.8 27.1 39.7 54.4 49.2 86.2l160 0zM192 512c44.2 0 80-35.8 80-80l0-16-160 0 0 16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/></svg>

          </div>
        </Tab>

        <Tab
          onClick={() => setActiveItem('inversion')}
        >
          <div className={`${ activeItem === 'inversion' && 'bg-[#B49BC0] lg:bg-transparent rounded-t-xl  lg:rounded-none lg:border-b-4'} ${ project?.title?.includes('indie') ? 'border-[#B49BC0] text-[#B49BC0]' : 'border-[#6D5555] text-[#6D5555]' } inline-block cursor-pointer py-2 px-2 font-roboto font-normal text-lg`}>
          <span className='hidden lg:block'>Detalles de la inversión</span>
          <svg className={`flex lg:hidden w-10 h-10 fill-current hover:text-[#716178] ${ activeItem === 'inversion' ? 'text-[#ffffff]' : 'text-[#B49BC0]' }`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0l32 0c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64l32 0c123.7 0 224 100.3 224 224l0 32 0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160C100.3 320 0 219.7 0 96z"/></svg>
          </div>
        </Tab>

        {
            project?.title === 'nido de agua' &&
            <Tab
              onClick={() => setActiveItem('projectSubscription')}
            >
              <div className={`${activeItem === 'projectSubscription' && 'bg-[#B49BC0] lg:bg-transparent rounded-t-xl  lg:rounded-none lg:border-b-4'} ${project?.title?.includes('indie') ? 'border-[#B49BC0] text-[#B49BC0]' : 'border-[#6D5555] text-[#6D5555]'} inline-block cursor-pointer py-2 px-2 font-roboto font-normal text-lg`}>
                <span className='hidden lg:block'><strong>Pagos por suscripción</strong></span>
                <svg className={`flex lg:hidden w-10 h-10 fill-current hover:text-[#716178] ${ activeItem === 'projectSubscription' ? 'text-[#ffffff]' : 'text-[#B49BC0]' }`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z"/></svg>
              </div>
            </Tab>
        }
        {
          project?.title === 'indie universe' &&
          <Tab
          onClick={() => setActiveItem('contracts')}
          >
            <div className={`${ activeItem === 'contracts' && 'bg-[#B49BC0] lg:bg-transparent rounded-t-xl  lg:rounded-none lg:border-b-4'} ${ project?.title?.includes('indie') ? 'border-[#B49BC0] text-[#B49BC0]' : 'border-[#6D5555] text-[#6D5555]' } inline-block cursor-pointer py-2 px-2 font-roboto font-normal text-lg`}>
              <span className='hidden lg:block'>Contratos y reportes</span>
              <svg className={`flex lg:hidden w-10 h-10 fill-current hover:text-[#716178] ${ activeItem === 'contracts' ? 'text-[#ffffff]' : 'text-[#B49BC0]' }`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-19.3c-2.7 1.1-5.4 2-8.2 2.7l-60.1 15c-3 .7-6 1.2-9 1.4c-.9 .1-1.8 .2-2.7 .2l-64 0c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 381l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2L80 448c-8.8 0-16-7.2-16-16s7.2-16 16-16l8.2 0c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.8 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8 8.9 0c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7L384 203.6l0-43.6-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM549.8 139.7c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM311.9 321c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L512.1 262.7l-71-71L311.9 321z"/></svg>
            </div>
          </Tab>
        }
      </TabList>

      {topBarBytab()}

      <div className="flex flex-col lg:block space-y-5 lg:space-y-0 w-full">

        <TabPanel className={'flex flex-col lg:flex-row space-y-5 lg:space-y-0' }>

          <div className="lg:w-[380px] bg-white lg:mr-[20px] p-[30px] rounded-2xl" >

            <div className="mb-3 flex justify-between items-center" ref={btnTriggerRef}>
              
              <h2 className="text-[21px] font-lato font-black">{project?.title}</h2>

              <div className="flex">

                <img className="w-[16px]" src={starIcon} alt="star" />
                <div className="text-[15px] ml-2">{project?.rate}</div>
              
              </div>

            </div>

            <p className="text-[12px] w-[90%] mb-[30px]">{project.description}</p>

            <div className="w-[60%] flex flex-row justify-between items-end">

              <div className="">

                <h2 className="text-[18px] font-bold">${numberWithCommas(project?.investment)}</h2>
                <h2 className="text-[12px] font-normal">Monto invertido</h2>

              </div>

              <div className="">

                <h2 className="text-[18px] font-bold">{project?.shares + project?.unitsByReferral}</h2>
                <h2 className="text-[12px] font-normal">Units</h2>

              </div>

            </div>

            <div className="data flex flex-col mt-[20px]">

              {
                project?.data?.map((data: any, i: any) => 
                  <>
                    <div key={i} className={`w-full pt-[15px] flex flex-wrap justify-between ${ project.data.length - 1 === i ? '' : 'border-gray-300 border-b-[1px] pb-[15px]' }`}>
                      
                      <div className="text-[14px] text-gray-400">{data.name}</div>
                      <div className="text-[14px] text-gray-400">{data.value}</div>

                      { 
                        data.name === 'Cupo de inversión' ? 
                        <div className="w-full flex justify-between items-center">
                          {
                            project.title.includes('nido') ?
                            <>
                              <progress className='w-[77%] my-3' value={ porcentNido } max="100"></progress> 
                              <div className='text-gray-400'>{porcentNido}%</div>
                            </> 
                            : 
                            <>
                              <progress className='w-[77%] my-3' value={ 100 } max="100"></progress> 
                              <div className='text-gray-400'>100.00%</div>
                            </>
                          }
                        </div>
                        : null 
                      }
                      
                    </div>
                  </>
                )
              }

            </div>

          </div>

          {
            project?.title?.includes('indie') ? 
            <GridImagesIndie />
            :
            <GridImagesNido />
          }

        </TabPanel>

        <TabPanel width={100}>
        {
          project.title === "nido de agua" ? 
          <ContractsNidoDeAgua userContract={userContract}/> :
          <InvestmentDetail project={project} />
        }

        {/* { Array.isArray(suscription) && suscription.length >= 1 && project?.title?.includes('Nido') && SuscriptionSection() } */}

        </TabPanel>

        <TabPanel width={100}>
          {
            project.title === "nido de agua" ? 
            <SubscriptionPage /> : 
            <ContractsIndieUniverse pmsData={pmsData} userContract={userContract}/>
          }
    
        </TabPanel>

      </div>

      <div className="lg:hidden">

        {
          project.title !== "nido de agua" &&
          <div className='flex mr-4'>
          
            <a 
              id='reserveIndieDetail'
              className="
              bg-[#CFDDFF]
              hover:bg-[#CFDDFF]/80
              hover:text-black
                hover:no-underline
                no-underline
                w-[155px]
                rounded 
                py-2.5 
                font-medium 
                text-sm
                text-left
                cursor-pointer
                duration-300 
                ease-in-out
                mb-[20px]
                flex
                justify-center
                items-center
                mr-5
              "
              href='https://hotels.cloudbeds.com/reservation/BTHiNc#promo=INDIE-LOKL23'
              target={"_blank"}
              rel="noreferrer"
            >
              <img className='mr-2' width={30} src={indieIcon} alt="" />
              Reserva en <br /> Indie Universe
            </a>

            <button 
              id='downloadReport'
              className="
              bg-[#E4E4E4]
              hover:bg-[#E4E4E4]/80
                w-[155px]
                rounded 
                py-2.5 
                font-medium 
                text-sm
                text-left
                cursor-pointer
                duration-300 
                ease-in-out
                mb-[20px]
                flex
                justify-center
                items-center
              "
              onClick={() => window.open('https://docsend.com/view/brh54pepy8tcidq6', "blank")}
            >
              <img className='mr-2' width={30} src={reportIcon} alt="" />
              Reporte 2023 (3)
            </button>
          
          </div>
        }
        { 
          project.title === "nido de agua" &&
          <div className='flex mr-4'>
            <button 
              id='downloadReport'
              className="
              bg-[#E4E4E4]
              hover:bg-[#E4E4E4]/80
                w-full
                rounded 
                py-3
                font-medium 
                text-sm
                text-left
                cursor-pointer
                duration-300 
                ease-in-out
                mb-[16px]
                flex
                justify-center
                items-center
              "
              onClick={() => window.open('https://docsend.com/view/wqnxiwjyvppkhkh3', "blank")}
            >
              <img className='mr-2' width={30} src={reportIcon} alt="" />
              Reporte <br /> 2023 (1 -2)
            </button>
          </div>
        }

        <div className="lg:w-[330px] bg-white p-[30px] rounded-2xl mb-3">

          <div className="text-2xl font-lato font-black mb-3">
            Contratos de mandato
          </div>

          {

            userContract?.map((contract: any, i: any) => 
            
              <div 
                key={i} 
                className="flex justify-between py-3 cursor-pointer border-b-[0.5px] border-gray-300 last:border-b-0 text-gray-500"
                onClick={() => downloadContract(contract.contractFile, name)}
              >

                <div className="">{contract.id.slice(0, 5)}_contrato_mandato_{name}</div>
                <svg className='text-gray-500 hover:text-black focus:text-black' width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                  <path d="M14.375 15.0938H17.7891C20.2598 15.0938 22.2812 14.141 22.2812 11.6977C22.2812 9.25436 19.9004 8.39725 17.9688 8.30156C17.5694 4.48051 14.7793 2.15625 11.5 2.15625C8.40039 2.15625 6.40406 4.21322 5.75 6.25312C3.05469 6.50918 0.71875 7.86492 0.71875 10.6734C0.71875 13.482 3.14453 15.0938 6.10938 15.0938H8.625M8.625 17.9732L11.5 20.8438L14.375 17.9732M11.5 10.0625V20.1263" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>


              </div>
            
            )

          }

        </div>

        { project?.title?.includes('nido') && <SubscriptionPage /> }

      </div>

      <PopupDisclaimerRetorno triggerRef={btnTriggerRef} isVisible={popoverShow} />

    </Tabs>
    </>
  )

}

export default DashboardDetail;