import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import error from '../../assets/img/payment/photo-complete-error.png';
import { numberWithCommas, unitPriceMulplicated } from '../../helpers/functions';

function Error() {

  const navigate = useNavigate();
  const instalmentValue: any = sessionStorage.getItem('instalmentValue');

  const units = sessionStorage.getItem('amount');

  // TODO: Verify if is possible to improve this with react-router-dom config
  // Code to scroll to top when change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className='lg:w-[1024px] lg:flex lg:space-x-16 py-20'>

      <div className="lg:w-1/2">
        <img src={error} alt="" />
      </div>

      <div className="lg:w-1/2 lg:space-y-7">

        <div className="flex justify-start items-center">

          <div className="text-xl text-red-500 font-medium mr-8">
            Transacción denegada <br />
            Vuelve a intentarlo
          </div>

          <button 
            className="bg-gray-900 text-white hover:bg-gray-700 w-60 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out"
            onClick={() => {
              navigate('/payment/complete');
            }}
          >
            Volver a invertir
          </button>

        </div>

        <div className="text-gray-500 text-sm">
          20 de Octubre de 2022 
          <br />
          Hora: 2:00pm
        </div>

        <div className="flex items-end">

          <div className="">

            <div className="font-extrabold font-lato text-3xl">
              Nido de agua
            </div>

            <div className="font-extrabold font-lato text-3xl">
              ${numberWithCommas(parseInt(instalmentValue))} /mes
            </div>

            <div className="text-gray-500 text-sm">
              Un mes único y total de inversión
            </div>

          </div>

          <div className="ml-8">
            Descargar contrato
          </div>

        </div>

        <div className="flex divide-x-2 space-x-2 text-sm leading-tight">

          <div className="">Etapa</div>

          <div className="pl-2">Total: {units} Units</div>

          <div className="pl-2">Costo por Unit: ${unitPriceMulplicated(1)}</div>

        </div>
        
      </div>

    </div>
  )

}

export default Error;