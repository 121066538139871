import axios from "axios"

// const truoraBaseUrl = process.env.REACT_APP_TRUORA_API_URL;
const truoraApiKey = process.env.REACT_APP_TRUORA_API_KEY;

export const createWebIntegrationToken = async()=> {

  // Datos a enviar en el cuerpo de la solicitud
  const formData = new URLSearchParams();
  formData.append('key_name', 'Lokl Integration');
  formData.append('key_type', 'web');
  formData.append('grant', 'digital-identity');
  formData.append('api_key_version', '1');
  formData.append('country', 'CO');
  formData.append('redirect_url', 'https://www.lokl.life/checkout/personal-data');
  formData.append('flow_id', 'IPFb5f2b2e404fbe0caa204c673d4d5d7bf');
  try {
    // FIXME: Cambiar el endpoint por el de producción
    const result = await axios.post(`https://api.account.truora.com/v1/api-keys/`, formData, {
      headers: {
        'Truora-API-Key': `${truoraApiKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getProcessState = async (processId: string) =>  {
  try {
    const result = await axios.get(`https://api.identity.truora.com/v1/processes/${processId}/result`, {
      headers: {
        'Truora-API-Key': `${truoraApiKey}`,
        'Content-Type': 'application/json'
      }
    })
    return result
  } catch (error) {
    console.log(error)
  }

}
export const getProcessStateInPdf = async (processId: string) =>  {
  try {
    const result = await axios.get(`https://api.identity.truora.com/v1/processes/${processId}/pdf`, {
      headers: {
        'Truora-API-Key': `${truoraApiKey}`,
        "Accept": "application/json"
      }
    })
    return result
  } catch (error) {
    console.log(error)
  }

}

export const getBackgroundCheck = async (checkId: string) =>  {
  try {
    const result = await axios.get(`https://api.checks.truora.com/v1/checks/${checkId}`, {
      headers: {
        'Truora-API-Key': `${truoraApiKey}`,
        "Accept": "application/json"
      },
    })
    return result
  } catch (error) {
    throw error
  }
}