import React, { useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Input } from './Input'
// import PhoneInput from 'react-phone-number-input'
// import es from 'react-phone-number-input/locale/es.json';
import Select from 'react-tailwindcss-select'
import { Button } from './Button'
import toast from 'react-hot-toast'
import { createCustomer, createPayoutTarget } from '../../../services/PayoutService'
import { useParams } from 'react-router-dom'
import { payoutBanks} from '../constants/payoutBanks'


export const PayoutForm = () => {

  const {projectId} = useParams()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ step, setStep ] = useState(1)

  const [documentType] = useState<any>(['cc', 'ce', 'nit']);
  const [selectedDocumentType, setSelectedDocumentType] = useState<any>(null);

  const [accountType] = useState<any>([
    {
      value: 'savings',
      label: 'Ahorros'
    },
    {
      value: 'checking',
      label: 'Corriente'
    }
  ]);
  const [selectedAccountType, setSelectedAccountType] = useState<any>(null);

  const [bank] = useState<any>(payoutBanks);
  const [selectedBank, setSelectedBank] = useState<any>(null);

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
    setValue,
    setError,
    clearErrors
  } = useForm<FieldValues>({
    defaultValues: {
      name: '',
      documentType: '',
      documentNumber: '',
      email: '',
      phoneNumber: '',
    }
  })

  const {
    register: registerAccount,
    handleSubmit: handleSubmitAccount,
    formState: {
      errors: errorsAccount,
    },
    setValue: setValueAccount,
    setError: setErrorAccount,
    clearErrors: clearErrorsAccount
  } = useForm<FieldValues>({
    defaultValues: {
      payoutCustomerId: '',
      bank: '',
      accountType: '',
      accountNumber: '',
      bankCertificate: '',
    }
  })

  const setCustomValue = (id: string, value: any, required: boolean) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  const setCustomValueAccount = (id: string, value: any, required: boolean) => {
    setValueAccount(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  const handleDocumentType = (val: any) => {
    setCustomValue('documentType', val.value, true);
    setSelectedDocumentType(val);
    clearErrors('documentType');
  }

  const handleAccountType = (val: any) => {
    setCustomValueAccount('accountType', val.value, true);
    setSelectedAccountType(val);
    clearErrorsAccount('accountType');
  }

  const handleBank = (val: any) => {
    setCustomValueAccount('bank', val.value, true);
    setSelectedBank(val);
    clearErrorsAccount('bank');
  }

  const onSubmit: SubmitHandler<FieldValues> = (async(data) => {
    if(!data.documentType) {
      setError('documentType', {type: 'required'});
      return
    }
    const body = {
      name: data.name,
      documentType: data.documentType,
      documentNumber: data.documentNumber,
      email: data.email,
      phoneNumber: data.phoneNumber,
    }
    try {
      setIsLoading(true);
      const response = await createCustomer(body);
      if(response?.data?.success) {
        localStorage.setItem('payoutCustomerId', response?.data?.data?.id)
        setIsLoading(false)
        setStep(2)
        toast.success('Cuenta registrada con éxito', { duration: 5000})
      } else {
        setIsLoading(false)
        toast.error('No se pudo crear el usuario en Palomma', { duration: 5000})
      }
    } catch (error: any) {
      setIsLoading(false)
      const errorMessage = error?.response?.data.message || ''
      const errorData = error?.response?.data?.data || ''
      toast.error(`${errorMessage}.\n\n${errorData}`, { duration: 5000})
    }
  })

  const onSubmitAccount: SubmitHandler<FieldValues> = (async(data) => {
    
    if(!data.accountType) {
      setErrorAccount('accountType', {type: 'required'});
      return
    }
    if(!data.bank) {
      setErrorAccount('bank', {type: 'required'});
      return
    }

    const body = {
      projectId,
      payoutCustomerId: localStorage.getItem('payoutCustomerId'),
      bank: data.bank,
      accountType: data.accountType,
      accountNumber: data.accountNumber,
      bankCertificate: data.bankCertificate[0]
    }
    try {
      setIsLoading(true);
      const response = await createPayoutTarget(body);
      if(response?.data?.success) {
        window.location.href = response?.data?.data.authUrl
      } else {
        setIsLoading(false)
        toast.error('No se pudo registrar la cuenta', { duration: 5000})
      }
    } catch (error: any) {
      setIsLoading(false)
      const errorMessage = error?.response?.data.message || ''
      const errorData = error?.response?.data?.data || ''
      toast.error(`${errorMessage}.\n\n${errorData}`, { duration: 5000})
    }
  })

  return (
    <>
      {
        step === 1 &&
        <div className="bg-white flex flex-col justify-between rounded-md w-full md:w-3/4 xl:w-2/4 py-4 px-10 max-w-2xl">
          <div className="flex items-center gap-3">
            <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="45" height="43" rx="5" fill="#A9DBFF" />
              <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
              <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            <h2 className="font-bold text-lg">Registra tu cuenta de banco</h2>
          </div>

          <p className='w-full mx-auto my-2 text-gray-600'>* La información en el siguiente formulario es fundamental para lograr la transferencia de utilidades. Te pedimos revisar de manera detallada los datos antes de finalizar el proceso.</p>
          <p className='w-full mx-auto my-2 text-gray-600'>⚠️ No se permite registrar cuentas bancarias de terceros.</p>
          <div className="grid gap-3 grid-cols-8 mt-4 w-full xl:w-2/3 mx-auto">
            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Nombre completo</label>
              <Input
                id="name"
                label="Nombre completo"
                disabled={isLoading}
                register={register}
                errors={errors}
                type='text'
                required
              />
            </div>

            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Tipo de documento</label>
              <Select
                classNames={{
                  menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${ !errors.documentType ? 'border-gray-300' : 'border-red-500' } py-1 px-4 rounded-lg outline-none cursor-pointer focus:border-violet-600`,
                  ChevronIcon: ({ open }) => ( open ? 'rotate-90' : 'rotate-180' ),
                  list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
                }}
                primaryColor='violet'
                value={selectedDocumentType}
                loading={documentType.length <= 0}
                options={documentType.map((value: any) => { return { value: value, label: value }})}
                placeholder="Tipo de documento"
                onChange={(val: any) => {
                  handleDocumentType(val)
                }}
              />
            </div>

            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Número de documento</label>
              <Input
                id="documentNumber"
                label="Número de documento"
                disabled={isLoading}
                register={register}
                errors={errors}
                type='text'
                required
              />
            </div>

            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Correo electrónico</label>
              <Input
                id="email"
                label="Correo electrónico"
                disabled={isLoading}
                register={register}
                errors={errors}
                type='email'
                required
              />
            </div>

            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Número de celular</label>
              <Input
                id="phoneNumber"
                label="Número de teléfono"
                disabled={isLoading}
                register={register}
                errors={errors}
                type='number'
                required
                minLength={10}
                maxLength={10}
              />
              {(errors.phoneNumber?.type === "minLength" || errors.phoneNumber?.type === "maxLength") && (<div className='text-red-500 text-sm' id='warning-minlength-lead-origin'>* El celular debe tener 10 dígitos</div>)}
            </div>
            
          </div>

          <div className='w-full flex justify-center my-4'>
            <div className="w-full lg:w-3/4 xl:w-2/4">
              <Button label='Siguiente' onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} />
            </div>
          </div>
        </div>
      }
      {
        step === 2 &&
        <div className="bg-white flex flex-col justify-between rounded-md w-full md:w-3/4 xl:w-2/4 py-4 px-10 max-w-2xl">
          <div className="flex items-center gap-3">
            <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="45" height="43" rx="5" fill="#A9DBFF" />
              <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
              <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            <h2 className="font-bold text-lg">Cuenta registrada</h2>
          </div>

          <p className='w-full mx-auto my-2 text-gray-600'>*La información en el siguiente formulario es fundamental para lograr la transferencia de utilidades. Te pedimos revisar de manera detallada los datos antes de finalizar el proceso.</p>

          <div className="grid gap-3 grid-cols-8 mt-4 w-full xl:w-2/3 mx-auto">

            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Banco</label>
              <Select
                classNames={{
                  menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${ !errorsAccount.bank ? 'border-gray-300' : 'border-red-500' } py-1 px-4 rounded-lg outline-none cursor-pointer focus:border-violet-600`,
                  ChevronIcon: ({ open }) => ( open ? 'rotate-90' : 'rotate-180' ),
                  list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                  searchIcon :'hidden',
                }}
                primaryColor='violet'
                value={selectedBank}
                isSearchable={true}
                loading={bank.length <= 0}
                options={bank.map((value: any) => { return { value: value.value, label: value.label }})}
                placeholder="Selecciona un banco"
                onChange={(val: any) => {
                  handleBank(val)
                }}
              />
            </div>

            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Tipo de cuenta</label>
              <Select
                classNames={{
                  menuButton: ({ isDisabled }) => `flex justify-between border-[1px] ${ !errorsAccount.accountType ? 'border-gray-300' : 'border-red-500' } py-1 px-4 rounded-lg outline-none cursor-pointer focus:border-violet-600`,
                  ChevronIcon: ({ open }) => ( open ? 'rotate-90' : 'rotate-180' ),
                  list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
                }}
                primaryColor='violet'
                value={selectedAccountType}
                loading={accountType.length <= 0}
                options={accountType.map((value: any) => { return { value: value.value, label: value.label }})}
                placeholder="Tipo de cuenta"
                onChange={(val: any) => {
                  handleAccountType(val)
                }}
              />
            </div>

            <div className="col-span-8">
            <label className="block text-sm font-medium text-gray-700">Número de cuenta</label>
              <Input
                id="accountNumber"
                label="Número de cuenta"
                disabled={isLoading}
                register={registerAccount}
                errors={errorsAccount}
                type='text'
                required
              />
            </div>

            <div className="col-span-8">
              <label htmlFor="bankCertificate" className="block text-sm font-medium text-gray-700">Certificado bancario (pdf)</label>
              <Input
                accept='application/pdf'
                id="bankCertificate"
                label="Certificado bancario"
                disabled={isLoading}
                register={registerAccount}
                errors={errorsAccount}
                type='file'
                required
              />
            </div>

          </div>

          <div className='w-full flex justify-center my-4'>
            <div className="w-full lg:w-3/4 xl:w-2/4">
              <Button label='Registrar cuenta' onSubmit={handleSubmitAccount(onSubmitAccount)} isLoading={isLoading} />
            </div>
          </div>
        </div>
      }
        
    </>
  )
}
